import { TGlobalState } from '../models/global-state.model';
import { AUTH_FORM_TYPES } from './user.const';

export const initialAuth: TGlobalState['auth'] = {
  authFormType: AUTH_FORM_TYPES.SIGN_IN,
  passwordError: '',
  isSignUpSuccess: false,
  isZeroOrganizations: false,
};

export const initialLoader: TGlobalState['initLoader'] = {
  loader: 0,
};

export const initialMenu: TGlobalState['menu'] = {
  isShow: false,
  isShowOnMobile: false,
};

export const initialPageAlerts: TGlobalState['pageAlerts'] = [];

export const initialOrg: TGlobalState['initOrg'] = {
  org: null,
  orgRequest: false,
  orgFailed: false,
};

export const initialDocs: TGlobalState['initDocs'] = {
  docs: null,
  docsRequest: false,
  docsFailed: false,
  docTypes: [],
};

export const initialProducts: TGlobalState['initProducts'] = {
  products: null,
  productsRequest: false,
  productsFailed: false,
};

export const initialDocument: TGlobalState['initDocument'] = {
  document: [],
  description: [],
  characteristic: [],
  productCardInfoRequest: false,
  productCardInfoFailed: false,
};

export const initialOrder: TGlobalState['initOrder'] = {
  order: {
    products: [],
    totalPrice: 0,
  },
};

export const initialOrders: TGlobalState['orders'] = [];

export const initialIsEdoFlow: TGlobalState['initIsEdoFlow'] = {
  isEdoFlow: false,
  isEdoFlowRequest: false,
  isEdoFlowFailed: false,
};

export const initialIsEdo: TGlobalState['initIsEdo'] = {
  isEdoRegistered: false,
  isEdoRegisteredRequest: false,
  isEdoRegisteredFailed: false,
};

export const initialIsOffer: TGlobalState['initIsOffer'] = {
  isOfferAccepted: false,
  isOfferAcceptedRequest: false,
  isOfferAcceptedFailed: false,
};

export const initialModal: TGlobalState['initModal'] = {
  modal: false,
  showModalInvoice: false,
};

export const initialDelivery: TGlobalState['delivery'] = {
  isTariffLoading: false,
  isDeliveryFormReady: false,
  isDeliveryFormWasEdit: false,
  isDeliveryAddressError: false,
  deliveryData: {
    start: false,
    contactName: '',
    contactPhone: '',
    currentAddress: '',
    fiasId: null,
    dadataInfo: null,
    deliveryList: [],
    tariff: null,
    deliveryPrice: 0,
    deliveryType: 'Доставка',
    comments: '',
  },
};

export const initialSuccessOrder: TGlobalState['initSuccessOrder'] = {
  successOrder: false,
};

export const initialMultiLogin: TGlobalState['multiLogin'] = {
  type: 'PK',
  userId: '',
  logins: [],
};
