import classNames from 'classnames/bind';
import { FC, memo } from 'react';

import styles from './content-loader.module.css';

const cx = classNames.bind(styles);

export const TextLoader: FC = memo(() => {
  return (
    <div>
      <div className={cx('placeholder-wrapper')}>
        <div
          className={cx('base-placeholder', 'paragraph-placeholder')}
        />
        <div
          className={cx('base-placeholder', 'paragraph-placeholder')}
        />
        <div
          className={cx('base-placeholder', 'paragraph-placeholder')}
        />
      </div>
      <div className={cx('placeholder-wrapper')}>
        <div
          className={cx('base-placeholder', 'paragraph-placeholder')}
        />
        <div
          className={cx('base-placeholder', 'paragraph-placeholder')}
        />
        <div
          className={cx('base-placeholder', 'paragraph-placeholder')}
        />
        <div
          className={cx('base-placeholder', 'paragraph-placeholder')}
        />
      </div>
    </div>
  );
});

TextLoader.displayName = 'TextLoader';
