import classNames from 'classnames/bind';
import { FC, MouseEvent, memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getDeliveryState, getOrder } from 'store';
import { setDeliveryData } from 'store/delivery.slice';
import { addAlert } from 'store/pageAlerts.slice';
import {
  currencyInt,
  withKeyHasBlocked,
  withSetHasBlocked,
} from 'utils';

import ProductCounter from 'components/product-counter/product-counter';

import { PRODUCTS_STATUSES } from 'shared/const';
import { useOrder } from 'shared/hooks/use-order.hook';
import { useProducts } from 'shared/hooks/use-products.hook';
import { ProductResponse } from 'shared/models/global-state.model';

import styles from './product-amount.module.css';

const cx = classNames.bind(styles);

interface IProductAmountProps {
  price: number;
  product: ProductResponse;
}

const ProductAmount: FC<IProductAmountProps> = memo(
  ({ price, product }) => {
    const { order } = useSelector(getOrder);
    const { deliveryData } = useSelector(getDeliveryState);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isMainPage } = useProducts();

    const orderController = useOrder(product);

    const blockedCounterFnWithKey = withKeyHasBlocked(order, product);
    const blockedCounterFnSetWithOfd = withSetHasBlocked(
      order,
      product,
    );

    const isAvailableForOrder = useMemo(() => {
      return (
        product.availableStatus !== PRODUCTS_STATUSES.NOT_FOR_ORDER
      );
    }, [product.availableStatus]);

    const isBlockedCounter = useMemo(() => {
      if (isMainPage) return false;
      return blockedCounterFnSetWithOfd || blockedCounterFnWithKey;
    }, [
      blockedCounterFnSetWithOfd,
      blockedCounterFnWithKey,
      isMainPage,
    ]);

    const increaseCount = useCallback(() => {
      orderController.updateQuantity(1);
      dispatch(setDeliveryData({ ...deliveryData, start: true }));
    }, [orderController, deliveryData, dispatch]);

    const handleClick = useCallback(
      (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();

        if (blockedCounterFnSetWithOfd || blockedCounterFnWithKey) {
          return dispatch(
            addAlert({
              text: `Выбранный товар не может быть добавлен в текущий заказ. Разместите ${product.name} в отдельный заказ.`,
              variant: 'warning',
            }),
          );
        } else {
          increaseCount();
        }
        isMainPage && navigate('/products');
      },
      [
        blockedCounterFnSetWithOfd,
        blockedCounterFnWithKey,
        isMainPage,
        navigate,
        dispatch,
        product.name,
        increaseCount,
      ],
    );

    return (
      <div
        className={cx('product-amount', {
          hidden: isBlockedCounter,
          mt: !blockedCounterFnSetWithOfd && !blockedCounterFnWithKey,
        })}
      >
        {isAvailableForOrder && !isBlockedCounter && (
          <>
            <div className={styles.price}>
              {currencyInt(price)} ₽
              <span className={styles.quantity}>/ шт.</span>
            </div>

            {(orderController.currentProductQuantity === 0 ||
              isMainPage) && (
              <button
                className={styles['buy-btn']}
                onClick={handleClick}
                data-testid="product-amount-click-1"
                aria-hidden="true"
              >
                В корзину
              </button>
            )}

            {orderController.currentProductQuantity !== 0 &&
              !isMainPage && (
                <div
                  className={styles['product-counter']}
                  onClick={(e) => e.stopPropagation()}
                >
                  <ProductCounter product={product} />
                </div>
              )}
          </>
        )}
      </div>
    );
  },
);

ProductAmount.displayName = 'ProductAmount';

export default ProductAmount;
