import classNames from 'classnames/bind';
import { FC, ReactNode, memo } from 'react';

import DropdownItem from 'components/dropdown-item/dropdown-item';

import styles from './dropdown-list.module.css';

const cx = classNames.bind(styles);

interface IDropdownListItem {
  id?: number;
  href: string;
  title: string;
  content: ReactNode;
  icon: ReactNode | null;
}

interface IDropdownListProps {
  dropdownData: IDropdownListItem[];
}

const DropdownList: FC<IDropdownListProps> = memo(
  ({ dropdownData }) => {
    return (
      <>
        {dropdownData.map(
          (item: IDropdownListItem, index: number) => (
            <div key={item.href} className={cx('base-text')}>
              <DropdownItem
                testId={`dropdown-item-${index + 1}`}
                title={item.title}
                icon={item.icon}
                content={item.content}
                href={item.href}
              />
            </div>
          ),
        )}
      </>
    );
  },
);

DropdownList.displayName = 'DropdownList';

export default DropdownList;
