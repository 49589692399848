import { FC, memo } from 'react';

import { DownloadDoc } from 'components/download-doc/download-doc';

import { FileProduct } from 'shared/models/global-state.model';

interface ITabProps {
  tabName: string;
  documents: FileProduct[];
  description: string;
  characteristic: string;
}

export const Tab: FC<ITabProps> = memo(
  ({ tabName, documents, description, characteristic }) => {
    switch (tabName) {
      case '1':
        return (
          <div
            dangerouslySetInnerHTML={{ __html: description || '' }}
          />
        );

      case '2':
        return (
          <div
            dangerouslySetInnerHTML={{ __html: characteristic || '' }}
          />
        );

      case '3':
        return (
          <div>
            {documents.length
              ? documents.map((document, i) => (
                  <div key={i}>
                    <DownloadDoc document={document} />
                  </div>
                ))
              : 'Нет документов'}
          </div>
        );
      default:
        return <div />;
    }
  },
);

Tab.displayName = 'Tab';
