import { FC, memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AppDispatch,
  getDeliveryState,
  getMultiLogin,
  getOrder,
} from 'store';
import { addAlert } from 'store/pageAlerts.slice';
import { currency } from 'utils';

import CustomBtn from 'components/custom-btn/custom-btn';
import OrderDelivery from 'components/order-delivery/order-delivery';
import OrderItems from 'components/order-items/order-items';

import { CONTENT, ONE_MINUTE } from 'shared/const';
import { useAuth } from 'shared/hooks/use-auth';
import { tokens } from 'shared/utils/tokens.util';

import styles from './aside.module.scss';

const Aside: FC = memo(() => {
  const { order } = useSelector(getOrder);
  const { isTariffLoading } = useSelector(getDeliveryState);
  const { logins } = useSelector(getMultiLogin);
  const [startOrder, setStartOrder] = useState(false);
  const [orderLoaderFlag, setOrderLoaderFlag] = useState(false);
  const isAuth = tokens.getAccess();
  const { deliveryData } = useSelector(getDeliveryState);
  const auth = useAuth();
  const dispatch: AppDispatch = useDispatch();

  const handleOrderLoaderFlagToggle = useCallback((val: boolean) => {
    setOrderLoaderFlag(val);
  }, []);

  const startOrderOn = useCallback(() => {
    window.scrollTo(0, 0);

    if (!isAuth) {
      return auth?.logIn();
    }

    if (logins.length === 0) {
      return dispatch(
        addAlert({
          text: CONTENT.auth.login.zeroOrganizations,
          variant: 'warning',
          lifetime: ONE_MINUTE,
        }),
      );
    }

    handleOrderLoaderFlagToggle(true);
    setStartOrder(true);
  }, [
    dispatch,
    handleOrderLoaderFlagToggle,
    isAuth,
    auth,
    logins.length,
  ]);

  const startOrderOff = useCallback(() => {
    setStartOrder(false);
    window.scrollTo(0, 0);
  }, []);

  return (
    <aside className={`${styles.aside} d-none d-lg-block`}>
      <div className="aside-item mt-3">
        <OrderItems aside />
      </div>

      <div className="aside-delivery mt-3">
        <OrderDelivery
          aside
          startOrder={startOrder}
          startOrderOff={startOrderOff}
          onOrderLoaderFlagToggle={handleOrderLoaderFlagToggle}
        />

        {Boolean(order?.totalPrice) && (
          <div className={`${styles['total-price']} mt-3 pt-3`}>
            <div className={styles.total}>Итого:</div>
            {`${currency(
              order.totalPrice + deliveryData.deliveryPrice,
            )} ₽`}
          </div>
        )}

        <div className="mt-3 mb-3">
          <CustomBtn
            theme="edo"
            type="button"
            text={isAuth ? 'Заказать' : 'Авторизуйтесь'}
            onClick={startOrderOn}
            testId="products-btn-1"
            loading={orderLoaderFlag || isTariffLoading}
            isLink={false}
            customCls={null}
            disabled={false}
          />
        </div>
      </div>
    </aside>
  );
});

Aside.displayName = 'Aside';

export default Aside;
