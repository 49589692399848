import { FC, memo } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { TData } from 'pages/orders';

import calendarIcon from 'images/icons/calendar.svg';
import resetIcon from 'images/icons/close.svg';

import styles from './datepicker.module.css';

interface IDatepickerProps {
  dateRange: TData[];
  func: (update: TData[]) => void;
  onDatepickerReset: () => void;
}

const Datepicker: FC<IDatepickerProps> = memo(
  ({ dateRange, func, onDatepickerReset }) => {
    const [startDate, endDate] = dateRange;

    const currentDate = new Date(Date.now());
    const stringDate = currentDate.toLocaleDateString();
    const dateRangePlaceholder = `01.01.1970 - ${stringDate}`;

    return (
      <div className={styles.wrap}>
        <label className={styles.label}>Дата оформления заказа</label>
        <DatePicker
          dateFormat="dd.MM.yyyy"
          selectsRange
          startDate={startDate}
          placeholderText={dateRangePlaceholder}
          endDate={endDate}
          onChange={func}
          className={`${styles.datepicker} datepicker`}
          maxDate={new Date()}
        />
        <div className={styles.icon}>
          <img src={calendarIcon} alt="" />
        </div>
        <div>
          <button
            data-testid="datepicker-btn-1"
            className={styles['icon-reset']}
            type="button"
            onClick={onDatepickerReset}
          >
            <img src={resetIcon} alt="Сбросить выбранные даты" />
          </button>
        </div>
      </div>
    );
  },
);

Datepicker.displayName = 'Datepicker';

export default Datepicker;
