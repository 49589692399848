import { FC, memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIsOffer } from 'store';
import {
  isOfferAcceptedSuccess,
  offerAccepted,
} from 'store/isOffer.slice';

import styles from '../modal-custom-edooffer/modal-custom-edooffer.module.css';
import OfferAcceptanceForm from './offer-acceptance-form';
import OfferAcceptanceReady from './offer-acceptance-ready';

const OfferAcceptance: FC = memo(() => {
  const [isShowReady, setIsShowReady] = useState(false);

  const { isOfferAccepted } = useSelector(getIsOffer);

  const dispatch = useDispatch();

  const handleShowReady = useCallback(() => {
    setIsShowReady(true);
    dispatch(offerAccepted());
    dispatch(isOfferAcceptedSuccess(true));
  }, [dispatch]);

  if (isOfferAccepted && isShowReady) {
    return (
      <div className={styles['modal-col']}>
        <OfferAcceptanceReady />
      </div>
    );
  }

  if (!isOfferAccepted) {
    return (
      <div className={styles['modal-col']}>
        <OfferAcceptanceForm onShowReady={handleShowReady} />
      </div>
    );
  }

  return null;
});

OfferAcceptance.displayName = 'OfferAcceptance';

export default OfferAcceptance;
