import {
  PayloadAction,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { checkDocFlowUrl } from 'urls';

import { initialIsEdoFlow } from 'shared/const/initial-state.const';
import { TGlobalState } from 'shared/models/global-state.model';
import { IError } from 'shared/models/simple-response.model';
import httpClient from 'shared/utils/http-client/http-client';
import { multiLoginStorage } from 'shared/utils/navigate-to-multi-login.utils';

import { logOutUser } from './auth.slice';
import { AppDispatch } from './index';

const initialState: TGlobalState['initIsEdoFlow'] = {
  ...initialIsEdoFlow,
};

/** Способы обмена документами */
const EXCHANGE_TYPES_DTO = {
  edo: 1,
  paper: 2,
};

const isEdoFlowSlice = createSlice({
  name: 'isEdoFlow',
  initialState,
  reducers: {
    isEdoFlow(state) {
      state.isEdoFlowRequest = true;
      state.isEdoFlowFailed = false;
    },
    isEdoFlowSuccess(state, action: PayloadAction<boolean>) {
      state.isEdoFlow = action.payload;
      state.isEdoFlowRequest = false;
    },
    isEdoFlowFailed(state) {
      state.isEdoFlowFailed = true;
      state.isEdoFlowRequest = false;
    },
  },
});

export const checkDocFlow = createAsyncThunk<
  void,
  undefined,
  { dispatch: AppDispatch }
>(
  'isEdoFlow/checkDocFlow',
  async (_, { dispatch, rejectWithValue }) => {
    const orgId = multiLoginStorage.getOrgId();
    dispatch(isEdoFlow());
    try {
      if (orgId) {
        const { data } = await httpClient.get(checkDocFlowUrl(orgId));
        const isEdoFlow = data === EXCHANGE_TYPES_DTO.edo;
        dispatch(isEdoFlowSuccess(isEdoFlow));
        return data;
      }
    } catch (error) {
      const err = error as IError;

      dispatch(isEdoFlowFailed());

      if (err?.response?.status === 401) {
        dispatch(logOutUser());
      }

      return rejectWithValue(err.response.data.message);
    }
  },
);

export const { isEdoFlow, isEdoFlowSuccess, isEdoFlowFailed } =
  isEdoFlowSlice.actions;

export default isEdoFlowSlice.reducer;
