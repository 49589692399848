import { FC, memo, useMemo } from 'react';

import styles from './status-pay-order.module.css';

interface IStatusPayOrderProps {
  header: string;
  iconStatus: string;
  customStyle?: string;
  type:
    | 'successfulDelivery'
    | 'successfulPickup'
    | 'rejected'
    | 'pending';
  deliveryNumber: number;
  pickupNumber?: string;
  orderDate?: string;
  address?: string;
}

export const StatusPayOrder: FC<IStatusPayOrderProps> = memo(
  ({
    header,
    iconStatus,
    customStyle,
    deliveryNumber,
    orderDate,
    type,
    address,
  }) => {
    const statuses = useMemo(
      () => ({
        successfulDelivery: (
          <div>
            Заказ <strong>№{deliveryNumber}</strong> собирается на
            складе.
            <br />
            Будет доставлен {orderDate} по адресу: г. {address}
          </div>
        ),

        successfulPickup: (
          <div>
            Заказ <strong>№{deliveryNumber}</strong> собирается на
            складе.
          </div>
        ),

        rejected: (
          <div>
            Заказ <strong>№{deliveryNumber}</strong> не удалось
            оплатить или произошла ошибка.
            <br />
            Попробуйте оплатить снова или оплатить заказ по счету.
          </div>
        ),

        pending: (
          <>
            <div>
              Оплата заказа <strong>№{deliveryNumber}</strong>{' '}
              обрабатывается.
            </div>
            <br />
            <div>
              Этот процесс может занять некоторое время, как только
              оплата поступит, мы начнем собирать заказ на складе.
              Статус оплаты обновится в карточке заказа
            </div>
          </>
        ),
      }),
      [address, deliveryNumber, orderDate],
    );

    return (
      <div className={`${styles.container} ${customStyle}`}>
        <img src={iconStatus} alt="checkGreen" />

        <div className={styles.wrap}>
          <p className={styles.status}>{header}</p>
          <div className={styles.desc}>{statuses[type]}</div>
        </div>
      </div>
    );
  },
);

StatusPayOrder.displayName = 'StatusPayOrder';
