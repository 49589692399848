export const INPUT_ERROR = {
  setName: 'Введите контактное лицо',
  setPhone: 'Введите номер телефона',
  inCorrectPhone: 'Введите корректный номер',
  setCity: 'Уточните населенный пункт в адресе',
  noEmptyCity:
    'Введите  адрес или ближайший город\n' +
    '(с Вами свяжется оператор)',
  noTariff: 'Выберите тариф',
};
