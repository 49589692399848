import { FC, memo } from 'react';

import ProgressInfoBlock from 'components/progress-info-block/progress-info-block';

import paidDisabledIcon from 'images/icons/paid-disabled.svg';
import notPaidIcon from 'images/icons/paid-err.svg';
import paidIcon from 'images/icons/paid-success.svg';
import readyShipment from 'images/icons/ready-shipment.svg';

interface IPayStatusIconProps {
  statusName: string;
  payStatusName: string;
  percent: number;
}

export const PayStatusIcon: FC<IPayStatusIconProps> = memo(
  ({ statusName, payStatusName, percent }) => {
    switch (payStatusName) {
      case 'Не оплачен':
        return (
          <ProgressInfoBlock
            icon={notPaidIcon}
            text={`Заказ ${payStatusName}`}
            theme="error"
            percent={percent}
          />
        );

      case 'Готов к выдаче':
        return (
          <ProgressInfoBlock
            icon={paidIcon}
            text={`Заказ ${statusName}`}
            theme="success"
            percent={percent}
          />
        );

      case 'Частично оплачен':
        return (
          <ProgressInfoBlock
            icon={readyShipment}
            text={`Заказ ${payStatusName}`}
            theme="warn"
            percent={percent}
          />
        );

      default:
        return (
          <ProgressInfoBlock
            icon={paidDisabledIcon}
            text="Заказ"
            theme="disabled"
            percent={percent}
          />
        );
    }
  },
);

PayStatusIcon.displayName = 'PayStatusIcon';
