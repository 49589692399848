import { FC, memo } from 'react';

import { Icon } from 'components/Icon';

import styles from '../history-orders-item.module.css';

interface DeliveryStatusIconProps {
  deliveryStatusName: string;
}

export const DeliveryStatusIcon: FC<DeliveryStatusIconProps> = memo(
  ({ deliveryStatusName = null }) => {
    let statusIcon;

    switch (deliveryStatusName) {
      case 'Ожидает отгрузку':
        statusIcon = (
          <Icon name="awaitingDelivery" width="32" height="32" />
        );
        break;

      case 'Отгружен':
        statusIcon = <Icon name="delivered" width="32" height="32" />;
        break;

      default:
        statusIcon = (
          <Icon name="deliveryDisabled" width="32" height="32" />
        );
        break;
    }

    return <div className={styles.icon}>{statusIcon}</div>;
  },
);

DeliveryStatusIcon.displayName = 'DeliveryStatusIcon';
