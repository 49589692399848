import React, { useCallback, useState } from 'react';

import { ERROR_MESSAGES } from '../conts';

export const usePasswordInput = () => {
  const [value, setValue] = useState('');
  const [repeat, setRepeat] = useState('');
  const [error, setError] = useState('');

  const handleValueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
    },
    [],
  );

  const handleRepeatChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setRepeat(e.target.value);
    },
    [],
  );

  const checkIdentical = useCallback(() => {
    const isIdentical = value === repeat;
    setError(() => (isIdentical ? '' : ERROR_MESSAGES.password));
    return isIdentical;
  }, [value, repeat]);

  const resetError = useCallback(() => setError(''), []);

  return {
    value,
    error,
    repeat,
    resetError,
    handleValueChange,
    handleRepeatChange,
    checkIdentical,
  };
};
