import React, { FC, memo, useCallback, useState } from 'react';

import { Menu } from './menu';
import { MenuOrganization } from './menu-organization';

interface IProfileMenuProps {
  toggleMenu: (e: React.MouseEvent) => void;
}

const ProfileMenu: FC<IProfileMenuProps> = memo(({ toggleMenu }) => {
  const [isOrgMenu, setIsOrgMenu] = useState(false);

  const switchToOrgMenu = useCallback(() => {
    setIsOrgMenu(true);
  }, []);

  const switchToMainMenu = useCallback(() => {
    setIsOrgMenu(false);
  }, []);

  return (
    <>
      {!isOrgMenu ? (
        <Menu
          switchToOrgMenu={switchToOrgMenu}
          toggleMenu={toggleMenu}
        />
      ) : (
        <MenuOrganization
          switchToMainMenu={switchToMainMenu}
          toggleMenu={toggleMenu}
        />
      )}
    </>
  );
});

ProfileMenu.displayName = 'ProfileMenu';

export default ProfileMenu;
