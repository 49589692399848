import { ChangeEvent, FC, memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDeliveryState, getOrder } from 'store';
import { setDeliveryData } from 'store/delivery.slice';
import { addAlert } from 'store/pageAlerts.slice';
import { withKeyHasBlocked, withSetHasBlocked } from 'utils';

import { useOrder } from 'shared/hooks/use-order.hook';
import { ProductResponse } from 'shared/models/global-state.model';

import decrement from 'images/counter/minus.svg';
import increment from 'images/counter/plus.svg';

import styles from './product-сounter.module.css';

interface IProductCounterProps {
  product: ProductResponse;
}

const ProductCounter: FC<IProductCounterProps> = memo(
  ({ product }) => {
    const { order } = useSelector(getOrder);
    const { deliveryData } = useSelector(getDeliveryState);

    const dispatch = useDispatch();

    const orderController = useOrder(product);

    const blockedCounterFnWithKey = withKeyHasBlocked(order, product);
    const blockedCounterFnSetWithOfd = withSetHasBlocked(
      order,
      product,
    );

    const allowDeliveryRecalculation = useCallback(() => {
      dispatch(setDeliveryData({ ...deliveryData, start: true }));
    }, [deliveryData, dispatch]);

    const handleInputChange = useCallback(
      (num: number) => {
        allowDeliveryRecalculation();

        num === 0
          ? orderController.deleteProduct()
          : orderController.updateQuantity(num, 'set');
      },
      [orderController, allowDeliveryRecalculation],
    );

    const increaseCount = useCallback(() => {
      allowDeliveryRecalculation();
      orderController.updateQuantity(1);
    }, [allowDeliveryRecalculation, orderController]);

    const decreaseCount = useCallback(() => {
      allowDeliveryRecalculation();
      orderController.updateQuantity(-1);
    }, [allowDeliveryRecalculation, orderController]);

    const handleChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        if (blockedCounterFnSetWithOfd || blockedCounterFnWithKey) {
          return dispatch(
            addAlert({
              text: `Выбранный товар не может быть добавлен в текущий заказ. Разместите ${product.name} в отдельный заказ.`,
              variant: 'warning',
            }),
          );
        }

        return handleInputChange(
          Number(event?.target?.value.replace(/\D/, '')),
        );
      },
      [
        dispatch,
        product.name,
        blockedCounterFnSetWithOfd,
        blockedCounterFnWithKey,
        handleInputChange,
      ],
    );

    const handleClick = useCallback(() => {
      if (blockedCounterFnSetWithOfd || blockedCounterFnWithKey) {
        return dispatch(
          addAlert({
            text: `Выбранный товар не может быть добавлен в текущий заказ. Разместите ${product.name} в отдельный заказ.`,
            variant: 'warning',
          }),
        );
      }

      return increaseCount();
    }, [
      dispatch,
      product.name,
      blockedCounterFnSetWithOfd,
      blockedCounterFnWithKey,
      increaseCount,
    ]);

    return (
      <div className={styles['product-counter']}>
        <div className={styles.decrement}>
          <button
            type="button"
            data-testid="product-counter-btn-1"
            disabled={orderController.currentProductQuantity === 0}
            onClick={decreaseCount}
            className={`${styles.btn} decrement-btn`}
          >
            <img src={decrement} alt="minus" />
          </button>
        </div>

        <div className={styles.score}>
          <input
            data-testid="product-counter-input-1"
            type="text"
            value={orderController.currentProductQuantity}
            onInput={handleChange}
          />
        </div>

        <div className={styles.increment}>
          <button
            type="button"
            data-testid="product-counter-btn-2"
            onClick={handleClick}
            className={`${styles.btn} increment-btn`}
          >
            <img src={increment} alt="plus" />
          </button>
        </div>
      </div>
    );
  },
);

ProductCounter.displayName = 'ProductCounter';

export default ProductCounter;
