import { FC, memo } from 'react';

import ProgressInfoBlock from 'components/progress-info-block/progress-info-block';

import awaitingSigning from 'images/icons/awaiting-signing.svg';
import signingDisabledIcon from 'images/icons/disabled-doc.svg';
import signingIcon from 'images/icons/signing-doc.svg';

interface IDocumentStatusIconProps {
  documentStatusName: string;
}

export const DocumentStatusIcon: FC<IDocumentStatusIconProps> = memo(
  ({ documentStatusName }) => {
    switch (documentStatusName) {
      case 'Подписан':
        return (
          <ProgressInfoBlock
            icon={signingIcon}
            text={`УПД + ${documentStatusName}`}
            theme="warn"
          />
        );

      case 'Ожидает подписи':
        return (
          <ProgressInfoBlock
            icon={awaitingSigning}
            text={`УПД ${documentStatusName}`}
            theme="success"
          />
        );

      default:
        return (
          <ProgressInfoBlock
            icon={signingDisabledIcon}
            text="УПД"
            theme="disabled"
          />
        );
    }
  },
);

DocumentStatusIcon.displayName = 'DocumentStatusIcon';
