import { FC, memo, useEffect, useState } from 'react';

import { useKeyPress } from 'shared/hooks/use-key-press.hook';
import { IAddress, IAddressList } from 'shared/models/dadata.model';

import styles from './autocomplete-list.module.css';
import { ListItem } from './list-item';

interface IAutocompleteList {
  show: boolean;
  list: IAddressList[];
  changeCurrentAddressFromSuggest: (item: IAddress) => void;
}

const AutocompleteList: FC<IAutocompleteList> = memo(
  ({ show, list, changeCurrentAddressFromSuggest }) => {
    const downPress = useKeyPress('ArrowDown');
    const upPress = useKeyPress('ArrowUp');
    const enterPress = useKeyPress('Enter');
    const [cursor, setCursor] = useState(0);
    const [hovered, setHovered] = useState(undefined);

    useEffect(() => {
      if (list.length && downPress) {
        setCursor((prevState) => {
          return prevState < list.length - 1
            ? prevState + 1
            : prevState;
        });
      }
    }, [downPress, list.length]);

    useEffect(() => {
      if (list.length && upPress) {
        setCursor((prevState) => {
          return prevState > 0 ? prevState - 1 : prevState;
        });
      }
    }, [upPress, list.length]);

    useEffect(() => {
      if (list.length && enterPress) {
        changeCurrentAddressFromSuggest(list[cursor]);
      } // eslint-disable-next-line
    }, [cursor, enterPress, list.length]);

    useEffect(() => {
      if (list.length && hovered) {
        setCursor(list.indexOf(hovered));
      }
    }, [hovered, list]);

    return list.length && show ? (
      <ul className={styles.list}>
        {list.map((item, i) => (
          <ListItem
            key={item.value}
            active={i === cursor}
            item={item}
            setHovered={() => setHovered}
            id={i}
            changeCurrentAddressFromSuggest={
              changeCurrentAddressFromSuggest
            }
          />
        ))}
      </ul>
    ) : null;
  },
);

AutocompleteList.displayName = 'AutocompleteList';

export default AutocompleteList;
