import { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { getPageAlerts } from 'store';

import PageAlert from './page-alert/page-alert';

const PageAlerts: FC = memo(() => {
  const pageAlerts = useSelector(getPageAlerts);

  return (
    <div className="page-alerts">
      {pageAlerts &&
        pageAlerts.map((pageAlert, i) => (
          <PageAlert
            key={i}
            text={pageAlert.text}
            variant={pageAlert.variant}
            index={i}
          />
        ))}
    </div>
  );
});

PageAlerts.displayName = 'PageAlerts';

export default PageAlerts;
