import classNames from 'classnames/bind';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getMultiLogin } from 'store';

import { Icon } from 'components/Icon';
import { InfoSearchBanner } from 'components/info-search-banner/info-search-banner';
import { LoginItem } from 'components/login-item/login-item';
import { SearchInput } from 'components/search-input/search-input';

import { useSearch } from 'shared/hooks/use-search.hook';

import styles from './multi-login-subitem.module.css';

const cn = classNames.bind(styles);

interface IMultiLoginSubitemProps {
  toggleMenu?: (e: React.MouseEvent) => void;
  isInMenu: boolean;
}

const MAX_QUANTITY = 50;

export const MultiLoginSubitem: FC<IMultiLoginSubitemProps> = memo(
  ({ toggleMenu, isInMenu }) => {
    const { logins } = useSelector(getMultiLogin);
    const { searchOrg, handleChange, foundOrganizations } =
      useSearch();
    const foundOrg = foundOrganizations(logins);
    const orgQuantity = logins.length;
    const foundOrgQuantity = foundOrg.length;

    const renderOgrWord = useCallback(
      (str: string) => {
        const lastDigit = Number(orgQuantity.toString().slice(-1));
        const lastFoundDigit = Number(
          foundOrgQuantity.toString().slice(-1),
        );

        switch (str) {
          case 'компании':
            return lastDigit === 1
              ? 'компания'
              : lastDigit > 1 && lastDigit < 5
              ? 'компании'
              : 'компаний';
          case 'совпадения':
            return lastFoundDigit === 1
              ? 'совпадение'
              : lastFoundDigit > 1 && lastFoundDigit < 5
              ? 'совпадения'
              : 'совпадений';
          default:
            break;
        }
      },
      [orgQuantity, foundOrgQuantity],
    );

    const conditionalHeight = useMemo(() => {
      return (
        (orgQuantity > MAX_QUANTITY && !searchOrg) ||
        (foundOrgQuantity > MAX_QUANTITY && searchOrg)
      );
    }, [foundOrgQuantity, orgQuantity, searchOrg]);

    return (
      <>
        <div
          className={cn({
            wrap: !isInMenu,
            'in-menu-wrap': isInMenu,
          })}
        >
          <div
            className={cn({
              container: !isInMenu,
              'in-menu-container': isInMenu,
            })}
          >
            <div className={styles.search}>
              <Icon name="search" width="16" height="16" />
            </div>
            <SearchInput
              searchOrg={searchOrg}
              handleChange={handleChange}
            />
          </div>

          {orgQuantity > MAX_QUANTITY && !searchOrg && (
            <InfoSearchBanner
              isInMenu={isInMenu}
              message={`В данном кабинете ${orgQuantity} ${renderOgrWord(
                'компании',
              )}.
              Ниже показаны первые 50 записей, уточните запрос в строке поиска`}
            />
          )}

          {foundOrgQuantity > MAX_QUANTITY && searchOrg && (
            <InfoSearchBanner
              isInMenu={isInMenu}
              message={`По данному запросу найдено ${foundOrgQuantity} ${renderOgrWord(
                'совпадения',
              )}.
              Ниже показаны первые 50 записей, для более точного поиска уточните запрос.`}
            />
          )}
        </div>

        <ul
          className={cn({
            list: !isInMenu,
            'in-menu-list': isInMenu,
            'height-info': conditionalHeight,
          })}
        >
          {foundOrg.slice(0, MAX_QUANTITY).map((item) => (
            <LoginItem
              key={item.orgId}
              data={item}
              toggleMenu={toggleMenu}
              isInMenu={isInMenu}
            />
          ))}
        </ul>
      </>
    );
  },
);

MultiLoginSubitem.displayName = 'MultiLoginSubitem';
