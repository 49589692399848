export enum PRODUCTS_STATUSES {
  /** Доступен Много */
  AVAILABLE_MANY = 1,
  /** Доступен Достаточно */
  AVAILABLE_ENOUGH = 2,
  /** Доступен Мало */
  LITTLE_AVAILABLE = 3,
  /** Недоступен для заказа */
  NOT_FOR_ORDER = 4,
  /** Не отображать */
  DO_NOT_DISPLAY = 5,
}
