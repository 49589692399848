import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';

import LoaderSpinner from 'components/loader-spinner/loader-spinner';

import resetIcon from 'images/icons/close.svg';

import styles from './custom-input.module.css';

export interface CustomInputProps {
  margin?: string;
  iconFlag?: string;
  testId?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  onFocus?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onInput?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type: string;
  label: string;
  theme?: string;
  icon?: string;
  passIconOpened?: string;
  passIconClosed?: string;
  placeholder?: string;
  error?: string;
  defaultValue?: string | number;
  value?: string | number;
  disabled?: boolean;
  revealFlag?: boolean;
  maxLength?: number;
  isLoading?: boolean;
  withClearButton?: boolean;
  customRightElement?: React.ReactNode;
  legacyMode?: boolean;
  onClear?: () => void;
}

const CustomInput: FC<CustomInputProps> = memo(
  ({
    value,
    margin,
    iconFlag,
    onBlur,
    onFocus,
    onChange,
    onInput,
    type,
    label,
    theme,
    icon,
    passIconOpened,
    passIconClosed,
    placeholder,
    testId,
    error,
    defaultValue,
    disabled,
    revealFlag,
    maxLength,
    isLoading,
    withClearButton,
    customRightElement,
    onClear,
  }) => {
    const [passwordOpened, setPasswordOpened] = useState(false);
    const [inputType, setInputType] = useState(type);
    const [inputValue, setInputValue] = useState('');
    const [inputDefaultValue, setInputDefaultValue] = useState('');

    const handleRevealClick = useCallback(() => {
      setPasswordOpened((prev) => !prev);
    }, []);

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
        setInputDefaultValue(e.target.value);
        onChange && onChange(e);
      },
      [onChange],
    );

    const handleClear = useCallback(() => {
      setInputValue('');
      setInputDefaultValue('');
      onClear && onClear();
      onChange &&
        onChange({
          target: { value: '' },
        } as React.ChangeEvent<HTMLInputElement>);
    }, [onChange, onClear]);

    useEffect(() => {
      if (revealFlag) {
        setInputType(() => (passwordOpened ? 'text' : 'password'));
      }
    }, [revealFlag, passwordOpened]);

    useEffect(() => {
      if (value) {
        setInputValue(value.toString());
      }
      if (defaultValue) setInputDefaultValue(defaultValue.toString());
    }, [value, defaultValue]);

    return (
      <div
        className={`${styles.main} ${
          margin ? styles[`${margin}`] : ''
        } ${disabled ? styles.disabled : ''}`}
      >
        <label className={styles.label}>{label}</label>

        {defaultValue && value === undefined && (
          <input
            data-testid={`custom-input-${testId}`}
            onBlur={onBlur}
            onFocus={onFocus}
            className={`${styles.input} ${
              theme ? styles[`${theme}`] : ''
            }`}
            type={inputType}
            disabled={disabled || false}
            placeholder={placeholder}
            defaultValue={inputDefaultValue}
            maxLength={maxLength}
          />
        )}

        {!defaultValue && value !== undefined && (
          <input
            data-testid={`custom-input-${testId}`}
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={handleChange}
            onInput={onInput}
            className={`${styles.input} ${
              theme ? styles[`${theme}`] : ''
            }`}
            type={inputType}
            disabled={disabled || false}
            placeholder={placeholder}
            value={inputValue}
            maxLength={maxLength}
          />
        )}

        {!defaultValue && value === undefined && (
          <input
            data-testid={`custom-input-${testId}`}
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={handleChange}
            onInput={onInput}
            className={`${styles.input} ${
              theme ? styles[`${theme}`] : ''
            }`}
            type={inputType}
            disabled={disabled || false}
            placeholder={placeholder}
            maxLength={maxLength}
          />
        )}

        {iconFlag && (
          <div className={styles.icon}>
            <img src={icon} alt="" />
          </div>
        )}

        {revealFlag && (
          <button
            type="button"
            className={styles.icon}
            onClick={handleRevealClick}
          >
            <img
              src={passwordOpened ? passIconOpened : passIconClosed}
              title={
                passwordOpened ? 'Показать пароль' : 'Скрыть пароль'
              }
              alt={
                passwordOpened ? 'Показать пароль' : 'Скрыть пароль'
              }
            />
          </button>
        )}

        {Boolean(error?.length) && (
          <p className={styles.error}>{error}</p>
        )}

        {isLoading && <LoaderSpinner />}

        {withClearButton &&
          (!!inputValue?.length || !!inputDefaultValue?.length) && (
            <button
              type="button"
              className={styles.clear}
              onClick={handleClear}
            >
              <img src={resetIcon} alt="Сбросить данные поля" />
            </button>
          )}

        {customRightElement && (
          <div className={styles.icon}>{customRightElement}</div>
        )}
      </div>
    );
  },
);

CustomInput.displayName = 'CustomInput';

export default CustomInput;
