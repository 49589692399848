import { useCallback } from 'react';

import { REG_EXP } from 'shared/const';

export const useValidation = () => {
  const validateEmail = useCallback((email: string): boolean => {
    return REG_EXP.email.test(email);
  }, []);

  const validatePhone = useCallback((phone: string): boolean => {
    const phoneAsArray = phone.match(REG_EXP.phone);
    if (!phoneAsArray?.length) return false;
    const isEnoughLength = phoneAsArray.length === 11;
    const isValidFirstDigit = ['7', '8'].includes(phoneAsArray[0]);
    return isEnoughLength && isValidFirstDigit;
  }, []);

  /** Есть ли в строке только цифры */
  const isOnlyNumbersInString = useCallback((text: string) => {
    return !!text.match(/^[0-9]*$/g)?.length;
  }, []);

  /** Соответствует ли длина строки одной из заданных величин */
  const isSameSomeLength = useCallback(
    (text: string, lenghts: number[]) => {
      return lenghts.some((len) => text?.length === len);
    },
    [],
  );

  return {
    validateEmail,
    validatePhone,
    isOnlyNumbersInString,
    isSameSomeLength,
  };
};
