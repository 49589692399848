import classNames from 'classnames/bind';
import { FC, memo } from 'react';
import {
  Tooltip as BaseTooltip,
  OverlayTrigger,
} from 'react-bootstrap';

import { ToolTipProps } from './tooltip.model';
import styles from './tooltip.module.css';

const cn = classNames.bind(styles);

const ToolTip: FC<ToolTipProps> = memo((props) => {
  const { children, text, id = '1', placement = 'top' } = props;

  if (!text) {
    return <div className={cn('inner')}>{children}</div>;
  }

  return (
    <OverlayTrigger
      placement={placement}
      overlay={
        <BaseTooltip id={id} className={cn('lk-tooltip')}>
          {text}
        </BaseTooltip>
      }
    >
      <div className={cn('inner')}>{children}</div>
    </OverlayTrigger>
  );
});

ToolTip.displayName = 'Tooltip';

export default ToolTip;
