import { FC, memo } from 'react';

import offerImg from 'images/confirm_offer.svg';
import okImg from 'images/ok.svg';

import styles from './offer-acceptance-ready.module.css';

const OfferAcceptanceReady: FC = memo(() => (
  <div className={styles['accept-ready']}>
    <div className={styles.top}>
      <img className="mx-auto" src={offerImg} alt="" />
    </div>

    <div className={styles.bottom}>
      <img src={okImg} alt="" />
      <div>Принято</div>
    </div>
  </div>
));

OfferAcceptanceReady.displayName = 'OfferAcceptanceReady';

export default OfferAcceptanceReady;
