import { useMask } from '@react-input/mask';
import { InvisibleSmartCaptcha } from '@yandex/smart-captcha';
import React, {
  ChangeEvent,
  FC,
  memo,
  useCallback,
  useState,
} from 'react';

import { Icon } from 'components/Icon';
import AuthMenu from 'components/auth-menu/auth-menu';
import CustomBtn from 'components/custom-btn/custom-btn';
import CustomInput from 'components/custom-input/custom-input';
import PhoneInput from 'components/phone-input/phone-input';

import { tokens } from 'shared/utils/tokens.util';

import passIconClosed from 'images/icons/pass-icon-closed.svg';
import passIconOpened from 'images/icons/pass-icon-opened.svg';

import InputSearch from './components/input-search/input-search';
import {
  useCaptcha,
  useEmailInput,
  useForm,
  useOrgInfo,
  usePasswordInput,
  usePhoneInput,
} from './hooks';
import styles from './registration.module.css';

export interface RegistrationProps {
  toggleMenu: (e: React.MouseEvent) => void;
}

export const Registration: FC<RegistrationProps> = memo(
  ({ toggleMenu }) => {
    const isAuth = tokens.getAccess();
    const phone = usePhoneInput();
    const email = useEmailInput();
    const password = usePasswordInput();
    const organization = useOrgInfo();
    const captcha = useCaptcha();
    const form = useForm({
      email: email.value,
      phone: phone.cleanPhoneNumber,
      password: password.value,
      passwordRepeat: password.repeat,
      selectedOrg: organization.data,
      token: captcha.token,
      showCaptcha: captcha.handleChallengeVisible,
      anyFormErrors: [
        phone.error,
        email.error,
        password.error,
        organization.error,
      ],
    });
    const inputRef = useMask(phone.maskOptions);
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

    const handleCheckboxChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        setIsCheckboxChecked(e.target.checked);
      },
      [],
    );

    return (
      <div className={styles.registration}>
        <AuthMenu
          logo={
            isAuth ? (
              <Icon name="newUser" width="16" height="16" />
            ) : (
              <Icon name="authLogo" width="16" height="16" />
            )
          }
          toggleMenu={toggleMenu}
        />

        <hr />

        <div className={styles.header}>
          <div>Регистрация</div>
        </div>

        <form onSubmit={form.handleSubmit}>
          <PhoneInput
            ref={inputRef}
            placeholder=""
            label="Номер мобильного телефона (Логин)"
            type="text"
            theme="edo"
            margin="mt"
            value={phone.value}
            onFocus={phone.resetError}
            onChange={phone.handleChange}
            onBlur={phone.checkValidation}
          />

          <div className={styles.errors}>{phone.error}</div>

          <CustomInput
            placeholder=""
            label="Адрес электронной почты"
            type="text"
            theme="edo"
            margin="mt"
            value={email.value}
            onFocus={email.resetError}
            onChange={email.handleChange}
            onBlur={email.checkValidation}
          />

          <div className={styles.errors}>{email.error}</div>

          <CustomInput
            placeholder=""
            label="Придумайте пароль"
            type="password"
            theme="edo"
            margin="mt"
            revealFlag={true}
            passIconOpened={passIconOpened}
            passIconClosed={passIconClosed}
            value={password.value}
            onFocus={password.resetError}
            onChange={password.handleValueChange}
          />

          <CustomInput
            placeholder=""
            label="Повторите пароль"
            type="text"
            theme="edo"
            margin="mt"
            revealFlag={true}
            passIconOpened={passIconOpened}
            passIconClosed={passIconClosed}
            value={password.repeat}
            onFocus={password.resetError}
            onChange={password.handleRepeatChange}
            onBlur={password.checkIdentical}
          />

          <div className={styles.errors}>{password.error}</div>

          <InputSearch onOrgSelect={organization.onSearch} />

          <div className={styles.captcha}>
            <InvisibleSmartCaptcha
              sitekey="ysc1_DoDK6gKBDfQkEt1WtOEgbDzhdzgtuBiTWqkpi60cf827566b"
              onSuccess={captcha.handleSuccess}
              onChallengeHidden={captcha.handleChallengeHidden}
              hideShield={true}
              visible={captcha.isVisible}
              test={captcha.isEnableTest}
            />

            <div className={styles.errors}>
              {captcha.status.message ?? ''}
            </div>
          </div>

          <div className={form.errorMessage ? 'mt-2' : 'mt-4'}>
            <div className={`mb-2 text-center ${styles.errors}`}>
              {form.errorMessage}
            </div>

            <div className={styles.info}>
              <input
                type="checkbox"
                onChange={handleCheckboxChange}
                checked={isCheckboxChecked}
              />
              <a
                href="https://cdn1.platformaofd.ru/lkfn-prod/docs/Согласие_на_обработку_Персональных_данных_ООО_ТД_Платформа.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Согласен
              </a>
              на обработку персональных данных
            </div>

            <CustomBtn
              theme="edo"
              type="submit"
              text={'Зарегистрироваться'}
              disabled={!isCheckboxChecked}
              testId="auth-2"
              loading={false}
              isLink={false}
              customCls={null}
            />
          </div>
        </form>
      </div>
    );
  },
);

Registration.displayName = 'Registration';
