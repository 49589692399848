import { FC, memo } from 'react';

import { IAddress } from 'shared/models/dadata.model';

import styles from './autocomplete-list.module.css';

interface IListItemProps {
  item: IAddress;
  active: boolean;
  setHovered: (item: IAddress | undefined) => void;
  id: number;
  changeCurrentAddressFromSuggest: (item: IAddress) => void;
}

export const ListItem: FC<IListItemProps> = memo(
  ({
    item,
    active,
    setHovered,
    id,
    changeCurrentAddressFromSuggest,
  }) => (
    <li
      data-testid={`list-item-id-${id}`}
      className={active ? styles.active : ''}
      onMouseEnter={() => setHovered(item)}
      onMouseLeave={() => setHovered(undefined)}
      onClick={() => changeCurrentAddressFromSuggest(item)}
      role="menuitem"
      tabIndex={0}
      aria-hidden="true"
    >
      {item.value}
    </li>
  ),
);

ListItem.displayName = 'ListItem';
