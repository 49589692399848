import { FC, memo, useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getIsEdo, getIsEdoFlow, getIsOffer } from 'store';
import { showModal } from 'store/modal.slice';

import EdoRegistration from 'components/edo-registration/edo-registration';
import Modal from 'components/modal/modal';
import OfferAcceptance from 'components/offer-acceptance/offer-acceptance';

import styles from './modal-custom-edooffer.module.css';

const modalRoot = document.getElementById('modals') as HTMLElement;

const ModalCustomEdooffer: FC = memo(() => {
  const dispatch = useDispatch();
  const { isOfferAccepted } = useSelector(getIsOffer);
  const { isEdoRegistered } = useSelector(getIsEdo);
  const { isEdoFlow } = useSelector(getIsEdoFlow);
  const [headerText, setHeaderText] = useState('');

  const onClose = useCallback(() => {
    dispatch(showModal(false));
  }, [dispatch]);

  useEffect(() => {
    const withoutOffer = !isOfferAccepted;
    const withoutEdo = isEdoFlow && !isEdoRegistered;

    if (withoutOffer && withoutEdo) {
      setHeaderText('Согласие с Офертой и регистрация в ЭДО');
    } else if (withoutOffer) {
      setHeaderText('Согласие с Офертой');
    } else if (withoutEdo) {
      setHeaderText('Регистрация в ЭДО');
    } else {
      onClose();
    }
  }, [isOfferAccepted, isEdoRegistered, isEdoFlow, onClose]);

  return ReactDOM.createPortal(
    <Modal onClose={onClose} headerText={headerText}>
      <div className={styles['modal-row']}>
        {!isOfferAccepted && <OfferAcceptance />}

        {isEdoFlow && !isEdoRegistered && (
          <div className={styles['modal-col']}>
            <EdoRegistration />
          </div>
        )}
      </div>
    </Modal>,
    modalRoot,
  );
});

ModalCustomEdooffer.displayName = 'ModalCustomEdooffer';

export default ModalCustomEdooffer;
