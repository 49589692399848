import classNames from 'classnames/bind';
import {
  Dispatch,
  FC,
  JSX,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, getModals } from 'store';
import { setShowModalInvoice } from 'store/modal.slice';

import cross from 'images/icons/cross.svg';

import styles from './modal-pay-order.module.css';

const cx = classNames.bind(styles);

interface IModalPayOrderProps {
  modalActive: boolean;
  setModalActive: Dispatch<SetStateAction<boolean>>;
  children: JSX.Element;
}

export const ModalPayOrder: FC<IModalPayOrderProps> = memo(
  ({ modalActive, setModalActive, children }) => {
    const { showModalInvoice } = useSelector(getModals);
    const dispatch: AppDispatch = useDispatch();

    const handleCloseModal = useCallback(() => {
      setModalActive(false);
      dispatch(setShowModalInvoice(false));
    }, [dispatch, setModalActive]);

    useEffect(
      () => {
        if (!showModalInvoice) return;
        setModalActive(true);
      },
      /* eslint-disable-next-line */
      [showModalInvoice],
    );

    return (
      <div className={cx('modal', { 'modal-active': modalActive })}>
        <div
          className={cx('content', { 'content-active': modalActive })}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles['content-header']}>
            Оплата заказа
            <img
              src={cross}
              className={styles.cross}
              onClick={handleCloseModal}
              alt="cross"
            />
          </div>

          {children}
        </div>
      </div>
    );
  },
);

ModalPayOrder.displayName = 'ModalPayOrder';
