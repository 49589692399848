import {
  PayloadAction,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { checkDocUrl } from 'urls';

import { initialIsOffer } from 'shared/const/initial-state.const';
import { TGlobalState } from 'shared/models/global-state.model';
import { IError } from 'shared/models/simple-response.model';
import httpClient from 'shared/utils/http-client/http-client';
import { multiLoginStorage } from 'shared/utils/navigate-to-multi-login.utils';

import { logOutUser } from './auth.slice';
import { AppDispatch } from './index';

const initialState: TGlobalState['initIsOffer'] = {
  ...initialIsOffer,
};

const isOfferSlice = createSlice({
  name: 'isOffer',
  initialState,
  reducers: {
    offerAccepted(state) {
      state.isOfferAcceptedRequest = true;
      state.isOfferAcceptedFailed = false;
    },
    isOfferAcceptedSuccess(state, action: PayloadAction<boolean>) {
      state.isOfferAccepted = action.payload;
      state.isOfferAcceptedRequest = false;
    },
    isOfferAcceptedFailed(state) {
      state.isOfferAcceptedFailed = true;
      state.isOfferAcceptedRequest = false;
    },
  },
});

export const checkOffer = createAsyncThunk<
  void,
  undefined,
  { dispatch: AppDispatch }
>('offer/checkOffer', async (_, { dispatch, rejectWithValue }) => {
  const orgId = multiLoginStorage.getOrgId();
  dispatch(offerAccepted());
  try {
    if (orgId) {
      const { data } = await httpClient.get(
        `${checkDocUrl(orgId)}?docType=400`,
      );
      dispatch(isOfferAcceptedSuccess(data));
      return data;
    }
  } catch (error) {
    const err = error as IError;

    dispatch(isOfferAcceptedFailed());

    if (err?.response?.status === 401) {
      dispatch(logOutUser());
    }

    return rejectWithValue(err.response.data.message);
  }
});

export const {
  offerAccepted,
  isOfferAcceptedSuccess,
  isOfferAcceptedFailed,
} = isOfferSlice.actions;

export default isOfferSlice.reducer;
