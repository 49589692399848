import classNames from 'classnames/bind';
import { FC, ReactNode, memo } from 'react';

import styles from './numbered-item.module.css';

const cx = classNames.bind(styles);

interface INumberedItemProps {
  content: ReactNode;
  number: number;
}

const NumberedItem: FC<INumberedItemProps> = memo(
  ({ content, number }) => (
    <li className={cx('item')}>
      <p className={cx('marker')}>{number}</p>
      <div className={cx('text-wrapper')}>{content}</div>
    </li>
  ),
);

NumberedItem.displayName = 'NumberedItem';

export default NumberedItem;
