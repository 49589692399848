import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrder } from 'store';
import { changeOrder } from 'store/order.slice';

import { metrika } from 'shared/utils/metrika.util';
import { tokens } from 'shared/utils/tokens.util';

import {
  ProductResponse,
  TGlobalState,
} from '../models/global-state.model';

export const useOrder = (product: ProductResponse) => {
  const { order } = useSelector(getOrder);
  const isAuth = tokens.getAccess();

  const dispatch = useDispatch();
  const thisProductInOrder = useMemo(
    () =>
      order?.products.filter((item) => item.id === product.id)?.[0] ||
      null,
    [
      product.id,
      order?.products,
      // eslint-disable-next-line
      order?.products.filter((item) => item.id === product.id)?.[0],
    ],
  );

  const currentProductQuantity = useMemo(
    () => thisProductInOrder?.quantity || 0,
    [thisProductInOrder?.quantity],
  );

  const updateQuantity = useCallback(
    (count: number, mode = 'add') => {
      const newOrder = { ...order };

      if (thisProductInOrder) {
        newOrder.products = order.products.reduce(
          (
            acc: TGlobalState['initOrder']['order']['products'],
            item,
          ) => {
            if (item.id !== product.id) {
              return [...acc, item];
            }

            const newQuantity =
              mode === 'add' ? (item?.quantity || 0) + count : count;

            if (newQuantity <= 0) {
              return [...acc];
            }

            return [...acc, { ...item, quantity: newQuantity }];
          },
          [],
        );
      } else {
        newOrder.products = [
          ...newOrder.products,
          { ...product, quantity: count },
        ];
      }

      if (isAuth) {
        const type = count > 0 ? 'add' : 'remove';

        metrika.sendProductsStat({
          type,
          products: [
            {
              id: (thisProductInOrder || product)?.id.toString(),
              name: (thisProductInOrder || product)?.name,
              price: (thisProductInOrder || product)?.price,
              quantity: Math.abs(count),
            },
          ],
        });
      }

      return dispatch(
        changeOrder({
          ...newOrder,
          products: newOrder.products,
          totalPrice: newOrder.products.reduce((sum, current) => {
            return sum + current.price * current.quantity!;
          }, 0),
        }),
      );
    },
    [order, thisProductInOrder, isAuth, product, dispatch],
  );

  const deleteProduct = useCallback(() => {
    if (!thisProductInOrder) return;

    const productsCount = thisProductInOrder?.quantity || 0;

    if (isAuth) {
      metrika.sendProductsStat({
        type: 'remove',
        products: [
          {
            id: thisProductInOrder.id.toString(),
            name: thisProductInOrder.name,
            price: thisProductInOrder.price,
            quantity: productsCount,
          },
        ],
      });
    }

    return dispatch(
      changeOrder({
        ...order,
        products: order.products.filter(
          (item) => item.id !== product.id,
        ),
        totalPrice: order.products.reduce(
          (sum, current) => sum + current.price * current.quantity!,
          0,
        ),
      }),
    );
  }, [thisProductInOrder, dispatch, isAuth, order, product.id]);

  return {
    thisProductInOrder,
    currentProductQuantity,
    updateQuantity,
    deleteProduct,
  };
};
