import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { initialPageAlerts } from 'shared/const/initial-state.const';
import { TGlobalState } from 'shared/models/global-state.model';

const initialState: TGlobalState['pageAlerts'] = [
  ...initialPageAlerts,
];

const pageAlertsSlice = createSlice({
  name: 'pageAlerts',
  initialState,
  reducers: {
    addAlert(
      state,
      action: PayloadAction<TGlobalState['pageAlerts'][number]>,
    ) {
      window.scrollTo(0, 0);
      const index = state.findIndex(
        (alert) => alert.text === action.payload.text,
      );

      index === -1
        ? state.push(action.payload)
        : (state[index] = action.payload);
    },
    delAlert(state, action: PayloadAction<number>) {
      state.splice(action.payload, 1);
    },
    delAlertByText(state, action: PayloadAction<string>) {
      return state.filter((alert) => alert.text !== action.payload);
    },
  },
});

export const { addAlert, delAlert, delAlertByText } =
  pageAlertsSlice.actions;

export default pageAlertsSlice.reducer;
