import classname from 'classnames/bind';
import { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { getLoader } from 'store';

import ContentLoader from 'components/content-loader/content-loader';

import styles from './image-slider.module.css';

const cx = classname.bind(styles);

interface IProductImageProps {
  alt: string;
  classNameImg: string;
  classNameContainer: string;
  fileUrl: string;
}

export const ProductImage: FC<IProductImageProps> = memo((props) => {
  const { alt, classNameImg, classNameContainer, fileUrl } = props;
  const { loader } = useSelector(getLoader);

  if (loader) {
    return <ContentLoader placeholderType="image" />;
  }

  return (
    <div className={cx(classNameContainer)}>
      <img src={fileUrl} alt={alt} className={classNameImg} />
    </div>
  );
});

ProductImage.displayName = 'ProductImage';
