import classNames from 'classnames/bind';
import React, { FC, memo } from 'react';

import styles from './home.module.css';

const cx = classNames.bind(styles);
export const Article: FC = memo(() => {
  return (
    <div className="mt-4 mt-md-0">
      <div className={cx('sub-title')}>Как выбрать ФН?</div>
      <div className={`desc mt-3 ${cx('wrap')}`}>
        <p className={cx('paragraph')}>
          На сегодняшний день есть два вида фискальных накопителей,
          отличающихся друг от друга сроком действия: 15 и 36 месяцев.
        </p>
        <p className={cx('paragraph')}>
          Выбор фискального накопителя зависит от нескольких условий:
        </p>
        <ul className={cx('article-list')}>
          <li className={cx('article-item')}>
            Вид деятельности пользователя ККТ
          </li>
          <li className={cx('article-item')}>
            Наличие сезонного (временного) характера работ
          </li>
          <li className={cx('article-item')}>
            Категория реализуемых товаров
          </li>
          <li className={cx('article-item')}>
            Применяемая система налогообложения
          </li>
          <li className={cx('article-item')}>
            Режим передачи данных с ККТ
          </li>
          <li className={cx('article-item')}>
            Ограничение по сроку действия ФН, установленное в паспорте
            изготовителя ФН
          </li>
        </ul>
        <p className={cx('paragraph')}>
          Производители могут устанавливать дополнительные ограничения
          на срок действия и порядок применения ФН. Подробная
          информация указана в паспорте фискального накопителя.
        </p>
        <p className={cx('paragraph')}>
          Фискальный накопитель требует замены не только по истечении
          срока действия, но и при заполнении объема памяти.
        </p>
        <p className={cx('paragraph')}>
          Старый ФН нельзя выбрасывать — его необходимо хранить в
          течение 5 лет после окончания эксплуатации (п. 2 ст. 5
          Закона № 54-ФЗ).
        </p>
      </div>
    </div>
  );
});

Article.displayName = 'Article';
