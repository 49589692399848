import { FC, memo } from 'react';

import ProgressInfoBlock from 'components/progress-info-block/progress-info-block';

import awaitingDelivery from 'images/icons/awaiting-delivery.svg';
import deliveredIcon from 'images/icons/delivered.svg';
import deliveryDisabledIcon from 'images/icons/delivery-disabled.svg';

interface IDeliveryStatusIconProps {
  deliveryStatusName: string;
}

export const DeliveryStatusIcon: FC<IDeliveryStatusIconProps> = memo(
  ({ deliveryStatusName }) => {
    switch (deliveryStatusName) {
      case 'Ожидает отгрузку':
        return (
          <ProgressInfoBlock
            icon={awaitingDelivery}
            text={`Доставка ${deliveryStatusName}`}
            theme="warn"
          />
        );

      case 'Отгружен':
        return (
          <ProgressInfoBlock
            icon={deliveredIcon}
            text={deliveryStatusName}
            theme="success"
          />
        );

      default:
        return (
          <ProgressInfoBlock
            icon={deliveryDisabledIcon}
            text="Доставкa"
            theme="disabled"
          />
        );
    }
  },
);

DeliveryStatusIcon.displayName = 'DeliveryStatusIcon';
