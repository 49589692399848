export const tokens = {
  getAccess() {
    return localStorage.getItem('accessToken');
  },

  getRefresh() {
    return localStorage.getItem('refreshToken');
  },

  setAccess(token: string) {
    localStorage.setItem('accessToken', token);
  },

  setRefresh(token: string) {
    localStorage.setItem('refreshToken', token);
  },

  removeAccess() {
    localStorage.removeItem('accessToken');
  },

  removeRefresh() {
    localStorage.removeItem('refreshToken');
  },

  removeAll() {
    tokens.removeAccess();
    tokens.removeRefresh();
  },
};
