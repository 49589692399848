import classNames from 'classnames/bind';
import { FC, memo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppDispatch, getMultiLogin, getProductsState } from 'store';
import { addAlert } from 'store/pageAlerts.slice';

import { Article } from 'pages/article';

import ContentLoader from 'components/content-loader/content-loader';
import CustomBtn from 'components/custom-btn/custom-btn';
import DropdownList from 'components/dropdown-list/dropdown-list';
import ProductsCard from 'components/products-card/products-card';
import Table from 'components/table/table';

import { CONTENT, ONE_MINUTE, PRODUCTS_STATUSES } from 'shared/const';
import { tokens } from 'shared/utils/tokens.util';

import delivIcon from 'images/icons/delivIcon.svg';
import ffdIcon from 'images/icons/ffdIcon.svg';
import fnCollabIcon from 'images/icons/fn-collab.svg';
import fnMinIcon from 'images/icons/fn-min.svg';
import fnIcon from 'images/icons/fnIcon.svg';

import styles from './home.module.css';

const cx = classNames.bind(styles);

const TABLE_CATEGORIES_MAIN = ['Продажа товаров', 'Оказание услуг'];
const TABLE_CATEGORIES_SIMPLIFIED = [' '];
const TABLE_CATEGORIES_MIXED = [' '];

const TABLE_HEADERS = [
  {
    image: (
      <img src={fnMinIcon} alt="Иконка фискального накопителя" />
    ),
    text: '15 мес.',
  },
  {
    image: (
      <img src={fnMinIcon} alt="Иконка фискального накопителя" />
    ),
    text: '36 мес.',
  },
];

const TABLE_CONTENT_MAIN = [
  {
    key: 'Общий срок действия',
    value: [
      <span className={cx('optimal')}>470 дней</span>,
      <span className={cx('medium')}>560 дней</span>,
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.41408 7.49992L14.7071 12.7929C14.8892 12.9815 14.99 13.2341 14.9878 13.4963C14.9855 13.7585 14.8803 14.0093 14.6949 14.1947C14.5095 14.3801 14.2587 14.4853 13.9965 14.4876C13.7343 14.4899 13.4817 14.3891 13.2931 14.2069L8.00008 8.91392L2.70708 14.2069C2.61483 14.3024 2.50449 14.3786 2.38249 14.431C2.26048 14.4834 2.12926 14.511 1.99648 14.5122C1.8637 14.5133 1.73202 14.488 1.60913 14.4377C1.48623 14.3875 1.37458 14.3132 1.28069 14.2193C1.18679 14.1254 1.11254 14.0138 1.06226 13.8909C1.01198 13.768 0.986677 13.6363 0.987831 13.5035C0.988985 13.3707 1.01657 13.2395 1.06898 13.1175C1.12139 12.9955 1.19757 12.8852 1.29308 12.7929L6.58608 7.49992L1.29308 2.20692C1.11092 2.01832 1.01013 1.76571 1.01241 1.50352C1.01469 1.24132 1.11985 0.990509 1.30526 0.805101C1.49067 0.619692 1.74148 0.514523 2.00368 0.512245C2.26588 0.509966 2.51848 0.610761 2.70708 0.792919L8.00008 6.08592L13.2931 0.792919C13.4817 0.610761 13.7343 0.509966 13.9965 0.512245C14.2587 0.514523 14.5095 0.619692 14.6949 0.805101C14.8803 0.990509 14.9855 1.24132 14.9878 1.50352C14.99 1.76571 14.8892 2.01832 14.7071 2.20692L9.41408 7.49992Z"
          fill="#D12E34"
        />
      </svg>,
      <span className={cx('optimal')}>1100 дней</span>,
    ],
  },
  {
    key: 'Работа ККТ в автономном режиме',
    value: [
      <span className={cx('below')}>410 дней</span>,
      <span className={cx('medium')}>560 дней</span>,
      <span className={cx('below')}>410 дней</span>,
      <span className={cx('medium')}>560 дней</span>,
    ],
  },
  {
    key: 'Реализация подакцизных товаров',
    value: [
      <span className={cx('below')}>410 дней</span>,
      <span className={cx('below')}>410 дней</span>,
      <span className={cx('not-provided')} />,
      <span className={cx('not-provided')} />,
    ],
  },
  {
    key: 'Сезонный (временный) характер работ или платежные агенты (субагенты)',
    value: [
      <span className={cx('optimal')}>470 дней</span>,
      <span className={cx('medium')}>560 дней</span>,
      <span className={cx('optimal')}>470 дней</span>,
      <span className={cx('optimal')}>1100 дней</span>,
    ],
  },
  {
    key: 'Продажа маркированного средствами идентификации товара',
    value: [
      <span className={cx('below')}>410 дней</span>,
      <span className={cx('medium')}>560 дней</span>,
      <span className={cx('not-provided')} />,
      <span className={cx('not-provided')} />,
    ],
  },
  {
    key: 'При проведении азартных игр, лотерей, установке принтера в автомате, осуществлении ломбардной деятельности, страховой деятельности',
    value: [
      <span className={cx('below')}>410 дней</span>,
      <span className={cx('medium')}>560 дней</span>,
      <span className={cx('below')}>410 дней</span>,
      <span className={cx('optimal')}>1100 дней</span>,
    ],
  },
];

const TABLE_CONTENT_SIMPLIFIED = [
  {
    key: 'Общий срок действия',
    value: [
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.41408 7.49992L14.7071 12.7929C14.8892 12.9815 14.99 13.2341 14.9878 13.4963C14.9855 13.7585 14.8803 14.0093 14.6949 14.1947C14.5095 14.3801 14.2587 14.4853 13.9965 14.4876C13.7343 14.4899 13.4817 14.3891 13.2931 14.2069L8.00008 8.91392L2.70708 14.2069C2.61483 14.3024 2.50449 14.3786 2.38249 14.431C2.26048 14.4834 2.12926 14.511 1.99648 14.5122C1.8637 14.5133 1.73202 14.488 1.60913 14.4377C1.48623 14.3875 1.37458 14.3132 1.28069 14.2193C1.18679 14.1254 1.11254 14.0138 1.06226 13.8909C1.01198 13.768 0.986677 13.6363 0.987831 13.5035C0.988985 13.3707 1.01657 13.2395 1.06898 13.1175C1.12139 12.9955 1.19757 12.8852 1.29308 12.7929L6.58608 7.49992L1.29308 2.20692C1.11092 2.01832 1.01013 1.76571 1.01241 1.50352C1.01469 1.24132 1.11985 0.990509 1.30526 0.805101C1.49067 0.619692 1.74148 0.514523 2.00368 0.512245C2.26588 0.509966 2.51848 0.610761 2.70708 0.792919L8.00008 6.08592L13.2931 0.792919C13.4817 0.610761 13.7343 0.509966 13.9965 0.512245C14.2587 0.514523 14.5095 0.619692 14.6949 0.805101C14.8803 0.990509 14.9855 1.24132 14.9878 1.50352C14.99 1.76571 14.8892 2.01832 14.7071 2.20692L9.41408 7.49992Z"
          fill="#D12E34"
        />
      </svg>,
      <span className={cx('optimal')}>1100 дней</span>,
    ],
  },
  {
    key: 'Работа ККТ в автономном режиме',
    value: [
      <span className={cx('below')}>410 дней</span>,
      <span className={cx('medium')}>560 дней</span>,
    ],
  },
  {
    key: 'Реализация подакцизных товаров',
    value: [
      <span className={cx('below')}>410 дней</span>,
      <span className={cx('below')}>410 дней</span>,
    ],
  },
  {
    key: 'Сезонный (временный) характер работ или платежные агенты (субагенты)',
    value: [
      <span className={cx('optimal')}>470 дней</span>,
      <span className={cx('optimal')}>1100 дней</span>,
    ],
  },
  {
    key: 'Продажа маркированного средствами идентификации товара',
    value: [
      <span className={cx('below')}>410 дней</span>,
      <span className={cx('optimal')}>1100 дней</span>,
    ],
  },
  {
    key: 'При проведении азартных игр, лотерей, установке принтера в автомате, осуществлении ломбардной деятельности, страховой деятельности',
    value: [
      <span className={cx('below')}>410 дней</span>,
      <span className={cx('optimal')}>1100 дней</span>,
    ],
  },
];

const TABLE_CONTENT_MIXED = [
  {
    key: 'Общий срок действия',
    value: [
      <span className={cx('optimal')}>470 дней</span>,
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.41408 7.49992L14.7071 12.7929C14.8892 12.9815 14.99 13.2341 14.9878 13.4963C14.9855 13.7585 14.8803 14.0093 14.6949 14.1947C14.5095 14.3801 14.2587 14.4853 13.9965 14.4876C13.7343 14.4899 13.4817 14.3891 13.2931 14.2069L8.00008 8.91392L2.70708 14.2069C2.61483 14.3024 2.50449 14.3786 2.38249 14.431C2.26048 14.4834 2.12926 14.511 1.99648 14.5122C1.8637 14.5133 1.73202 14.488 1.60913 14.4377C1.48623 14.3875 1.37458 14.3132 1.28069 14.2193C1.18679 14.1254 1.11254 14.0138 1.06226 13.8909C1.01198 13.768 0.986677 13.6363 0.987831 13.5035C0.988985 13.3707 1.01657 13.2395 1.06898 13.1175C1.12139 12.9955 1.19757 12.8852 1.29308 12.7929L6.58608 7.49992L1.29308 2.20692C1.11092 2.01832 1.01013 1.76571 1.01241 1.50352C1.01469 1.24132 1.11985 0.990509 1.30526 0.805101C1.49067 0.619692 1.74148 0.514523 2.00368 0.512245C2.26588 0.509966 2.51848 0.610761 2.70708 0.792919L8.00008 6.08592L13.2931 0.792919C13.4817 0.610761 13.7343 0.509966 13.9965 0.512245C14.2587 0.514523 14.5095 0.619692 14.6949 0.805101C14.8803 0.990509 14.9855 1.24132 14.9878 1.50352C14.99 1.76571 14.8892 2.01832 14.7071 2.20692L9.41408 7.49992Z"
          fill="#D12E34"
        />
      </svg>,
    ],
  },
  {
    key: 'Работа ККТ в автономном режиме',
    value: [
      <span className={cx('below')}>410 дней</span>,
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.41408 7.49992L14.7071 12.7929C14.8892 12.9815 14.99 13.2341 14.9878 13.4963C14.9855 13.7585 14.8803 14.0093 14.6949 14.1947C14.5095 14.3801 14.2587 14.4853 13.9965 14.4876C13.7343 14.4899 13.4817 14.3891 13.2931 14.2069L8.00008 8.91392L2.70708 14.2069C2.61483 14.3024 2.50449 14.3786 2.38249 14.431C2.26048 14.4834 2.12926 14.511 1.99648 14.5122C1.8637 14.5133 1.73202 14.488 1.60913 14.4377C1.48623 14.3875 1.37458 14.3132 1.28069 14.2193C1.18679 14.1254 1.11254 14.0138 1.06226 13.8909C1.01198 13.768 0.986677 13.6363 0.987831 13.5035C0.988985 13.3707 1.01657 13.2395 1.06898 13.1175C1.12139 12.9955 1.19757 12.8852 1.29308 12.7929L6.58608 7.49992L1.29308 2.20692C1.11092 2.01832 1.01013 1.76571 1.01241 1.50352C1.01469 1.24132 1.11985 0.990509 1.30526 0.805101C1.49067 0.619692 1.74148 0.514523 2.00368 0.512245C2.26588 0.509966 2.51848 0.610761 2.70708 0.792919L8.00008 6.08592L13.2931 0.792919C13.4817 0.610761 13.7343 0.509966 13.9965 0.512245C14.2587 0.514523 14.5095 0.619692 14.6949 0.805101C14.8803 0.990509 14.9855 1.24132 14.9878 1.50352C14.99 1.76571 14.8892 2.01832 14.7071 2.20692L9.41408 7.49992Z"
          fill="#D12E34"
        />
      </svg>,
    ],
  },
  {
    key: 'Реализация подакцизных товаров',
    value: [
      <span className={cx('below')}>410 дней</span>,
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.41408 7.49992L14.7071 12.7929C14.8892 12.9815 14.99 13.2341 14.9878 13.4963C14.9855 13.7585 14.8803 14.0093 14.6949 14.1947C14.5095 14.3801 14.2587 14.4853 13.9965 14.4876C13.7343 14.4899 13.4817 14.3891 13.2931 14.2069L8.00008 8.91392L2.70708 14.2069C2.61483 14.3024 2.50449 14.3786 2.38249 14.431C2.26048 14.4834 2.12926 14.511 1.99648 14.5122C1.8637 14.5133 1.73202 14.488 1.60913 14.4377C1.48623 14.3875 1.37458 14.3132 1.28069 14.2193C1.18679 14.1254 1.11254 14.0138 1.06226 13.8909C1.01198 13.768 0.986677 13.6363 0.987831 13.5035C0.988985 13.3707 1.01657 13.2395 1.06898 13.1175C1.12139 12.9955 1.19757 12.8852 1.29308 12.7929L6.58608 7.49992L1.29308 2.20692C1.11092 2.01832 1.01013 1.76571 1.01241 1.50352C1.01469 1.24132 1.11985 0.990509 1.30526 0.805101C1.49067 0.619692 1.74148 0.514523 2.00368 0.512245C2.26588 0.509966 2.51848 0.610761 2.70708 0.792919L8.00008 6.08592L13.2931 0.792919C13.4817 0.610761 13.7343 0.509966 13.9965 0.512245C14.2587 0.514523 14.5095 0.619692 14.6949 0.805101C14.8803 0.990509 14.9855 1.24132 14.9878 1.50352C14.99 1.76571 14.8892 2.01832 14.7071 2.20692L9.41408 7.49992Z"
          fill="#D12E34"
        />
      </svg>,
    ],
  },
  {
    key: 'Сезонный (временный) характер работ или платежные агенты (субагенты)',
    value: [
      <span className={cx('optimal')}>470 дней</span>,
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.41408 7.49992L14.7071 12.7929C14.8892 12.9815 14.99 13.2341 14.9878 13.4963C14.9855 13.7585 14.8803 14.0093 14.6949 14.1947C14.5095 14.3801 14.2587 14.4853 13.9965 14.4876C13.7343 14.4899 13.4817 14.3891 13.2931 14.2069L8.00008 8.91392L2.70708 14.2069C2.61483 14.3024 2.50449 14.3786 2.38249 14.431C2.26048 14.4834 2.12926 14.511 1.99648 14.5122C1.8637 14.5133 1.73202 14.488 1.60913 14.4377C1.48623 14.3875 1.37458 14.3132 1.28069 14.2193C1.18679 14.1254 1.11254 14.0138 1.06226 13.8909C1.01198 13.768 0.986677 13.6363 0.987831 13.5035C0.988985 13.3707 1.01657 13.2395 1.06898 13.1175C1.12139 12.9955 1.19757 12.8852 1.29308 12.7929L6.58608 7.49992L1.29308 2.20692C1.11092 2.01832 1.01013 1.76571 1.01241 1.50352C1.01469 1.24132 1.11985 0.990509 1.30526 0.805101C1.49067 0.619692 1.74148 0.514523 2.00368 0.512245C2.26588 0.509966 2.51848 0.610761 2.70708 0.792919L8.00008 6.08592L13.2931 0.792919C13.4817 0.610761 13.7343 0.509966 13.9965 0.512245C14.2587 0.514523 14.5095 0.619692 14.6949 0.805101C14.8803 0.990509 14.9855 1.24132 14.9878 1.50352C14.99 1.76571 14.8892 2.01832 14.7071 2.20692L9.41408 7.49992Z"
          fill="#D12E34"
        />
      </svg>,
    ],
  },
  {
    key: 'Продажа маркированного средствами идентификации товара',
    value: [
      <span className={cx('below')}>410 дней</span>,
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.41408 7.49992L14.7071 12.7929C14.8892 12.9815 14.99 13.2341 14.9878 13.4963C14.9855 13.7585 14.8803 14.0093 14.6949 14.1947C14.5095 14.3801 14.2587 14.4853 13.9965 14.4876C13.7343 14.4899 13.4817 14.3891 13.2931 14.2069L8.00008 8.91392L2.70708 14.2069C2.61483 14.3024 2.50449 14.3786 2.38249 14.431C2.26048 14.4834 2.12926 14.511 1.99648 14.5122C1.8637 14.5133 1.73202 14.488 1.60913 14.4377C1.48623 14.3875 1.37458 14.3132 1.28069 14.2193C1.18679 14.1254 1.11254 14.0138 1.06226 13.8909C1.01198 13.768 0.986677 13.6363 0.987831 13.5035C0.988985 13.3707 1.01657 13.2395 1.06898 13.1175C1.12139 12.9955 1.19757 12.8852 1.29308 12.7929L6.58608 7.49992L1.29308 2.20692C1.11092 2.01832 1.01013 1.76571 1.01241 1.50352C1.01469 1.24132 1.11985 0.990509 1.30526 0.805101C1.49067 0.619692 1.74148 0.514523 2.00368 0.512245C2.26588 0.509966 2.51848 0.610761 2.70708 0.792919L8.00008 6.08592L13.2931 0.792919C13.4817 0.610761 13.7343 0.509966 13.9965 0.512245C14.2587 0.514523 14.5095 0.619692 14.6949 0.805101C14.8803 0.990509 14.9855 1.24132 14.9878 1.50352C14.99 1.76571 14.8892 2.01832 14.7071 2.20692L9.41408 7.49992Z"
          fill="#D12E34"
        />
      </svg>,
    ],
  },
  {
    key: 'При проведении азартных игр, лотерей, установке принтера в автомате, осуществлении ломбардной деятельности, страховой деятельности',
    value: [
      <span className={cx('below')}>410 дней</span>,
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.41408 7.49992L14.7071 12.7929C14.8892 12.9815 14.99 13.2341 14.9878 13.4963C14.9855 13.7585 14.8803 14.0093 14.6949 14.1947C14.5095 14.3801 14.2587 14.4853 13.9965 14.4876C13.7343 14.4899 13.4817 14.3891 13.2931 14.2069L8.00008 8.91392L2.70708 14.2069C2.61483 14.3024 2.50449 14.3786 2.38249 14.431C2.26048 14.4834 2.12926 14.511 1.99648 14.5122C1.8637 14.5133 1.73202 14.488 1.60913 14.4377C1.48623 14.3875 1.37458 14.3132 1.28069 14.2193C1.18679 14.1254 1.11254 14.0138 1.06226 13.8909C1.01198 13.768 0.986677 13.6363 0.987831 13.5035C0.988985 13.3707 1.01657 13.2395 1.06898 13.1175C1.12139 12.9955 1.19757 12.8852 1.29308 12.7929L6.58608 7.49992L1.29308 2.20692C1.11092 2.01832 1.01013 1.76571 1.01241 1.50352C1.01469 1.24132 1.11985 0.990509 1.30526 0.805101C1.49067 0.619692 1.74148 0.514523 2.00368 0.512245C2.26588 0.509966 2.51848 0.610761 2.70708 0.792919L8.00008 6.08592L13.2931 0.792919C13.4817 0.610761 13.7343 0.509966 13.9965 0.512245C14.2587 0.514523 14.5095 0.619692 14.6949 0.805101C14.8803 0.990509 14.9855 1.24132 14.9878 1.50352C14.99 1.76571 14.8892 2.01832 14.7071 2.20692L9.41408 7.49992Z"
          fill="#D12E34"
        />
      </svg>,
    ],
  },
];

const DROPDOWN_ITEMS = [
  {
    title: 'Для общей системы налогообложения (ОСН, ОСНО)',
    href: '#main-tax',
    content: (
      <Table
        tableCategory={TABLE_CATEGORIES_MAIN}
        tableHeader={TABLE_HEADERS}
        tableContent={TABLE_CONTENT_MAIN}
      />
    ),
    icon: null,
  },
  {
    title:
      'Для упрощённой, патентной систем налогообложения и единого сельскохозяйственного налога (УСН, ЕСХН, патент)',
    href: '#simplified-tax',
    content: (
      <Table
        tableCategory={TABLE_CATEGORIES_SIMPLIFIED}
        tableHeader={TABLE_HEADERS}
        tableContent={TABLE_CONTENT_SIMPLIFIED}
      />
    ),

    icon: null,
  },
  {
    title:
      'Для ОСН, совмещенной с другой системой налогообложения (в том числе при оказании услуг)',
    href: '#mixed-tax',
    content: (
      <Table
        tableCategory={TABLE_CATEGORIES_MIXED}
        tableHeader={TABLE_HEADERS}
        tableContent={TABLE_CONTENT_MIXED}
      />
    ),
    icon: null,
  },
];

export const Home: FC = memo(() => {
  const { products } = useSelector(getProductsState);
  const { productsRequest } = useSelector(getProductsState);
  const { logins } = useSelector(getMultiLogin);
  const isAuth = !!tokens.getAccess();
  const dispatch: AppDispatch = useDispatch();

  const handleScrollToTop = () => {
    if (isAuth && logins.length === 0) {
      dispatch(
        addAlert({
          text: CONTENT.auth.login.zeroOrganizations,
          variant: 'warning',
          lifetime: ONE_MINUTE,
        }),
      );
    }

    window.scrollTo(0, 0);
  };

  return (
    <div className="home">
      <Helmet>
        <meta
          name="keywords"
          content="фискальный накопитель, фн, фн замена, фн купить, фн 1.1, фн м, фн 36, фн атол, фн 15, фн касса, фн эвотор, фн цена, фн перерегистрация, фн штрих, фн инвента, фн накопитель, фн официальный, фн стоимость, фискальный накопитель купить, замена фискального накопителя, фискальный накопитель фн"
        />
        <meta
          name="description"
          content="Фискальный накопитель (ФН) устанавливается в онлайн-кассу. Устройство шифрует, записывает и хранит информацию о кассовых чеках. Из ФН информация в зашифрованном виде передается оператору фискальных данных (ОФД)."
        />
      </Helmet>

      <section className={styles.section}>
        <Container className={styles.container}>
          <div className="d-flex flex-column justify-content-start mt-5">
            <Row>
              <Col md={7}>
                <h1
                  className={`${styles['main-title']} text-center text-md-start`}
                >
                  Фискальные накопители
                  <br />в наличии и на заказ
                </h1>
              </Col>
            </Row>

            <Row>
              <Col md={3}>
                <div className={styles.btn}>
                  <Link to="/products">
                    <CustomBtn
                      theme="edo"
                      type="button"
                      text="Заказать"
                      disabled={false}
                      onClick={handleScrollToTop}
                      testId="product-link-1"
                      loading={false}
                      isLink={false}
                      customCls={null}
                    />
                  </Link>
                </div>
              </Col>
            </Row>
          </div>

          <div
            className="d-flex flex-column "
            style={{ marginTop: '25%' }}
          >
            <Row>
              <Col xs={12}>
                <ul
                  className={`${styles.list} d-block d-md-flex justify-content-between p-0 m-3 mt-5 mb-5`}
                >
                  <li className="item">
                    <div className="item-desc d-flex align-items-center">
                      <div className="item-icon">
                        <img src={fnIcon} alt="" />
                      </div>

                      <div
                        className={`${styles['item-title']} ms-3 ps-3 text-start`}
                      >
                        ФН <br /> на 15 / 36 мес.
                      </div>
                    </div>
                  </li>

                  <li className="item mt-5 mt-md-0">
                    <div className="item-desc d-flex align-items-center">
                      <div className="item-icon">
                        <img src={ffdIcon} alt="" />
                      </div>

                      <div
                        className={`${styles['item-title']} ms-3 ps-3 text-start`}
                      >
                        Поддержка <br /> ФФД 1.2
                      </div>
                    </div>
                  </li>

                  <li className="item mt-5 mt-md-0">
                    <div className="item-desc d-flex align-items-center">
                      <div className="item-icon">
                        <img src={delivIcon} alt="" />
                      </div>

                      <div
                        className={`${styles['item-title']} ms-3 ps-3 text-start`}
                      >
                        Доставка <br /> по всей России
                      </div>
                    </div>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
        </Container>
      </section>

      <div className={styles['text-block']}>
        <Container>
          <div className="pt-5 pb-5 text-center">
            Фискальный накопитель (ФН) устанавливается в онлайн-кассу.
            Устройство шифрует, записывает и хранит информацию о
            кассовых чеках. Из ФН информация в зашифрованном виде
            передается оператору фискальных данных (ОФД).
          </div>
        </Container>
      </div>

      <section className={styles.section}>
        <Container className={styles.container}>
          <div
            className={`${styles.main} d-flex flex-column justify-content-center`}
          >
            {productsRequest && (
              <ContentLoader placeholderType="product-card" />
            )}

            {!productsRequest && (
              <div className="item-list d-flex justify-content-between flex-wrap mb-4">
                {products?.length &&
                  products
                    .filter(
                      (product) =>
                        product.availableStatus !==
                        PRODUCTS_STATUSES.NOT_FOR_ORDER,
                    )
                    .map((product) => (
                      <div
                        className={styles['products-card']}
                        key={product.id}
                      >
                        <ProductsCard
                          counter={false}
                          product={product}
                          availableStatus={product.availableStatus}
                          indicator={false}
                          photo={product?.images ?? []}
                          imageStyle={styles['products-img']}
                        />
                      </div>
                    ))}

                {!products?.length && (
                  <p className="pt-3 pb-3">нет товаров</p>
                )}
              </div>
            )}

            <Row className="d-flex justify-content-center mt-4">
              <Col xs={12} md={3}>
                <div>
                  <Link
                    to="/products"
                    style={{ textDecoration: 'none' }}
                  >
                    <CustomBtn
                      theme="edo"
                      type="button"
                      text="Заказать"
                      disabled={false}
                      onClick={handleScrollToTop}
                      testId="product-link-2"
                      loading={false}
                      isLink={false}
                      customCls={null}
                    />
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>

      <div className={`${styles.separator} d-none d-md-block`}>
        <Container>
          <div className={styles['separator-icon']} />
        </Container>
      </div>

      <section className={styles.section}>
        <Container className={styles.container}>
          <div
            className={`${styles.main} d-flex flex-column justify-content-center`}
          >
            <div className={cx('article-wrapper')}>
              <Article />

              <div className={cx('article-icon')}>
                <img src={fnCollabIcon} alt="" />
              </div>
            </div>

            <DropdownList dropdownData={DROPDOWN_ITEMS} />
          </div>
        </Container>
      </section>
    </div>
  );
});

Home.displayName = 'Home';
