const GOAL_ID = 304862938;

export type TActionNames =
  // Просмотр списка товаров
  | 'impressions'
  // Клик по товару из списка
  | 'click'
  //Просмотр карточки товара
  | 'detail'
  // Добавление товара в корзину
  | 'add'
  // Удаление товара из корзины
  | 'remove'
  // Покупка
  | 'purchase';

type TProduct = {
  id: string;
  name: string;
  price: number | string;
  position?: number;
  brand?: string;
  category?: string;
  quantity?: number;
  list?: string;
};

type ProductData = {
  ecommerce: {
    currencyCode: string;
  } & {
    [key in TActionNames]?: TProduct[];
  };
};

type PurchaseData = {
  ecommerce: {
    currencyCode: string;
    purchase: {
      actionField: {
        id: string;
        goal_id: number;
      };
      products: TProduct[];
    };
  };
};

type TSendProductsStatFn = ({
  type,
  products,
}: {
  type: TActionNames;
  products: TProduct[];
}) => void;

type TSendPurchaseFn = ({
  actionFieldID,
  products,
}: {
  actionFieldID: string;
  products: TProduct[];
}) => void;

type TMetrika = {
  sendProductsStat: TSendProductsStatFn;
  sendPurchase: TSendPurchaseFn;
};

export const metrika: TMetrika = {
  sendProductsStat: ({ type, products }) => {
    const data: ProductData = {
      ecommerce: {
        currencyCode: 'RUB',
        [type]: {
          products,
        },
      },
    };
    (window as any).dataLayer.push(data);
  },

  sendPurchase: ({ actionFieldID, products }) => {
    const data: PurchaseData = {
      ecommerce: {
        currencyCode: 'RUB',
        purchase: {
          actionField: {
            id: actionFieldID,
            goal_id: GOAL_ID,
          },
          products,
        },
      },
    };
    (window as any).dataLayer.push(data);
  },
};
