import classNames from 'classnames/bind';
import { FC, ReactNode, memo } from 'react';

import styles from './table-row.module.css';

const cx = classNames.bind(styles);

interface ITableRowProps {
  tableContent: {
    key: string;
    value: ReactNode[];
  }[];
}

const TableRow: FC<ITableRowProps> = memo(({ tableContent }) => {
  return (
    <>
      {tableContent.map((item) => (
        <tr className={cx('row')} key={item.key}>
          <td className={cx('key')}>{item.key}</td>
          {item.value.map((value) => (
            <td
              key={item.value.length + Math.random()}
              align="center"
              className={cx({ value: item.value.length === 4 })}
            >
              {value}
            </td>
          ))}
        </tr>
      ))}
    </>
  );
});

TableRow.displayName = 'TableRow';

export default TableRow;
