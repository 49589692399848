import {
  PayloadAction,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { checkEdoUrl } from 'urls';

import { initialIsEdo } from 'shared/const/initial-state.const';
import { TGlobalState } from 'shared/models/global-state.model';
import { IError } from 'shared/models/simple-response.model';
import httpClient from 'shared/utils/http-client/http-client';
import { multiLoginStorage } from 'shared/utils/navigate-to-multi-login.utils';

import { logOutUser } from './auth.slice';
import { AppDispatch } from './index';

const initialState: TGlobalState['initIsEdo'] = { ...initialIsEdo };

const isEdoSlice = createSlice({
  name: 'isEdo',
  initialState,
  reducers: {
    isEdoRegistered(state) {
      state.isEdoRegisteredRequest = true;
      state.isEdoRegisteredFailed = false;
    },
    isEdoRegisteredSuccess(state, action: PayloadAction<boolean>) {
      state.isEdoRegistered = action.payload;
      state.isEdoRegisteredRequest = false;
    },
    isEdoRegisteredFailed(state) {
      state.isEdoRegisteredFailed = true;
      state.isEdoRegisteredRequest = false;
    },
  },
});

export const {
  isEdoRegistered,
  isEdoRegisteredSuccess,
  isEdoRegisteredFailed,
} = isEdoSlice.actions;

export default isEdoSlice.reducer;

export const checkEdo = createAsyncThunk<
  void,
  void,
  { dispatch: AppDispatch }
>('isEdo/checkEdo', async (_, { dispatch, rejectWithValue }) => {
  const orgId = multiLoginStorage.getOrgId();
  dispatch(isEdoRegistered());
  try {
    if (orgId) {
      const { data } = await httpClient.get(checkEdoUrl(orgId));
      dispatch(isEdoRegisteredSuccess(data));
      return data;
    }
  } catch (error) {
    const err = error as IError;

    dispatch(isEdoRegisteredFailed());

    if (err?.response?.status === 401) {
      dispatch(logOutUser());
    }

    return rejectWithValue(err.response.data.message);
  }
});
