import { configureStore } from '@reduxjs/toolkit';

import authSlice from './auth.slice';
import deliverySlice from './delivery.slice';
import docsSlice from './docs.slice';
import documentSlice from './document.slice';
import isEdoSlice from './isEdo.slice';
import isEdoFlowSlice from './isEdoFlow.slice';
import isOfferSlice from './isOffer.slice';
import loaderSlice from './loader.slice';
import menuSlice from './menu.slice';
import modalSlice from './modal.slice';
import multiLoginSlice from './multiLogin.slice';
import orderSlice from './order.slice';
import ordersSlice from './orders.slice';
import orgSlice from './org.slice';
import pageAlertsSlice from './pageAlerts.slice';
import productSlice from './product.slice';
import successOrderSlice from './successOrder.slice';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    loader: loaderSlice,
    menu: menuSlice,
    pageAlerts: pageAlertsSlice,
    org: orgSlice,
    docs: docsSlice,
    products: productSlice,
    document: documentSlice,
    description: documentSlice,
    characteristic: documentSlice,
    order: orderSlice,
    orders: ordersSlice,
    isEdoFlow: isEdoFlowSlice,
    isEdo: isEdoSlice,
    isOffer: isOfferSlice,
    modal: modalSlice,
    deliveryData: deliverySlice,
    successOrder: successOrderSlice,
    multiLogin: multiLoginSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const getAuth = (state: RootState) => state.auth;
export const getLoader = (state: RootState) => state.loader;
export const getMenu = (state: RootState) => state.menu;
export const getPageAlerts = (state: RootState) => state.pageAlerts;
export const getOrgState = (state: RootState) => state.org;
export const getDocsState = (state: RootState) => state.docs;
export const getProductsState = (state: RootState) => state.products;
export const getDocument = (state: RootState) => state.document;
export const getDescription = (state: RootState) => state.description;
export const getCharacteristic = (state: RootState) =>
  state.characteristic;
export const getOrder = (state: RootState) => state.order;
export const getOrders = (state: RootState) => state.orders;
export const getIsEdoFlow = (state: RootState) => state.isEdoFlow;
export const getIsEdo = (state: RootState) => state.isEdo;
export const getIsOffer = (state: RootState) => state.isOffer;
export const getModals = (state: RootState) => state.modal;
export const getDeliveryState = (state: RootState) =>
  state.deliveryData;
export const getSuccessOrder = (state: RootState) =>
  state.successOrder;
export const getMultiLogin = (state: RootState) => state.multiLogin;
