export const CONTENT = {
  order: {
    notAuthWarning:
      "Для оформления заказа необходимо войти в учетную запись. Используйте учетные данные ЛК 'Платформа ОФД'",
    orderHasBeenPaid: 'Заказ уже был оплачен ранее',
    paymentRequestError: 'Ошибка с запросом платежа',
  },

  auth: {
    registration: {
      success:
        'Вы успешно зарегистрировались! Для доступа к полным функциям сайта, пожалуйста, ',

      duplicate:
        'Данный пользователь (организация) уже зарегистрирован в системе. Войдите в кабинет, используя логин и пароль от Личного кабинета "Торговый Дом Платформа", или авторизуйтесь с помощью "Платформа ОФД"',
    },

    login: {
      badCredentials: 'Неверные логин или пароль',
      zeroOrganizations:
        'Для работы необходимы данные об организации. Добавьте организацию в личном ',
    },
  },

  http: {
    invalid: 'Неверный запрос',

    blocked: 'Ресурс заблокирован',

    notFound: 'Ресурс не найден',

    serverError: 'Ошибка сервера. Повторите попытку позже.',
  },
};
