import React, { useCallback, useState } from 'react';

import { useValidation } from 'shared/hooks/use-validation';

import { ERROR_MESSAGES } from '../conts';

export const useEmailInput = () => {
  const [value, setValue] = useState('');
  const [error, setError] = useState('');

  const { validateEmail } = useValidation();

  const resetError = useCallback(() => setError(''), []);

  const checkValidation = useCallback(() => {
    const isValid = validateEmail(value);
    setError(() => (isValid ? '' : ERROR_MESSAGES.email));
    return isValid;
  }, [validateEmail, value]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
    },
    [],
  );

  return {
    value,
    error,
    resetError,
    handleChange,
    checkValidation,
  };
};
