import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getMultiLogin } from 'store';

import { USER_TYPES } from '../const';

export const usePartner = () => {
  const { type } = useSelector(getMultiLogin);

  return useMemo(() => type === USER_TYPES.PK, [type]);
};
