import { FC, memo, useCallback } from 'react';

import { ListItem, ListProps } from './list.model';
import styles from './list.module.css';

export const List: FC<ListProps> = memo((props) => {
  const {
    items,
    onSelect,
    maxHeight = '155px',
    ulCustomClass = '',
    itemCustomClass = '',
  } = props;

  const handleItemClick = useCallback(
    (data: ListItem) => {
      onSelect(data);
    },
    [onSelect],
  );

  return (
    <ul
      className={`${styles.select} ${ulCustomClass}`}
      style={{ maxHeight }}
    >
      {items.map((item) => (
        <li
          className={`${styles.item} ${itemCustomClass}`}
          key={item.id}
          onClick={() => handleItemClick(item)}
        >
          <div className={styles.name}>{item.text}</div>
          <div className={styles.inn}>
            ИНН: {item.inn} / КПП: {item.kpp}
          </div>
        </li>
      ))}
    </ul>
  );
});

List.displayName = 'List';

export default List;
