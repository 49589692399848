import classNames from 'classnames/bind';
import { FC, memo } from 'react';

import styles from './header-loader.module.css';

const cx = classNames.bind(styles);

export const HeaderLoader: FC = memo(() => {
  return (
    <div className={cx('container')}>
      <div className={cx('logo', 'loading')} />
      <div className={cx('loading', 'menu')} />
      <div className={cx('loading', 'user')} />
    </div>
  );
});

HeaderLoader.displayName = 'HeaderLoader';
