import cx from 'classnames';
import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { currency } from 'utils';

import LoaderSpinner from 'components/loader-spinner/loader-spinner';

import { IOptions } from 'shared/models/delivery.model';

import arrowIcon from 'images/icons/arrow.svg';
import resetIcon from 'images/icons/close.svg';

import styles from './custom-select.module.css';

interface ICustomSelectProps {
  placeholder: string;
  options: IOptions[];
  disabled: boolean;
  onChange: (e: string) => void;
  onReset: () => void;
  testId: string;
  defValue: string;
  isLoading: boolean;
  legacyMode?: boolean;
  isClearDelivery: boolean;
}

const CustomSelect: FC<ICustomSelectProps> = memo((props) => {
  const {
    placeholder,
    options,
    disabled,
    onChange,
    onReset,
    testId,
    defValue,
    isLoading,
    isClearDelivery,
  } = props;
  const [defaultOption, setDefaultOption] = useState(true);
  const [value, setValue] = useState(defValue);

  const reset = useCallback(() => {
    setDefaultOption(true);
    setValue('');
    if (onReset) {
      onReset();
    }
  }, [onReset]);

  useEffect(() => {
    if (defValue === '' || isClearDelivery) {
      reset();
      return;
    }
    setDefaultOption(false);
    setValue(defValue);
    // eslint-disable-next-line
  }, [isClearDelivery]);

  useEffect(() => {
    reset();
    return;
    // eslint-disable-next-line
  }, []);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      e.preventDefault();
      setDefaultOption(false);
      setValue(e.target.value);
      if (onChange) {
        onChange(e.target.value);
      }
    },
    [onChange],
  );

  const handleReset = useCallback(() => {
    reset();
  }, [reset]);

  return (
    <div className={styles['order-select']}>
      <label className={styles.label}>{placeholder}</label>

      <select
        data-testid={`custom-select-${testId}`}
        size={1}
        onChange={handleChange}
        disabled={disabled !== undefined ? disabled : false}
        value={value}
        name="CustomSelect"
        className={cx(styles.select, styles['select-sort'], {
          [styles['option-default']]: defaultOption,
        })}
        required
      >
        <option value="" disabled hidden>
          {placeholder}
        </option>

        {options.map((optionObj) => (
          <option
            className={styles.option}
            key={optionObj.urgentTypeId}
            value={optionObj.urgentTypeId}
          >
            {`${optionObj.vendorName.split(' ')[0]} ${
              optionObj.maxDays === optionObj.minDays
                ? `${optionObj.maxDays} д.`
                : `${optionObj.minDays} - ${optionObj.maxDays} д.`
            } - ${currency(optionObj.price)} ₽`}
          </option>
        ))}
      </select>

      {isLoading && <LoaderSpinner />}

      {!isLoading && (
        <>
          <img
            src={arrowIcon}
            className={cx(styles['icon-arrow'], {
              [styles['show-arrow']]: defaultOption,
            })}
            alt="Развернуть список"
          />

          <button
            className={cx(styles['icon-reset'], {
              [styles['show-cross']]: !defaultOption,
            })}
            type="button"
            onClick={handleReset}
          >
            <img src={resetIcon} alt="Сбросить данные поля" />
          </button>
        </>
      )}
    </div>
  );
});

CustomSelect.displayName = 'CustomSelect';

export default CustomSelect;
