import Keycloak from 'keycloak-js';

import { isProd } from './hosts.const';

export const MIN_VALIDITY_SECONDS = 60 * 60 * 12;

const DEV_OPTIONS = {
  url: 'https://oauth-test.platformaofd.ru',
  realm: 'playground',
  clientId: 'pofd-sso',
};

const PROD_OPTIONS = {
  url: 'https://oauth.platformaofd.ru',
  realm: 'production',
  clientId: 'pofd-sso',
};

const options = isProd ? PROD_OPTIONS : DEV_OPTIONS;

export const keycloak = new Keycloak(options);
