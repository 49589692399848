import { FC, memo } from 'react';

import styles from './progress-line.module.css';

interface IProgressLineProps {
  theme: string;
  percent?: number;
}

const ProgressLine: FC<IProgressLineProps> = memo(
  ({ theme, percent }) => {
    return (
      <div className={styles.main}>
        <div className={`${styles.back} ${styles[theme]}`} />

        <div
          className={`${styles.progress} ${styles.theme}`}
          style={{ width: `${100 - percent!}%` }}
        />
      </div>
    );
  },
);

ProgressLine.displayName = 'ProgressLine';

export default ProgressLine;
