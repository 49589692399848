import cx from 'classnames';
import {
  FC,
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';

import DropdownContent from 'components/dropdown-content/dropdown-content';

import arrow from 'images/icons/arrow.svg';

import styles from './dropdown-item.module.css';

interface IDropdownItemProps {
  title: string;
  icon: ReactNode | null;
  content: ReactNode;
  href: string;
  testId: string;
}

const DropdownItem: FC<IDropdownItemProps> = memo(
  ({ title, icon, content, href, testId }) => {
    const [openFlag, setOpenFlag] = useState(false);

    const handleShowRule = useCallback(() => {
      setOpenFlag((prevState) => !prevState);
    }, [setOpenFlag]);

    useEffect(() => {
      if (window.location.hash === href) {
        setOpenFlag((prevState) => !prevState);
      }
    }, [href]);

    return (
      <div className={styles.wrapper}>
        <section
          className={styles.rule}
          onClick={handleShowRule}
          aria-hidden="true"
          data-testid={testId}
        >
          <a href={href}>
            <div className={styles.inner}>
              <div className={styles.left}>
                <h3 className={styles.title}>{title}</h3>
                {icon}
              </div>
              <img
                className={cx(styles.arrow, {
                  [styles.active]: openFlag,
                })}
                src={arrow}
                alt="Развернуть блок"
              />
            </div>
          </a>
        </section>
        <DropdownContent openFlag={openFlag} content={content} />
      </div>
    );
  },
);

DropdownItem.displayName = 'DropdownItem';

export default DropdownItem;
