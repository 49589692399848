import { FC, memo, useMemo, useState } from 'react';
import { getPaymentInvoiceLink } from 'urls';

import { Icon } from 'components/Icon';
import DownloadLink from 'components/download-link/download-link';

interface PayStatusIconProps {
  payStatusName: string;
  orderId: number;
  invoiceFileInfo?: {
    orderId?: number;
    docType?: string;
    docId?: number;
    docFile?: {
      docName?: string;
      docBody?: string;
    };
  };
}

export const PayStatusIcon: FC<PayStatusIconProps> = memo(
  ({ payStatusName, orderId, invoiceFileInfo = null }) => {
    let statusIcon;
    const [showIcon, setShowIcon] = useState(false);

    const isPaid = useMemo(() => {
      return payStatusName !== 'Не оплачен';
    }, [payStatusName]);

    switch (payStatusName) {
      case 'Не оплачен':
        statusIcon = <Icon name="paidErr" width="32" height="32" />;
        break;

      case 'Оплачен':
        statusIcon = (
          <Icon name="paidSuccess" width="32" height="32" />
        );
        break;

      case 'Частично оплачен':
        statusIcon = (
          <Icon name="readyShipment" width="32" height="32" />
        );
        break;

      default:
        statusIcon = (
          <Icon name="paidDisabled" width="32" height="32" />
        );
        break;
    }

    return (
      <div
        onMouseEnter={() => isPaid && setShowIcon(true)}
        onMouseLeave={() => setShowIcon(false)}
        onClick={(e) => e.stopPropagation()}
        role="button"
        tabIndex={0}
        aria-hidden="true"
      >
        <DownloadLink
          link={getPaymentInvoiceLink(orderId)}
          payload={{ responseType: 'blob' }}
          textLink="Скачать счет на оплату"
          linkStyle="icon"
          fileName={invoiceFileInfo?.docFile?.docName}
          documentType="application/pdf"
          iconSrc={statusIcon}
          showedDownloadIcon={isPaid && showIcon}
          isDisabled={!isPaid}
        />
      </div>
    );
  },
);

PayStatusIcon.displayName = 'PayStatusIcon';
