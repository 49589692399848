import { HOSTS } from './hosts.const';

type TMenuPaths = '/' | '/products' | '/orders' | '/information';

const menuHrefs = {
  [HOSTS.lk.dev.inner]: {
    '/': HOSTS.wp.prod.external,
    '/products': HOSTS.lk.dev.inner,
    '/orders': HOSTS.lk.dev.inner,
    '/information': HOSTS.lk.dev.inner,
  },
  [HOSTS.lk.dev.external]: {
    '/': HOSTS.wp.prod.external,
    '/products': HOSTS.lk.dev.external,
    '/orders': HOSTS.lk.dev.external,
    '/information': HOSTS.lk.dev.external,
  },
  [HOSTS.lk.prod.inner]: {
    '/': HOSTS.wp.prod.external,
    '/products': HOSTS.lk.prod.inner,
    '/orders': HOSTS.lk.prod.inner,
    '/information': HOSTS.lk.prod.inner,
  },
  [HOSTS.lk.prod.external]: {
    '/': HOSTS.wp.prod.external,
    '/products': HOSTS.lk.prod.external,
    '/orders': HOSTS.lk.prod.external,
    '/information': HOSTS.lk.prod.external,
  },
  [HOSTS.wp.prod.external]: {
    '/': HOSTS.wp.prod.external,
    '/products': HOSTS.wp.prod.external,
    '/orders': HOSTS.wp.prod.external,
    '/information': HOSTS.wp.prod.external,
  },
  [HOSTS.local.dev.inner]: {
    '/': HOSTS.local.dev.inner,
    '/products': HOSTS.local.dev.inner,
    '/orders': HOSTS.local.dev.inner,
    '/information': HOSTS.local.dev.inner,
  },
};

export const createMenuHrefs = (path: TMenuPaths) => {
  return '//' + menuHrefs[window.location.host][path] + path;
};
