import { FC, memo, useMemo, useState } from 'react';
import { downloadXlxsOrdersUrl } from 'urls';

import { Icon } from 'components/Icon';
import DownloadLink from 'components/download-link/download-link';

import { multiLoginStorage } from 'shared/utils/navigate-to-multi-login.utils';

interface FnListIconProps {
  orderId: number;
  deliveryStatusName: string;
}

export const FnListIcon: FC<FnListIconProps> = memo(
  ({ orderId, deliveryStatusName }) => {
    const [showIcon, setShowIcon] = useState(false);
    const orgId = multiLoginStorage.getOrgId();

    const isShipped = useMemo(
      () =>
        ['Отгружен', 'Готов к выдаче'].includes(deliveryStatusName),
      [deliveryStatusName],
    );

    return (
      <>
        <div
          onMouseEnter={() => isShipped && setShowIcon(true)}
          onMouseLeave={() => setShowIcon(false)}
          onClick={(e) => e.stopPropagation()}
          role="button"
          tabIndex={0}
          aria-hidden="true"
        >
          <DownloadLink
            link={downloadXlxsOrdersUrl(orgId, orderId)}
            payload={{ responseType: 'blob' }}
            textLink="Серийные номера"
            linkStyle="icon"
            fileName="Серийные номера.xlsx"
            documentType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            iconSrc={<Icon name="fnList" width="32" height="32" />}
            showedDownloadIcon={isShipped && showIcon}
            isDisabled={!isShipped}
          />
        </div>
      </>
    );
  },
);

FnListIcon.displayName = 'FnListIcon';
