import { FC, memo } from 'react';

import image from 'images/404.svg';

import styles from './not-found-404.module.css';

export const NotFound404: FC = memo(() => (
  <div className={styles['not-found']}>
    <img className={`${styles.image} mt-5`} src={image} alt="" />
    <div className={styles.header}>Страница не найдена</div>
    <p className={`${styles.text} mt-3 mb-5`}>
      Возможно, вы воспользовались недействительной ссылкой или
      страница была удалена.
    </p>
  </div>
));

NotFound404.displayName = 'NotFound404';
