import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { initialSuccessOrder } from 'shared/const/initial-state.const';
import { TGlobalState } from 'shared/models/global-state.model';

const initialState: TGlobalState['initSuccessOrder'] = {
  ...initialSuccessOrder,
};

const successOrderSlice = createSlice({
  name: 'successOrder',
  initialState,
  reducers: {
    setSuccessOrder(state, action: PayloadAction<boolean>) {
      state.successOrder = action.payload;
    },
  },
});

export const { setSuccessOrder } = successOrderSlice.actions;

export default successOrderSlice.reducer;
