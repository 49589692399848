import { FC, memo } from 'react';
import Checkbox from 'react-custom-checkbox';

import styles from './custom-checkbox.module.css';
import { IconCheckbox } from './icon-checkbox';

interface ICustomCheckboxProps {
  margin: string;
  label: string;
  checked: boolean;
  disabled: boolean;
}

const CustomCheckbox: FC<ICustomCheckboxProps> = memo(
  ({ margin, label, checked, disabled }) => (
    <div
      className={`${styles.main} ${
        margin ? styles[`${margin}`] : ''
      } ${disabled ? styles.disabled : ''}`}
    >
      <Checkbox
        disabled={disabled || false}
        checked={checked || false}
        icon={<IconCheckbox />}
        borderColor="#EA8242"
        borderRadius={2}
        size={16}
        label={label}
      />
    </div>
  ),
);

CustomCheckbox.displayName = 'CustomCheckbox';

export default CustomCheckbox;
