import React, { FC, memo } from 'react';

import styles from './search-input.module.css';

interface ISearchInputProps {
  searchOrg: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SearchInput: FC<ISearchInputProps> = memo(
  ({ searchOrg, handleChange }) => {
    return (
      <div className={styles['input-container']}>
        <label className={styles.label} htmlFor="input">
          Поиск компании
        </label>
        <input
          className={styles.input}
          value={searchOrg}
          onChange={handleChange}
          placeholder="Введите название организации, пользовательское название компании"
          id="input"
          type="text"
        />
        <div className={styles['gradient-input']} />
      </div>
    );
  },
);

SearchInput.displayName = 'SearchInput';
