import classNames from 'classnames/bind';
import { FC, memo } from 'react';

import styles from './content-loader.module.css';

const cx = classNames.bind(styles);

export const HistoryOrderLoader: FC = memo(() => {
  return (
    <div className={cx('placeholder-wrapper')}>
      <div className={cx('base-placeholder', 'placeholder-row')} />
      <div className={cx('base-placeholder', 'placeholder-row')} />
      <div className={cx('base-placeholder', 'placeholder-row')} />
      <div className={cx('base-placeholder', 'placeholder-row')} />
      <div className={cx('base-placeholder', 'placeholder-row')} />
      <div className={cx('base-placeholder', 'placeholder-row')} />
      <div className={cx('base-placeholder', 'placeholder-row')} />
      <div className={cx('base-placeholder', 'placeholder-row')} />
      <div className={cx('base-placeholder', 'placeholder-row')} />
    </div>
  );
});

HistoryOrderLoader.displayName = 'HistoryOrderLoader';
