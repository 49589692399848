import { FC, memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, getDocsState } from 'store';
import { logOutUser } from 'store/auth.slice';
import { isOfferAcceptedFailed } from 'store/isOffer.slice';
import { addAlert } from 'store/pageAlerts.slice';
import {
  acceptOfferUrl,
  createAndAcceptOfferUrl,
  getOfferUrl,
} from 'urls';
import { saveDoc } from 'utils';

import CustomBtn from 'components/custom-btn/custom-btn';

import { IError } from 'shared/models/simple-response.model';
import httpClient from 'shared/utils/http-client/http-client';
import { multiLoginStorage } from 'shared/utils/navigate-to-multi-login.utils';

import offerImg from 'images/accept-offer.svg';

import styles from '../edo-registration/edo-registration.module.css';

interface IOfferAcceptanceFormProps {
  onShowReady: () => void;
}

const OfferAcceptanceForm: FC<IOfferAcceptanceFormProps> = memo(
  ({ onShowReady }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { docs } = useSelector(getDocsState);

    const [loadingOffer, setLoading] = useState(false);
    const [loadingAcc, setLoadingAcc] = useState(false);
    const orgId = multiLoginStorage.getOrgId();

    const getOffer = useCallback(async () => {
      setLoading(true);
      try {
        const response = await httpClient.get(
          `${getOfferUrl(orgId)}?docType=400`,
          {
            responseType: 'blob',
          },
        );
        saveDoc({
          response,
          fileType: 'application/pdf',
          defaultFileName: 'offerta.pdf',
        });
      } catch (error) {
        const err = error as IError;
        if (err?.response?.status === 401) {
          return dispatch(logOutUser());
        }
        dispatch(
          addAlert({
            text: 'Ошибка получения оферты',
            variant: 'danger',
          }),
        );
      } finally {
        setLoading(false);
      }
    }, [dispatch, orgId]);

    const createOffer = useCallback(async () => {
      try {
        const response = await httpClient.get(
          `${createAndAcceptOfferUrl(orgId)}?docType=400`,
        );
        if (response.status === 200) {
          onShowReady();
        }
      } catch (error) {
        dispatch(isOfferAcceptedFailed());

        const err = error as IError;
        if (err?.response?.status === 401) {
          return dispatch(logOutUser());
        }
        dispatch(
          addAlert({
            text: 'Ошибка Создания оферты',
            variant: 'danger',
          }),
        );
      } finally {
        setLoadingAcc(false);
      }
    }, [dispatch, onShowReady, orgId]);

    const acceptOffer = useCallback(
      async (offerDocId: number) => {
        try {
          const response = await httpClient.get(
            `${acceptOfferUrl(orgId)}?docId=${offerDocId}`,
          );
          if (response.status === 200) {
            onShowReady();
          }
        } catch (error) {
          dispatch(isOfferAcceptedFailed());

          const err = error as IError;
          if (err?.response?.status === 401) {
            return dispatch(logOutUser());
          }
          dispatch(
            addAlert({
              text: 'Ошибка проверки оферты',
              variant: 'danger',
            }),
          );
        } finally {
          setLoadingAcc(false);
        }
      },
      [dispatch, onShowReady, orgId],
    );

    const acceptAndContinue = useCallback(() => {
      setLoadingAcc(true);

      let offerCreated = false;
      let offerDocId = 0;

      if (docs) {
        Object.keys(docs).forEach((_, i) => {
          if (docs[i].docTypeId === 400) {
            offerDocId = docs[i].docId;
            offerCreated = true;
          }
        });
      }

      if (!offerCreated) {
        createOffer();
      } else {
        acceptOffer(offerDocId);
      }
    }, [acceptOffer, createOffer, docs]);

    return (
      <>
        <img
          className="mx-auto"
          src={offerImg}
          height="225px"
          alt=""
        />

        <p className={`${styles.text} mt-2`}>
          Для оформления заказа необходимо заключить договор оферту на
          поставку с ООО «Торговый дом Платформа».
        </p>

        <p className={styles.text}>
          Ознакомьтесь и примите условия соглашения на поставку, для
          оформления заказа.
        </p>

        <div className="mt-auto">
          <CustomBtn
            theme="lkfn"
            type="button"
            text={loadingOffer ? 'Загружаем...' : 'Ознакомиться'}
            disabled={loadingOffer}
            onClick={getOffer}
            testId="offer-1"
            loading={loadingOffer}
            isLink={false}
          />

          <CustomBtn
            theme="edo"
            type="button"
            text={
              loadingAcc ? 'Идет запрос...' : 'Принять и продолжить'
            }
            disabled={loadingAcc}
            onClick={acceptAndContinue}
            testId="offer-2"
            loading={loadingAcc}
            isLink={false}
            customCls="mt-2"
          />
        </div>
      </>
    );
  },
);

OfferAcceptanceForm.displayName = 'OfferAcceptanceForm';

export default OfferAcceptanceForm;
