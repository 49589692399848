import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import {
  AppDispatch,
  getModals,
  getOrgState,
  getPageAlerts,
  getProductsState,
} from 'store';
import { logOutUser } from 'store/auth.slice';
import { getDocs, setDocTypes } from 'store/docs.slice';
import { checkEdo } from 'store/isEdo.slice';
import { checkDocFlow } from 'store/isEdoFlow.slice';
import { checkOffer } from 'store/isOffer.slice';
import { getOrganization } from 'store/multiLogin.slice';
import { changeOrder } from 'store/order.slice';
import { getOrg } from 'store/org.slice';
import {
  addAlert,
  delAlert,
  delAlertByText,
} from 'store/pageAlerts.slice';
import { getProducts } from 'store/product.slice';
import { fileTypesUrl } from 'urls';

import { Home } from 'pages/home';
import { MultiLogin } from 'pages/multi-login';
import { NotFound404 } from 'pages/not-found-404';
import { Order } from 'pages/order';
import { Orders } from 'pages/orders';
import { ProductCard } from 'pages/product-card';
import { ProductsPage } from 'pages/products-page';
import { Profile } from 'pages/profile';
import { ServerError500 } from 'pages/server-error-500';
import { UserInformation } from 'pages/user-information';

import AppFooter from 'components/app-footer/app-footer';
import AppHeader from 'components/app-header/app-header';
import { Layout } from 'components/layout/layout';
import ModalCustomEdooffer from 'components/modal-custom-edooffer/modal-custom-edooffer';
import PageAlerts from 'components/page-alerts/page-alerts';
import ProtectedRoute from 'components/protected-route/protected-route';

import { CONTENT } from 'shared/const';
import { useAuth } from 'shared/hooks/use-auth';
import { ProductResponse } from 'shared/models/global-state.model';
import { IError } from 'shared/models/simple-response.model';
import httpClient from 'shared/utils/http-client/http-client';
import { multiLoginStorage } from 'shared/utils/navigate-to-multi-login.utils';
import { tokens } from 'shared/utils/tokens.util';

import styles from './app.module.css';

const ORG_ID_MAX_LENGTH = 18;

const App: FC = memo(() => {
  const { modal } = useSelector(getModals);
  const { org } = useSelector(getOrgState);
  const { products } = useSelector(getProductsState);
  const pageAlerts = useSelector(getPageAlerts);
  const id = window.location.search.slice(7);

  const dispatch: AppDispatch = useDispatch();
  const auth = useAuth();
  const isAuth = !!tokens.getAccess() && !!tokens.getRefresh();
  const url = new URL(window.location.href);
  const token = url.searchParams.get('token');
  const refreshToken = url.searchParams.get('refresh_token');
  const serverErrorRoute = '/error500';
  const [curPage, setCurPage] = useState('');

  const getFileTypes = useCallback(async () => {
    try {
      const response = await httpClient.get(fileTypesUrl);
      if (response.data.length) {
        dispatch(setDocTypes(response.data));
      }
    } catch (error) {
      const err = error as IError;

      if (err?.response?.status === 401) {
        return dispatch(logOutUser());
      }
      dispatch(
        addAlert({
          text: 'Ошибка получения типов документов',
          variant: 'danger',
        }),
      );
    }
  }, [dispatch]);

  const getCurrentPage = useCallback((path: string) => {
    setCurPage(path);
  }, []);

  /** Получение данных о мультилогине */
  useEffect(() => {
    if (window.location.pathname === '/error500') return;
    if (auth?.isDataLoading) return;

    if (id && id.length <= ORG_ID_MAX_LENGTH && isAuth) {
      multiLoginStorage.setOrgId(id);
    }
    dispatch(getOrganization());
  }, [auth?.isDataLoading, dispatch, id, isAuth]);

  /** Получение типов документов */
  useEffect(() => {
    if (auth?.isDataLoading) return;
    getFileTypes();
  }, [auth?.isDataLoading, getFileTypes]);

  /** Авторизация на сайте по токенам из URL */
  useEffect(() => {
    if (token && refreshToken) {
      tokens.setAccess(token);
      tokens.setRefresh(refreshToken);
    }
  }, [token, refreshToken, dispatch]);

  /** Получение списка товаров */
  useEffect(() => {
    if (auth?.isDataLoading) return;

    if (window.location.pathname !== serverErrorRoute) {
      dispatch(getProducts());
    }

    if (isAuth) {
      dispatch(checkDocFlow()).then((isEdoFlow) => {
        if (isEdoFlow) {
          dispatch(checkEdo()).then(() => {
            dispatch(checkOffer());
          });
        } else {
          dispatch(checkOffer());
        }
      });
    }
  }, [auth?.isDataLoading, dispatch, isAuth]);

  /** Получение информации о профиле пользователя */
  useEffect(() => {
    if (auth?.isDataLoading) return;
    if (isAuth && !org) dispatch(getOrg());
  }, [auth?.isDataLoading, dispatch, isAuth, org]);

  /** Получение документов */
  useEffect(() => {
    if (auth?.isDataLoading) return;
    if (isAuth) dispatch(getDocs());
  }, [auth?.isDataLoading, dispatch, isAuth]);

  /** Вывод алерта о необходимости войти в учетную запись */
  useEffect(() => {
    if (isAuth) {
      const pageAlertIndex = pageAlerts?.findIndex(
        (alert) => alert.text === CONTENT.order.notAuthWarning,
      );

      if (pageAlertIndex !== -1) {
        dispatch(delAlert(pageAlertIndex));
      }
    }
  }, [pageAlerts, dispatch, isAuth]);

  /** Удаление алертов по таймауту */
  useEffect(() => {
    if (!pageAlerts?.length) return;
    pageAlerts.forEach((alert) => {
      setTimeout(() => {
        dispatch(delAlertByText(alert.text));
      }, alert.lifetime);
    });
  }, [dispatch, pageAlerts]);

  /** Инициализация корзины */
  useEffect(() => {
    if (!sessionStorage.order) {
      dispatch(changeOrder({ products: [], totalPrice: 0 }));
      return;
    }

    if (!products) return;

    const updatedOrder = JSON.parse(sessionStorage.order);
    const updatedProducts = updatedOrder.products;
    updatedProducts.forEach((item: ProductResponse) => {
      products.forEach((product) => {
        if (product.id === item.id) {
          item.price = product.price;
        }
        return false;
      });
    });

    const updatedTotalPrice = updatedProducts.reduce(
      (sum: number, current: ProductResponse) =>
        sum + current.price * current.quantity!,
      0,
    );

    dispatch(
      changeOrder({
        products: updatedProducts,
        totalPrice: updatedTotalPrice,
      }),
    );
  }, [products, dispatch]);

  return (
    <div className="page">
      <div className={styles.wrap}>
        {curPage === '/' ? <div className={styles.back} /> : ''}

        <Router>
          <Layout>
            <AppHeader
              func={getCurrentPage}
              homePage={curPage === '/'}
            />

            <PageAlerts />

            <main className={styles.main}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/products" element={<ProductsPage />} />
                <Route path="/orders" element={<Orders />} />
                <Route
                  path="/orders/:orderId/cb"
                  element={<Orders />}
                />
                <Route path="/profile" element={<Profile />} />
                <Route path="/order" element={<Order />} />
                <Route
                  path="/multi-login"
                  element={
                    <ProtectedRoute>
                      <MultiLogin />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/information"
                  element={<UserInformation />}
                />
                <Route
                  path="/product/:id"
                  element={<ProductCard />}
                />
                <Route
                  path={serverErrorRoute}
                  element={<ServerError500 />}
                />
                <Route path="*" element={<NotFound404 />} />
                <Route path="/card" element={<ProductCard />} />
              </Routes>
            </main>

            <AppFooter />
          </Layout>
        </Router>
      </div>

      {!!modal && <ModalCustomEdooffer />}
    </div>
  );
});

App.displayName = 'App';

export default App;
