import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store';
import { logOutUser } from 'store/auth.slice';
import { addAlert } from 'store/pageAlerts.slice';
import { createOrderUrl, getCreatedOrders } from 'urls';

import { IUpdFileInfo } from 'shared/models/file.model';
import { IExtendedOder } from 'shared/models/order.model';
import { IError } from 'shared/models/simple-response.model';
import httpClient from 'shared/utils/http-client/http-client';

type getDocsParams = {
  orgId: string;
  orderId: number;
};

interface IUseOrderDocuments {
  orderInfo: IExtendedOder[];
  updFileInfo?: IUpdFileInfo;
  invoiceFileInfo?: IUpdFileInfo;

  getOrderData: ({ orgId, orderId }: getDocsParams) => Promise<void>;
  getDocs: ({ orgId, orderId }: getDocsParams) => Promise<void>;
}

export const useOrderDocuments = (): IUseOrderDocuments => {
  const dispatch = useDispatch<AppDispatch>();

  const [orderInfo, setOrderInfo] = useState<IExtendedOder[]>([]);
  const [updFileInfo, setUpdFileInfo] = useState<IUpdFileInfo>();
  const [invoiceFileInfo, setInvoiceFileInfo] =
    useState<IUpdFileInfo>();

  const getOrderData = useCallback(
    async ({ orgId, orderId }: getDocsParams) => {
      try {
        const { data } = await httpClient.get(
          `${createOrderUrl(orgId)}/${orderId}`,
        );
        setOrderInfo((prevstate) => prevstate.concat(data));
      } catch (error) {
        const err = error as IError;

        if (err.response.status === 401) {
          return dispatch(logOutUser());
        }
        dispatch(
          addAlert({
            text: 'Ошибка получения списка заказов',
            variant: 'danger',
          }),
        );
      }
    },
    [dispatch],
  );

  const getDocs = useCallback(
    async ({ orgId, orderId }: getDocsParams) => {
      try {
        const { data } = await httpClient.get<IUpdFileInfo[]>(
          `${getCreatedOrders(orgId)}/${orderId}/docs`,
        );

        data.forEach((item: IUpdFileInfo) => {
          if (item.docType === 'UPD') {
            setUpdFileInfo({ ...item });
          }
          if (item.docType === 'INVOICE_ORDER') {
            setInvoiceFileInfo({ ...item });
          }
        });
      } catch (error) {
        dispatch(
          addAlert({
            text: 'Ошибка получения документов по заказу.',
            variant: 'danger',
          }),
        );
      }
    },
    [dispatch],
  );

  return {
    orderInfo,
    updFileInfo,
    invoiceFileInfo,
    getOrderData,
    getDocs,
  };
};
