import classNames from 'classnames/bind';
import { FC, ReactNode, memo } from 'react';

import styles from './dropdown-content.module.css';

const cx = classNames.bind(styles);

interface IDropdownContentProps {
  content: ReactNode;
  openFlag: boolean;
}

const DropdownContent: FC<IDropdownContentProps> = memo(
  ({ content, openFlag }) => (
    <div
      className={cx('content-wrapper', {
        'show-content': openFlag,
      })}
    >
      {content}
    </div>
  ),
);

DropdownContent.displayName = 'DropdownContent';

export default DropdownContent;
