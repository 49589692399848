import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { initialAuth } from 'shared/const/initial-state.const';
import { TGlobalState } from 'shared/models/global-state.model';
import { multiLoginStorage } from 'shared/utils/navigate-to-multi-login.utils';
import { tokens } from 'shared/utils/tokens.util';

const initialState: TGlobalState['auth'] = { ...initialAuth };

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    changeAuthFormType(state, action: PayloadAction<string>) {
      state.authFormType = action.payload;
    },
    changePasswordError(state, action: PayloadAction<string>) {
      state.passwordError = action.payload;
    },
    setSignUpStatus(state) {
      state.isSignUpSuccess = true;
    },
    setZeroOrganizations(state) {
      state.isZeroOrganizations = true;
    },
  },
});

export const logOutUser = () => {
  return () => {
    const url = new URL(window.location.href);

    if (url.searchParams.get('id')) {
      window.location.href = '/';
    }

    tokens.removeAll();
    multiLoginStorage.removeOrgId();
  };
};

export const {
  changeAuthFormType,
  changePasswordError,
  setSignUpStatus,
  setZeroOrganizations,
} = authSlice.actions;

export default authSlice.reducer;
