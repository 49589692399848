import classNames from 'classnames/bind';
import { FC, memo } from 'react';

import { FileProduct } from 'shared/models/global-state.model';

import docIcon from 'images/icons/doc.svg';
import downloadIcon from 'images/icons/download.svg';

import styles from './download-doc.module.css';

const cx = classNames.bind(styles);

type TDownloadDocProps = {
  document: FileProduct;
};

export const DownloadDoc: FC<TDownloadDocProps> = memo(
  ({ document }) => {
    return (
      <a
        href={document.fileUrl}
        className={cx('download-doc')}
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={docIcon}
          alt="Иконка документа"
          className={cx('icon-left')}
        />

        <span className={cx('text')}>{document.fileName}</span>

        <img
          src={downloadIcon}
          alt="Иконка документа"
          className={cx('icon-right')}
        />
      </a>
    );
  },
);

DownloadDoc.displayName = 'DownloadDoc';
