import cx from 'classnames';
import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';

import arrowIcon from 'images/icons/arrow.svg';
import resetIcon from 'images/icons/close.svg';

import styles from './order-select.module.css';

interface IOrderSelectProps {
  testId: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  placeholder: string;
  options: { value: number; text: string }[];
  onReset: () => void;
  disabled: boolean;
}

const OrderSelect: FC<IOrderSelectProps> = memo((props) => {
  const {
    placeholder,
    options,
    onChange,
    onReset,
    testId,
    disabled,
  } = props;
  const [defaultOption, setDefaultOption] = useState(true);
  const [value, setValue] = useState('');

  const reset = useCallback(() => {
    setDefaultOption(true);
    setValue('');
    if (onReset) {
      onReset();
    }
  }, [onReset]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      e.preventDefault();
      setDefaultOption(false);
      setValue(e.target.value);
      if (onChange) {
        onChange(e);
      }
    },
    [onChange],
  );

  const handleReset = useCallback(() => {
    reset();
  }, [reset]);

  useEffect(() => {
    reset();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles['order-select']}>
      <label className={styles.label}>{placeholder}</label>
      <select
        data-testid={`custom-select-${testId}`}
        size={1}
        onChange={handleChange}
        value={value}
        disabled={disabled !== undefined ? disabled : false}
        name="CustomSelect"
        className={cx(styles.select, styles['select-sort'], {
          [styles['option-default']]: defaultOption,
        })}
        required
      >
        <option value="" disabled hidden>
          {placeholder}
        </option>
        {options.map((optionObj) => (
          <option
            className={styles.option}
            key={optionObj.value}
            value={optionObj.value}
          >
            {optionObj.text}
          </option>
        ))}
      </select>
      <img
        src={arrowIcon}
        className={cx(styles['icon-arrow'], {
          [styles['show-arrow']]: defaultOption,
        })}
        alt="Развернуть список"
      />
      <button
        className={cx(styles['icon-reset'], {
          [styles['show-arrow']]: !defaultOption,
        })}
        type="button"
        onClick={handleReset}
      >
        <img src={resetIcon} alt="Сбросить данные поля" />
      </button>
    </div>
  );
});

OrderSelect.displayName = 'OrderSelect';

export default OrderSelect;
