import { FC, memo } from 'react';

import linkIcon from 'images/icons/link.svg';

import styles from './custom-btn.module.css';

interface CustomBtnProps {
  theme: string;
  type: 'button' | 'submit';
  text: string;
  disabled: boolean;
  onClick?: () => void;
  isLink: boolean;
  customCls?: string | null;
  testId: string;
  loading: boolean;
}

const CustomBtn: FC<CustomBtnProps> = memo(
  ({
    theme,
    type,
    text,
    disabled,
    onClick,
    isLink,
    customCls,
    testId,
    loading,
  }) => (
    <button
      data-testid={`custom-btn-${testId}`}
      onClick={onClick}
      disabled={disabled || loading}
      className={`${styles.button} ${loading && styles.loading} ${
        disabled && styles.disabled
      } ${
        styles[`${theme}`]
      } d-flex justify-content-center align-items-center ${customCls}`}
      type={type === 'button' ? 'button' : 'submit'}
    >
      {text}
      {isLink && (
        <img src={linkIcon} className={styles['link-icon']} alt="" />
      )}
      {loading && <div className={styles.progress} />}
    </button>
  ),
);

CustomBtn.displayName = 'CustomBtn';

export default CustomBtn;
