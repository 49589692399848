import { FC, memo, useMemo } from 'react';
import { getCreatedOrders } from 'urls';

import { Icon } from 'components/Icon';
import DownloadLink from 'components/download-link/download-link';

import { multiLoginStorage } from 'shared/utils/navigate-to-multi-login.utils';

interface DocumentStatusIconProps {
  orderId: number;
  updFileInfo?: {
    orderId: number;
    docType: string;
    docId: number;
    docFile: {
      docName: string;
      docBody: string;
    };
  };
}

export const DocumentStatusIcon: FC<DocumentStatusIconProps> = memo(
  ({ orderId, updFileInfo = null }) => {
    const orgId = multiLoginStorage.getOrgId();

    const statusIcon = useMemo(() => {
      return updFileInfo?.docId ? (
        <Icon name="downloadDoc" width="31" height="32" />
      ) : (
        <Icon name="doc" width="26" height="32" />
      );
    }, [updFileInfo?.docId]);

    return (
      <div
        onClick={(e) => e.stopPropagation()}
        role="button"
        tabIndex={0}
        aria-hidden="true"
      >
        <DownloadLink
          link={`${getCreatedOrders(orgId)}/${orderId}/doc/${
            updFileInfo?.docId
          }/attachment`}
          payload={{ responseType: 'blob' }}
          textLink="Скачать УПД"
          linkStyle="icon"
          fileName={updFileInfo?.docFile.docName}
          documentType="application/pdf"
          iconSrc={statusIcon}
          showedDownloadIcon={false}
          isDisabled={!updFileInfo?.docId}
        />
      </div>
    );
  },
);

DocumentStatusIcon.displayName = 'DocumentStatusIcon';
