import {
  PayloadAction,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { multiLoginUrl } from 'urls';

import { CONTENT, ONE_MINUTE } from 'shared/const';
import { initialMultiLogin } from 'shared/const/initial-state.const';
import { TGlobalState } from 'shared/models/global-state.model';
import { IError } from 'shared/models/simple-response.model';
import httpClient from 'shared/utils/http-client/http-client';
import { multiLoginStorage } from 'shared/utils/navigate-to-multi-login.utils';

import { logOutUser, setZeroOrganizations } from './auth.slice';
import { showLoader } from './loader.slice';
import { addAlert } from './pageAlerts.slice';

const initialState: TGlobalState['multiLogin'] = {
  ...initialMultiLogin,
};

const multiLoginSlice = createSlice({
  name: 'multiLogin',
  initialState,
  reducers: {
    setMultiLoginData(
      _,
      action: PayloadAction<TGlobalState['multiLogin']>,
    ) {
      return action.payload;
    },
  },
});

export const { setMultiLoginData } = multiLoginSlice.actions;

export default multiLoginSlice.reducer;

export const getOrganization = createAsyncThunk(
  'multiLogin/getOrganization',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(showLoader(true));

    try {
      const { data } = await httpClient.get<
        TGlobalState['multiLogin']
      >(multiLoginUrl);

      switch (data.logins.length) {
        case 0:
          dispatch(setZeroOrganizations());
          dispatch(
            addAlert({
              text: CONTENT.auth.login.zeroOrganizations,
              variant: 'warning',
              lifetime: ONE_MINUTE,
            }),
          );
          break;
        case 1:
          multiLoginStorage.setOrgId(data.logins[0].orgId);
          break;
        default:
          break;
      }

      dispatch(setMultiLoginData(data));
    } catch (error) {
      const err = error as IError;

      if (err.response.status === 500) {
        window.location.href = '/error500';
        return rejectWithValue('Server Error');
      }

      if (err.response.status === 401) {
        dispatch(logOutUser());
        return rejectWithValue('Unauthorized');
      }

      if (!!err.response.status) {
        dispatch(
          addAlert({
            text: 'Ошибка получения мультилогина',
            variant: 'warning',
          }),
        );
      }

      return rejectWithValue('Error fetching multiLogin');
    } finally {
      dispatch(showLoader(false));
    }
  },
);
