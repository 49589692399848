import classNames from 'classnames/bind';
import { FC, memo } from 'react';

import { IndicatorImage } from './indicator-image';
import styles from './indicator.module.scss';

const cx = classNames.bind(styles);

interface IIndicatorProps {
  full: boolean;
  remainder: number;
}

export const Indicator: FC<IIndicatorProps> = memo(
  ({ full, remainder }) => {
    let remainderStatus;

    switch (true) {
      case remainder === 1:
        remainderStatus = 'Много';
        break;

      case remainder === 2:
        remainderStatus = 'Достаточно';
        break;

      case remainder === 3:
        remainderStatus = 'Мало';
        break;

      case remainder === 4:
        remainderStatus = 'Недоступен';
        break;

      default:
        break;
    }

    return (
      <div className={`${styles.main} d-flex align-items-center`}>
        {full && <div className={cx('text')}>На складе: </div>}

        <div className="ms-2 d-flex align-items-baseline">
          {full && (
            <div
              className={cx({
                few: remainder === 3,
                enough: remainder === 2,
                much: remainder === 1,
              })}
            >
              {remainderStatus}
            </div>
          )}
          <div className="ms-2 d-flex align-items-baseline">
            <IndicatorImage remainder={remainder} />
          </div>
        </div>
      </div>
    );
  },
);

Indicator.displayName = 'Indicator';
