import React, { FC, memo } from 'react';

import { MultiLoginSubitem } from 'components/multi-login-subitem/multi-login-subitem';

import styles from './multi-login.module.css';

export const MultiLogin: FC = memo(() => {
  return (
    <div className={styles['main-container']}>
      <div>
        <h1 className={styles.header}>Выбор компании</h1>
        <MultiLoginSubitem isInMenu={false} />
      </div>
    </div>
  );
});

MultiLogin.displayName = 'MultiLogin';
