import { FC, memo } from 'react';

import image from 'images/500.svg';

import styles from './server-error-500.module.css';

export const ServerError500: FC = memo(() => (
  <div className={styles['server-error']}>
    <img className={`${styles.image} mt-5`} src={image} alt="" />
    <div className={styles.header}>Ошибка сервера</div>
    <p className={`${styles.text} mt-3 mb-5`}>
      Подождите, скоро данная ошибка будет исправлена.
    </p>
  </div>
));

ServerError500.displayName = 'ServerError500';
