import { AxiosResponse } from 'axios';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { IError } from '../models/simple-response.model';

interface IUseDownloadFileProps {
  refEl: MutableRefObject<HTMLAnchorElement | null>;
  apiDefinition: () => Promise<AxiosResponse<BlobPart>>;
  preDownloading: () => void;
  postDownloading: () => void;
  onError: () => void;
  getFileName: string | undefined;
  documentType: string;
}

const useDownloadFile = ({
  apiDefinition,
  preDownloading,
  postDownloading,
  onError,
  getFileName,
  documentType,
  refEl,
}: IUseDownloadFileProps) => {
  const [url, setFileUrl] = useState<string>();
  const [name, setFileName] = useState<string | undefined>();

  const download = useCallback(async () => {
    try {
      preDownloading();
      const { data } = await apiDefinition();
      const fileUrl = URL.createObjectURL(
        new Blob([data], { type: `application/${documentType}` }),
      );
      setFileUrl(fileUrl);
      setFileName(getFileName);
      postDownloading();
    } catch (error) {
      const err = error as IError;

      if (err?.response?.status === 404) {
        return onError();
      }
    }
  }, [
    apiDefinition,
    documentType,
    getFileName,
    onError,
    postDownloading,
    preDownloading,
  ]);

  useEffect(() => {
    if (!url) return;

    documentType === 'pdf'
      ? window.open(url, '_blank')
      : refEl?.current?.click();

    URL.revokeObjectURL(url);
  }, [url, refEl, documentType]);

  return { download, url, name };
};

export default useDownloadFile;
