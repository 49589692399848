import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { initialLoader } from 'shared/const/initial-state.const';
import { TGlobalState } from 'shared/models/global-state.model';

const initialState: TGlobalState['initLoader'] = { ...initialLoader };

const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    showLoader(state, action: PayloadAction<boolean>) {
      state.loader += action.payload ? 1 : -1;
    },
  },
});

export const { showLoader } = loaderSlice.actions;

export default loaderSlice.reducer;
