import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import * as urls from 'urls';

import {
  MIN_VALIDITY_SECONDS,
  keycloak,
} from 'shared/const/keycloak.const';
import { IHttpClientError } from 'shared/models/simple-response.model';
import { tokens } from 'shared/utils/tokens.util';

interface IOriginalRequest extends AxiosRequestConfig {
  retry?: boolean;
}

const httpClientError: IHttpClientError = {
  ...new Error('Не удалось обновить токен'),
  response: { status: null },
};

const createError = () => {
  tokens.removeAll();
  httpClientError.response.status = 401;
  throw httpClientError;
};

const openLoginForm = () => {
  tokens.removeAll();
  window.location.href = `/${urls.LOGIN_FORM_URL_HASH}`;
};

const refreshTokenFromKeycloak = async (
  originalRequest: AxiosRequestConfig,
) => {
  try {
    const isTokenRefreshed = await keycloak.updateToken(
      MIN_VALIDITY_SECONDS,
    );
    if (!isTokenRefreshed) return createError();

    keycloak.token && tokens.setAccess(keycloak.token);
    keycloak.refreshToken && tokens.setRefresh(keycloak.refreshToken);
    axios.defaults.headers.common.Authorization = `Bearer ${keycloak.token}`;
    return httpClient.request(originalRequest);
  } catch (err) {
    createError();
  }
};

const onResponse = (response: AxiosResponse) => {
  return response;
};

const onError = async (error: AxiosError) => {
  if (!error.response || error.response.status !== 401) {
    return Promise.reject(error);
  }

  const originalRequest = (error.config as IOriginalRequest) || {};

  if (!!originalRequest?.retry) {
    return openLoginForm();
  }

  originalRequest.retry = true;

  return refreshTokenFromKeycloak(originalRequest);
};

const httpClient = axios.create();

httpClient.interceptors.request.use((request) => {
  const accessToken = tokens.getAccess();
  request.headers.Authorization = accessToken
    ? `Bearer ${accessToken}`
    : '';
  return request;
});

httpClient.interceptors.response.use(onResponse, onError);

export default httpClient;
