import { FC, memo } from 'react';

import { Icon } from 'components/Icon';

interface IIndicatorImageProps {
  remainder: number;
}

export const IndicatorImage: FC<IIndicatorImageProps> = memo(
  ({ remainder }) => {
    switch (remainder.toString()) {
      case '1':
        return <Icon name="much" width="16" height="16" />;
      case '2':
        return <Icon name="enough" width="16" height="16" />;
      case '3':
        return <Icon name="few" width="16" height="16" />;

      default:
        return <Icon name="unavailable" width="16" height="16" />;
    }
  },
);

IndicatorImage.displayName = 'IndicatorImage';
