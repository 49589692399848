import { FC, memo } from 'react';

import load from 'images/icons/load.svg';

import styles from './generating-invoice.module.css';

export const GeneratingInvoice: FC = memo(() => {
  return (
    <div className={styles['container-info']}>
      <div className={styles['wrap']}>
        <img
          src={load}
          className={styles['animate-spin']}
          alt="load"
        />

        <p className={styles['check-create']}>Счет формируется</p>
      </div>

      <p className={styles['middle-time']}>
        Среднее время формирования счета:
        <span className={`${styles['middle-time']} ${styles.min}`}>
          {' '}
          5 мин.
        </span>
      </p>
    </div>
  );
});

GeneratingInvoice.displayName = 'GeneratingInvoice';
