import { FC, memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, getOrgState } from 'store';
import { clearDocs, getDocs } from 'store/docs.slice';
import { clearOrg, getOrg } from 'store/org.slice';

import ContentLoader from 'components/content-loader/content-loader';
import CustomCheckbox from 'components/custom-checkbox/custom-checkbox';
import CustomInput from 'components/custom-input/custom-input';
import Documents from 'components/documents/documents';

import { tokens } from 'shared/utils/tokens.util';

import styles from './profile.module.css';

export const Profile: FC = memo(() => {
  const { org } = useSelector(getOrgState);
  const docsRequest = useSelector(getOrgState);
  const { orgRequest } = useSelector(getOrgState);
  const isAuth = !!tokens.getAccess();
  const [isAddressEq, setAsAddressEq] = useState(false);
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (org) {
      if (org.legalAddressId || org.postalAddressId) {
        setAsAddressEq(org.legalAddressId === org.postalAddressId);
      } else {
        setAsAddressEq(org.legalAddress === org.postalAddress);
      }

      dispatch(getDocs());
    }
  }, [org, dispatch]);

  useEffect(() => {
    dispatch(clearDocs());
    dispatch(clearOrg());
    dispatch(getOrg());
  }, [dispatch]);

  return (
    <div className="profile mt-4">
      {isAuth && (
        <div className="container">
          {orgRequest && <ContentLoader placeholderType="profile" />}

          {!orgRequest && (
            <div>
              <h1 className={styles.header}>Профиль организации</h1>

              {Boolean(org?.longName) && (
                <div className="row">
                  <div className="col">
                    <CustomInput
                      testId="profile-input-1"
                      label="Полное название организации"
                      type="text"
                      theme="edo"
                      disabled
                      defaultValue={org?.longName}
                      margin="mt"
                    />
                  </div>
                </div>
              )}

              <div className="row">
                {Boolean(org?.shortName) && (
                  <div className="col-12">
                    <CustomInput
                      testId="profile-input-2"
                      label="Краткое название организации"
                      type="text"
                      theme="edo"
                      disabled
                      defaultValue={org?.shortName}
                      margin="mt"
                    />
                  </div>
                )}
              </div>

              <div className="row">
                {Boolean(org?.inn) && (
                  <div className="col-6 col-md-3">
                    <CustomInput
                      testId="profile-input-3"
                      label="ИНН"
                      type="text"
                      theme="edo"
                      disabled
                      defaultValue={org?.inn}
                      margin="mt"
                    />
                  </div>
                )}

                {Boolean(org?.ogrn) && (
                  <div className="col-6 col-md-3">
                    <CustomInput
                      testId="profile-input-4"
                      label="ОГРН"
                      type="text"
                      theme="edo"
                      disabled
                      defaultValue={org?.ogrn}
                      margin="mt"
                    />
                  </div>
                )}

                {Boolean(org?.kpp) && (
                  <div className="col-6 col-md-3">
                    <CustomInput
                      testId="profile-input-5"
                      label="КПП"
                      type="text"
                      theme="edo"
                      disabled
                      defaultValue={org?.kpp}
                      margin="mt"
                    />
                  </div>
                )}

                {Boolean(org?.partnerId) && (
                  <div className="col-6 col-md-3">
                    <CustomInput
                      testId="profile-input-6"
                      label="ID"
                      type="text"
                      theme="edo"
                      disabled
                      defaultValue={org?.partnerId}
                      margin="mt"
                    />
                  </div>
                )}
              </div>

              {Boolean(org?.legalAddress) && (
                <div className="row">
                  <div className="col">
                    <CustomInput
                      testId="profile-input-7"
                      label="Юридический адрес"
                      type="text"
                      theme="edo"
                      disabled
                      defaultValue={org?.legalAddress}
                      margin="mt"
                    />
                  </div>
                </div>
              )}

              {Boolean(org?.postalAddress) && (
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-8">
                    <CustomInput
                      testId="profile-input-8"
                      label="Почтовый адрес"
                      type="text"
                      theme="edo"
                      disabled
                      defaultValue={org?.postalAddress}
                      margin="mt"
                    />
                  </div>

                  <div className="col-12 col-md-6 col-lg-4">
                    <CustomCheckbox
                      label="Почтовый адрес совпадает с юридическим"
                      disabled
                      checked={isAddressEq}
                      margin="mt"
                    />
                  </div>
                </div>
              )}

              <div className={styles.separator} />

              <h1 className={styles.header}>Документы</h1>

              {Boolean(docsRequest) && <Documents />}
            </div>
          )}
        </div>
      )}

      {!isAuth && (
        <div className={styles['no-auth']}>Вы не авторизованы</div>
      )}
    </div>
  );
});

Profile.displayName = 'Profile';
