export const multiLoginStorage = {
  setOrgId(id: string) {
    localStorage.setItem('orgId', JSON.stringify(id));
  },

  getOrgId() {
    const item = localStorage.getItem('orgId');
    return item ? JSON.parse(item) : null;
  },

  removeOrgId() {
    localStorage.removeItem('orgId');
  },
};
