import { FC, memo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import mailIcon from 'images/icons/mail.svg';
import phoneIcon from 'images/icons/phone.svg';

import styles from './app-footer.module.css';

const AppFooter: FC = memo(() => (
  <footer className={styles.footer}>
    <Container fluid="md">
      <Row className="align-items-end">
        <Col xs={12} sm={3} lg={3} className="order-1">
          <div className={styles.copy}>
            &copy; OOO &laquo;ТД Платформа&raquo;
          </div>
          <ul className={styles.list}>
            <li>ИНН: 9704104605</li>
            <li>КПП: 770401001</li>
            <li>ОГРН: 1217700589908</li>
          </ul>
        </Col>
        <Col
          xs={12}
          sm={9}
          lg={3}
          className="mt-4 mt-lg-0 order-2  order-lg-2"
        >
          <ul
            className={`${styles.list} d-sm-flex flex-sm-column align-items-sm-end d-lg-block`}
          >
            <li>
              <Link
                to="/information"
                data-testid="footer-link-6"
                className={styles.copy}
              >
                Покупателям
              </Link>
            </li>
            <li>
              <Link
                to="/information#order"
                data-testid="footer-link-7"
              >
                Заказ
              </Link>
            </li>
            <li>
              <Link
                to="/information#delivery"
                data-testid="footer-link-8"
              >
                Доставка
              </Link>
            </li>
            <li>
              <Link
                to="/information#pickup"
                data-testid="footer-link-9"
              >
                Самовывоз
              </Link>
            </li>
          </ul>
        </Col>
        <Col
          xs={12}
          sm={2}
          lg={4}
          className="mt-4 mt-lg-0 order-3  order-lg-3"
        >
          <ul className={styles.list}>
            <li>
              <a
                data-testid="footer-link-2"
                href="https://cdn1.platformaofd.ru/lkfn-prod/docs/Политика_ООО_ТД_Платофрма_в_отношении_обработки_и_защиты_персональных_данных.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Политика обработки персональных данных
              </a>
            </li>
          </ul>
        </Col>
        <Col
          xs={12}
          sm={10}
          lg={2}
          className="mt-4 mt-sm-0 order-4 order-lg-4"
        >
          <div className={styles.contacts}>
            <div
              className={`${styles.phone} d-flex flex-row justify-content-sm-end justify-content-start`}
            >
              <img src={phoneIcon} alt="" />
              <a data-testid="footer-link-4" href="tel:+74955408955">
                +7 (495) 540 89 55
              </a>
            </div>
            <div
              className={`${styles.mail} d-flex flex-row justify-content-sm-end justify-content-start`}
            >
              <img src={mailIcon} alt="" />
              <a
                data-testid="footer-link-5"
                href="mailto:info@platformatd.ru"
              >
                info@platformatd.ru
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </footer>
));

AppFooter.displayName = 'AppFooter';

export default AppFooter;
