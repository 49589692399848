import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link,
  NavLink,
  NavLinkRenderProps,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import {
  AppDispatch,
  getMenu,
  getMultiLogin,
  getOrgState,
} from 'store';
import { showMenu, showMenuOnMobile } from 'store/menu.slice';
import { LOGIN_FORM_URL_HASH } from 'urls';

import { Icon } from 'components/Icon';
import AuthMenu from 'components/auth-menu/auth-menu';
import ContentLoader from 'components/content-loader/content-loader';
import ProfileMenu from 'components/profile-menu/profile-menu';
import { Registration } from 'components/registration/registration';

import { createMenuHrefs } from 'shared/const';
import { useAuthFormToggle } from 'shared/hooks/use-auth-form-toggle';
import { multiLoginStorage } from 'shared/utils/navigate-to-multi-login.utils';
import { tokens } from 'shared/utils/tokens.util';

import styles from './app-header.module.css';

interface AppHeaderProps {
  homePage: boolean;
  func: (path: string) => void;
}

const TIMEOUT = 2000;

const MENU_ITEMS = [
  {
    to: createMenuHrefs('/'),
    className: 'navbar-brand',
    content: <Icon name="logoIcon" width="149" height="48" />,
    isMain: true,
    isEnd: false,
  },

  {
    to: createMenuHrefs('/'),
    className: (nav: NavLinkRenderProps) =>
      `${styles['nav-link']}${
        nav.isActive ? ` ${styles.active}` : ''
      }`,
    content: 'Главная',
    isMain: false,
    isEnd: true,
  },

  {
    to: createMenuHrefs('/products'),
    className: (nav: NavLinkRenderProps) =>
      `${styles['nav-link']}${
        nav.isActive ? ` ${styles.active}` : ''
      }`,
    content: 'Товары',
    isMain: false,
    isEnd: false,
  },

  {
    to: createMenuHrefs('/orders'),
    className: (nav: NavLinkRenderProps) =>
      `${styles['nav-link']}${
        nav.isActive ? ` ${styles.active}` : ''
      }`,
    content: 'История заказов',
    isMain: false,
    isEnd: false,
  },

  {
    to: createMenuHrefs('/information'),
    className: (nav: NavLinkRenderProps) =>
      `${styles['nav-link']}${
        nav.isActive ? ` ${styles.active}` : ''
      }`,
    content: 'Покупателям',
    isMain: false,
    isEnd: false,
  },

  /* TODO - Эта страница появится после переезда главной страницы на Wordpress
  {
    to: 'https://platformatd.ru/baza-znaniy/',
    className: (nav: NavLinkRenderProps) =>
      `${styles['nav-link']}${
        nav.isActive ? ` ${styles.active}` : ''
      }`,
    content: 'База знаний',
    isMain: false,
    isEnd: false,
  },
  */
];

const AppHeader: FC<AppHeaderProps> = memo(({ homePage, func }) => {
  const [isMenuSkeletonVisible, setIsMenuSkeletonVisible] =
    useState<boolean>(true);
  const haveToken = !!tokens.getAccess();
  const ref = useRef<HTMLDivElement>(null);

  const { isShow, isShowOnMobile } = useSelector(getMenu);
  const { org } = useSelector(getOrgState);
  const dispatch: AppDispatch = useDispatch();

  const [isAuthMenu, setIsAuthMenu] = useState(false);
  const [isProfileMenu, setIsProfileMenu] = useState(false);

  const loc = useLocation();
  const authForm = useAuthFormToggle();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { logins } = useSelector(getMultiLogin);
  const orgIg = multiLoginStorage.getOrgId();

  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      const target = e.target as HTMLDivElement;
      const isSelectDropdown =
        target?.classList?.contains(
          'ant-select-item-option-content',
        ) ||
        (
          target.parentNode?.parentNode?.parentNode as HTMLElement
        )?.classList?.contains('ant-select-clear');

      const isInsideMenu =
        ref.current && ref.current.contains(target);

      if (!isInsideMenu && !isSelectDropdown) {
        dispatch(showMenu(false));
        dispatch(showMenuOnMobile(false));
        setIsProfileMenu(false);
        setIsAuthMenu(false);
        authForm.showSignIn();
      }
    },
    [authForm, dispatch],
  );

  const handleMenuToggle = useCallback(
    (e: React.MouseEvent) => {
      dispatch(showMenu(true));

      if (e.currentTarget.id === 'auth-btn' && !isAuthMenu) {
        setIsAuthMenu(true);
        setIsProfileMenu(false);
        return;
      }

      if (e.currentTarget.id === 'profile-btn' && !isProfileMenu) {
        setIsProfileMenu(true);
        setIsAuthMenu(false);
        return;
      }

      dispatch(showMenu(false));
      dispatch(showMenuOnMobile(false));
      setIsProfileMenu(false);
      setIsAuthMenu(false);
      authForm.showSignIn();
    },
    [authForm, dispatch, isAuthMenu, isProfileMenu],
  );

  /** Отключение анимации загрузки */
  useEffect(() => {
    if (
      !haveToken ||
      org?.shortName ||
      org?.longName ||
      (logins.length === 0 && org === null)
    ) {
      setTimeout(() => setIsMenuSkeletonVisible(false), TIMEOUT);
    }
    return;
  }, [org, haveToken, logins.length]);

  useEffect(() => {
    func(loc.pathname);
  }, [func, loc.pathname]);

  useEffect(() => {
    dispatch(showMenu(loc.hash.startsWith(LOGIN_FORM_URL_HASH)));
  }, [dispatch, loc.hash]);

  useEffect(
    () => {
      document.addEventListener('mousedown', (e) =>
        handleClickOutside(e),
      );
      return () => {
        document.removeEventListener('mousedown', (e) =>
          handleClickOutside(e),
        );
      };
    },
    // eslint-disable-next-line
    [],
  );

  useEffect(() => {
    const orgExists = logins.some((login) => login.orgId === orgIg);

    if (haveToken && (!orgIg || !orgExists) && logins.length > 1) {
      navigate('/multi-login');
    }
  }, [haveToken, navigate, orgIg, logins]);

  /** Выход со страници 500 */
  useEffect(() => {
    if (window.location.href.includes('error500') && !haveToken) {
      window.location.href = '/';
    }
  }, [haveToken]);

  return (
    <header
      className={`${styles['app-header']} ${
        !homePage ? styles.back : ''
      }`}
    >
      <Navbar expand="lg">
        <Container fluid="md" className="wrapper">
          {/** Анимация загрузки */}
          {isMenuSkeletonVisible && (
            <ContentLoader placeholderType="header" />
          )}

          {!isMenuSkeletonVisible && (
            <>
              {/** Логотип */}
              <Link
                className={MENU_ITEMS[0].className as string}
                to={MENU_ITEMS[0].to}
              >
                {MENU_ITEMS[0].content}
              </Link>

              {/** Ссылки */}
              {pathname !== '/multi-login' && (
                <Nav
                  className="m-auto my-2 my-lg-0 d-none d-xl-block"
                  style={{ maxHeight: '160px' }}
                  navbarScroll
                >
                  {MENU_ITEMS.filter(
                    (menuItem) => !menuItem.isMain,
                  ).map((menuItem) => (
                    <NavLink
                      key={menuItem.to}
                      className={menuItem.className}
                      end={menuItem.isEnd}
                      to={menuItem.to}
                    >
                      {menuItem.content}
                    </NavLink>
                  ))}
                </Nav>
              )}

              {/** Авторизация */}
              <div className={styles['profile-block']} ref={ref}>
                {haveToken && (
                  <>
                    <AuthMenu
                      logo={
                        <Icon name="newUser" width="16" height="16" />
                      }
                      toggleMenu={handleMenuToggle}
                    />

                    {isShow && (
                      <div>
                        <ProfileMenu toggleMenu={handleMenuToggle} />
                      </div>
                    )}
                  </>
                )}

                {!haveToken && (
                  <>
                    <AuthMenu
                      logo={
                        <Icon
                          name="authLogo"
                          width="16"
                          height="16"
                        />
                      }
                      toggleMenu={handleMenuToggle}
                    />

                    {(isShow ||
                      window.location.hash ===
                        LOGIN_FORM_URL_HASH) && (
                      <>
                        <div className="d-none d-lg-block">
                          <Registration
                            toggleMenu={handleMenuToggle}
                          />
                        </div>

                        <div className="d-lg-none">
                          {isShowOnMobile ||
                          (isAuthMenu && !isProfileMenu) ? (
                            <Registration
                              toggleMenu={handleMenuToggle}
                            />
                          ) : (
                            <ProfileMenu
                              toggleMenu={handleMenuToggle}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </Container>
      </Navbar>
    </header>
  );
});

AppHeader.displayName = 'AppHeader';

export default AppHeader;
