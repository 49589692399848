import {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setSuccessOrder } from 'store/successOrder.slice';

import { Icon } from 'components/Icon';
import HistoryOrdersSubItem from 'components/history-orders-subitem/history-orders-subitem';
import ToolTip from 'components/tooltip/tooltip';

import { useOrderDocuments } from 'shared/hooks/use-order-documents.hook';
import { multiLoginStorage } from 'shared/utils/navigate-to-multi-login.utils';

import { DeliveryStatusIcon } from './components/delivery-status-icon';
import { DocumentStatusIcon } from './components/document-status-icon';
import { FnListIcon } from './components/fn-list-icon';
import { PayStatusIcon } from './components/pay-status-icon';
import { PickupStatusIcon } from './components/pickup-status-icon';
import styles from './history-orders-item.module.css';

export interface IHistoryOrdersItemProps {
  orderId: number;
  num: number;
  date: string;
  author: string;
  delivery: string;
  sum: string;
  statusName: string;
  payStatusName: string;
  deliveryStatusName: string;
  pickupStatusName?: string;
  signatureStatusName?: string;
  isNewOrder?: boolean;
  contactName: string;
  contactPhone: string;
  deliveryAddress: string;
  trackNumber: string;
  storageId: string;
  payRemain: number;
  price: number;
  adressDesc: string;
  documentStatusName: string;
  deliveryPrice: number;
  vendorName: string;
}

const HistoryOrdersItem: FC<IHistoryOrdersItemProps> = memo(
  ({
    orderId,
    num,
    date,
    author,
    delivery,
    sum,
    statusName,
    payStatusName,
    deliveryStatusName,
    isNewOrder,
    contactName,
    contactPhone,
    deliveryAddress,
    trackNumber,
    storageId,
    payRemain,
    price,
    adressDesc,
    documentStatusName,
    deliveryPrice,
    vendorName,
  }) => {
    const [openFlag, setOpenFlag] = useState(false);

    const dispatch = useDispatch();
    const orgId = multiLoginStorage.getOrgId();
    const orderDocuments = useOrderDocuments();

    const toggleFlag = useCallback(() => {
      setOpenFlag(!openFlag);
    }, [openFlag]);

    const payStatus = useMemo(() => {
      return payStatusName === 'Готов к выдаче'
        ? statusName
        : payStatusName;
    }, [payStatusName, statusName]);

    const sumQuantity = useMemo(() => {
      return orderDocuments.orderInfo.reduce(
        (acc, item) => acc + item.quantity,
        0,
      );
    }, [orderDocuments.orderInfo]);

    useEffect(() => {
      if (!orderId || !orgId) return;

      (async () => {
        await orderDocuments.getOrderData({ orgId, orderId });
        await orderDocuments.getDocs({ orgId, orderId });
      })();
      // eslint-disable-next-line
    }, [
      orderDocuments.getDocs,
      orderDocuments.getOrderData,
      orderId,
      orgId,
    ]);

    useEffect(() => {
      if (isNewOrder && !openFlag) {
        dispatch(setSuccessOrder(false));
        setOpenFlag(!openFlag);
      }
    }, [dispatch, isNewOrder, openFlag]);

    return (
      <div className={styles['orders-item']}>
        <div
          data-testid="his-order-click-1"
          onClick={toggleFlag}
          className={`${styles.main} ${
            openFlag ? styles.active : ''
          }`}
          role="button"
          tabIndex={0}
          aria-hidden="true"
        >
          <div className={styles.top}>
            <Row>
              <Col xs={8} md={1} lg={1} className="d-flex">
                <div className="number d-flex align-items-center">
                  {num}
                </div>
              </Col>

              <Col md={2} lg={2} className="d-none d-md-flex">
                <div
                  className={`${styles.date} align-items-center d-flex`}
                >
                  {date}
                </div>
              </Col>

              <Col lg={3} className="d-none d-lg-flex">
                <div
                  className={`${styles.author} align-items-center d-flex`}
                >
                  {author}
                </div>
              </Col>

              <Col md={1} lg={1} className="d-none d-md-flex">
                <div
                  className={`${styles.delivery} align-items-center d-flex mx-auto`}
                >
                  {delivery}
                </div>
              </Col>

              <Col md={1} lg={1} className="d-none d-md-flex">
                <div
                  className={`${styles.delivery} align-items-center d-flex mx-auto`}
                >
                  {sumQuantity}
                  <div className="align-items-center d-flex ms-1">
                    <Icon name="orangeBox" width="16" height="16" />
                  </div>
                </div>
              </Col>

              <Col
                xs={4}
                md={1}
                lg={1}
                className="d-flex justify-content-end"
              >
                <div
                  className={`${styles.sum} d-flex align-items-center`}
                >
                  {sum}
                </div>
              </Col>

              <Col
                xs={12}
                md={6}
                lg={3}
                className={`${styles.border} d-flex pt-3 pt-md-0 mt-3 mt-md-0 justify-content-around justify-content-md-between align-items-center`}
              >
                <div className={styles['pay-status']}>
                  <ToolTip text={payStatus}>
                    <PayStatusIcon
                      payStatusName={payStatus}
                      orderId={orderId}
                      invoiceFileInfo={orderDocuments.invoiceFileInfo}
                    />
                  </ToolTip>
                </div>

                <div className={styles['delivery-status']}>
                  <ToolTip text={deliveryStatusName ?? 'Доставка'}>
                    {delivery === 'Доставка' ? (
                      <DeliveryStatusIcon
                        deliveryStatusName={deliveryStatusName}
                      />
                    ) : (
                      <PickupStatusIcon
                        deliveryStatusName={deliveryStatusName}
                      />
                    )}
                  </ToolTip>
                </div>

                <div className={styles['signature-status']}>
                  <ToolTip text="Закрывающие документы">
                    <DocumentStatusIcon
                      orderId={orderId}
                      updFileInfo={orderDocuments.updFileInfo}
                    />
                  </ToolTip>
                </div>

                <div className={styles['fn-list']}>
                  <ToolTip text="Список ФН в заказе">
                    <FnListIcon
                      orderId={orderId}
                      deliveryStatusName={deliveryStatusName}
                    />
                  </ToolTip>
                </div>

                <div
                  className={`${styles.btn} ${
                    openFlag ? styles.open : ''
                  }`}
                >
                  <Icon name="arrowDown" width="10" height="7" />
                </div>
              </Col>
            </Row>
          </div>
        </div>

        {(openFlag || isNewOrder) && (
          <HistoryOrdersSubItem
            contactName={contactName}
            contactPhone={contactPhone}
            deliveryAddress={deliveryAddress}
            trackNumber={trackNumber}
            storageId={storageId}
            deliveryStatus={delivery}
            orderId={orderId}
            deliveryStatusName={deliveryStatusName}
            statusName={statusName}
            payStatusName={payStatusName}
            payRemain={payRemain}
            price={price}
            adressDesc={adressDesc}
            documentStatusName={documentStatusName}
            deliveryPrice={deliveryPrice}
            vendorName={vendorName}
          />
        )}
      </div>
    );
  },
);

HistoryOrdersItem.displayName = 'HistoryOrdersItem';

export default HistoryOrdersItem;
