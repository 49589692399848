import { FC, memo } from 'react';

import { IIconProps, IconType } from 'shared/models/Icon.model';

import { useIcons } from './hooks';

export const Icon: FC<IIconProps> = memo((props) => {
  const { name } = props;
  const icons: IconType = useIcons(props);
  return icons[name];
});

Icon.displayName = 'Icon';
