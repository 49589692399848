import React, { FC, memo } from 'react';

import { Icon } from 'components/Icon';

import { ProductResponse } from 'shared/models/global-state.model';

import styles from './products-card.module.css';

const EXCEPTIONS = [7505, 7506, 7355, 7356, 7273, 7274];

interface IHonestSignProps {
  product: ProductResponse;
}

export const HonestSign: FC<IHonestSignProps> = memo(
  ({ product }) => {
    if (!product || EXCEPTIONS.includes(product.id)) {
      return null;
    }

    return (
      <div className={styles['honest-sign']}>
        <Icon name="honestSign" width="101" height="65" />
      </div>
    );
  },
);

HonestSign.displayName = 'HonestSign';
