import React, { FC, memo, useCallback, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getDeliveryState, getMultiLogin, getOrder } from 'store';
import { showLoader } from 'store/loader.slice';
import { addAlert } from 'store/pageAlerts.slice';
import { currency } from 'utils';

import CustomBtn from 'components/custom-btn/custom-btn';
import OrderDelivery from 'components/order-delivery/order-delivery';
import OrderItems from 'components/order-items/order-items';

import { CONTENT, ONE_MINUTE } from 'shared/const';
import { useAuth } from 'shared/hooks/use-auth';
import { tokens } from 'shared/utils/tokens.util';

import styles from './order.module.css';

export const Order: FC = memo(() => {
  const { order } = useSelector(getOrder);
  const { isTariffLoading } = useSelector(getDeliveryState);

  const [orderLoaderFlag, setOrderLoaderFlag] = useState(false);
  const [startOrder, setStartOrder] = useState(false);
  const isAuth = tokens.getAccess();
  const { deliveryData } = useSelector(getDeliveryState);
  const auth = useAuth();
  const { logins } = useSelector(getMultiLogin);
  const dispatch = useDispatch();

  const startOrderOn = useCallback(() => {
    window.scrollTo(0, 0);

    if (!isAuth) {
      return auth?.logIn();
    }

    if (logins.length === 0) {
      return dispatch(
        addAlert({
          text: CONTENT.auth.login.zeroOrganizations,
          variant: 'warning',
          lifetime: ONE_MINUTE,
        }),
      );
    }

    dispatch(showLoader(true));
    setOrderLoaderFlag(true);
    setStartOrder(true);
  }, [dispatch, isAuth, auth, logins.length]);

  const startOrderOff = useCallback(() => {
    setStartOrder(false);
    window.scrollTo(0, 0);
    dispatch(showLoader(false));
  }, [dispatch]);

  return (
    <div className="order mt-4 mb-4">
      <Container>
        <div className="main">
          <div className="d-md-flex">
            <OrderItems aside={false} />

            <OrderDelivery
              aside={false}
              startOrder={startOrder}
              onOrderLoaderFlagToggle={setOrderLoaderFlag}
              startOrderOff={startOrderOff}
            />
          </div>

          <div
            className={`${styles.block} mt-3 d-flex justify-content-end`}
          >
            <div>
              <div
                className={`${styles['items-price']} mt-3 d-flex justify-content-end align-items-baseline`}
              >
                <span>Сумма заказа:</span>

                <span className={`${styles.price} ms-3`}>
                  {Boolean(order?.totalPrice) && (
                    <span className={`${styles.price} ms-3`}>
                      {`${currency(
                        order.totalPrice + deliveryData.deliveryPrice,
                      )} ₽`}
                    </span>
                  )}
                </span>
              </div>

              <div className="mt-4">
                <CustomBtn
                  theme="edo"
                  type="button"
                  text={isAuth ? 'Заказать' : 'Авторизуйтесь'}
                  onClick={startOrderOn}
                  testId="order-btn-1"
                  loading={orderLoaderFlag || isTariffLoading}
                  isLink={false}
                  customCls={null}
                  disabled={false}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
});

Order.displayName = 'Order';
