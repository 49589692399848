import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { initialOrder } from 'shared/const/initial-state.const';
import { TGlobalState } from 'shared/models/global-state.model';

const initialState: TGlobalState['initOrder'] = { ...initialOrder };

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    changeOrder(
      state,
      action: PayloadAction<TGlobalState['initOrder']['order']>,
    ) {
      sessionStorage.setItem('order', JSON.stringify(action.payload));
      state.order = action.payload;
    },
  },
});

export const { changeOrder } = orderSlice.actions;
export default orderSlice.reducer;
