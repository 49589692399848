import { FC, memo, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { useAuth } from 'shared/hooks/use-auth';

type TLayoutProps = { children: React.ReactNode };

export const Layout: FC<TLayoutProps> = memo((props) => {
  const { children } = props;

  const location = useLocation();
  const auth = useAuth();

  const isLastPage = useMemo(
    () => {
      const currentPathname = window.location.pathname;
      const lastPathname = localStorage.getItem(
        'lastUpdatedLoginPage',
      );
      return currentPathname === lastPathname;
    },
    /* eslint-disable-next-line */
    [location],
  );

  const isMultiLoginUrl = useMemo(
    () => {
      return window.location.pathname === '/multi-login';
    },
    /* eslint-disable-next-line */
    [location],
  );

  /** Инициализация SSO  */
  useEffect(
    () => {
      (async () => await auth?.init())();
    },
    /* eslint-disable-next-line */
    [auth?.init],
  );

  useEffect(
    () => {
      if (!auth?.isAuthenticated) return;
      if (isMultiLoginUrl) return;
      if (isLastPage) return;
      auth?.logIn({ prompt: 'none' });
    },
    /* eslint-disable-next-line */
    [isLastPage, isMultiLoginUrl, auth?.isAuthenticated],
  );

  if (auth?.isAuthenticated && !isLastPage && !isMultiLoginUrl)
    return null;

  return <>{children}</>;
});

Layout.displayName = 'Layout';
