import React, { useCallback, useMemo, useState } from 'react';

import { useValidation } from 'shared/hooks/use-validation';

import { ERROR_MESSAGES } from '../conts';

export const usePhoneInput = () => {
  const [value, setValue] = useState('');
  const [error, setError] = useState('');
  const { validatePhone } = useValidation();

  const maskOptions = useMemo(
    () => ({
      showMask: true,
      mask: '+7 (___) ___-__-__',
      replacement: { _: /\d/ },
    }),
    [],
  );

  const cleanPhoneNumber = useMemo(() => {
    return value.replace(/\s|\+|-|\(|\)/g, '');
  }, [value]);

  const resetError = useCallback(() => setError(''), []);

  const checkValidation = useCallback(() => {
    const isValid = validatePhone(String(value));
    setError(() => (isValid ? '' : ERROR_MESSAGES.phone));
    return isValid;
  }, [validatePhone, value]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
    },
    [],
  );

  return {
    value,
    cleanPhoneNumber,
    maskOptions,
    error,
    resetError,
    handleChange,
    checkValidation,
  };
};
