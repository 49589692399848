import { FC, ReactNode, memo } from 'react';
import ReactDOM from 'react-dom';

import ModalHeader from 'components/modal-header/modal-header';
import Overlay from 'components/overlay/overlay';

import styles from './modal.module.css';

const modalRoot = document.getElementById('modals') as HTMLElement;

interface IModalProps {
  children: ReactNode;
  onClose: () => void;
  headerText: string;
}

const Modal: FC<IModalProps> = memo(
  ({ children, onClose, headerText }) =>
    ReactDOM.createPortal(
      <>
        <div className={styles.modal}>
          <ModalHeader onClose={onClose} text={headerText} />
          <div className={styles.content}>{children}</div>
        </div>
        <Overlay onClose={onClose} />
      </>,
      modalRoot,
    ),
);

Modal.displayName = 'Modal';

export default Modal;
