import { FC, memo } from 'react';

import CustomInput from 'components/custom-input/custom-input';
import List from 'components/list/list';

import { useInputSearch } from './input-search.hook';
import { InputSearchProps } from './input-search.model';
import styles from './input-search.module.css';

const InputSearch: FC<InputSearchProps> = memo(({ onOrgSelect }) => {
  const {
    state,
    orgsAsList,
    isListVisible,
    isAdditionalDataVisible,
    handleInputChange,
    handleSelect,
  } = useInputSearch({ onOrgSelect });

  return (
    <div className={styles['input-search']}>
      <CustomInput
        placeholder="ИНН или ОГРН"
        label="Поиск организации по ИНН"
        type="text"
        theme="edo"
        margin="mt"
        value={state.value}
        isLoading={state.isLoading}
        onFocus={() => null}
        onChange={() => null}
        onInput={handleInputChange}
        onBlur={() => null}
      />

      {isAdditionalDataVisible && (
        <div className={styles['additional-data']}>
          <div>ИНН: {state.selectedOrg?.data.inn}</div>

          {state.selectedOrg?.data?.kpp && (
            <div>КПП: {state.selectedOrg?.data.kpp}</div>
          )}
        </div>
      )}

      <div className={styles.errors}>{state.error}</div>

      {isListVisible && (
        <div className={`mt-2 ${styles.list}`}>
          <List items={orgsAsList} onSelect={handleSelect} />
        </div>
      )}
    </div>
  );
});

InputSearch.displayName = 'InputSearch';

export default InputSearch;
