import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { initialMenu } from 'shared/const/initial-state.const';
import { TGlobalState } from 'shared/models/global-state.model';

const initialState: TGlobalState['menu'] = { ...initialMenu };

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    showMenu: (state, action: PayloadAction<boolean>) => {
      state.isShow = action.payload;
    },
    showMenuOnMobile: (state, action: PayloadAction<boolean>) => {
      state.isShowOnMobile = action.payload;
    },
  },
});

export const { showMenu, showMenuOnMobile } = menuSlice.actions;

export default menuSlice.reducer;
