import { FC, memo } from 'react';

import { Icon } from 'components/Icon';

import styles from '../history-orders-item.module.css';

interface PickupStatusIconProps {
  deliveryStatusName: string;
}

export const PickupStatusIcon: FC<PickupStatusIconProps> = memo(
  ({ deliveryStatusName = null }) => {
    let statusIcon;

    switch (deliveryStatusName) {
      case 'Сборка заказа':
        statusIcon = (
          <Icon name="awaitingShipment" width="32" height="32" />
        );
        break;

      case 'Готов к выдаче':
        statusIcon = (
          <Icon name="readyToPickup" width="32" height="32" />
        );
        break;

      case 'Отгружен':
        statusIcon = (
          <Icon name="deliveredPickup" width="32" height="32" />
        );
        break;

      default:
        statusIcon = (
          <Icon name="bagDisabled" width="32" height="32" />
        );
        break;
    }

    return <div className={styles.icon}>{statusIcon}</div>;
  },
);

PickupStatusIcon.displayName = 'PickupStatusIcon';
