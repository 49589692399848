import classNames from 'classnames/bind';
import { FC, ReactNode, memo } from 'react';

import NumberedItem from 'components/numbered-item/numbered-item';

import styles from './numbered-list.module.css';

const cx = classNames.bind(styles);

interface INumberedListProps {
  data: ReactNode[];
}

const NumberedList: FC<INumberedListProps> = memo(({ data }) => (
  <ul className={cx('list')}>
    {data.map((item, index) => (
      <NumberedItem content={item} key={index} number={index + 1} />
    ))}
  </ul>
));

NumberedList.displayName = 'NumberedList';

export default NumberedList;
