import { ChangeEvent, useCallback, useState } from 'react';

import { TGlobalState } from '../models/global-state.model';

export const useSearch = () => {
  const [searchOrg, setSearchOrg] = useState('');

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchOrg(e.target.value);
    },
    [],
  );

  const findName = useCallback(
    (name: string | null) => {
      return name
        ?.toLowerCase()
        .includes(searchOrg.toLowerCase().trim());
    },
    [searchOrg],
  );

  const foundOrganizations = useCallback(
    (orgs: TGlobalState['multiLogin']['logins']) => {
      return searchOrg
        ? orgs.filter(({ orgNameCustom, orgName }) => {
            return findName(orgNameCustom) || findName(orgName);
          })
        : orgs;
    },
    [searchOrg, findName],
  );

  return {
    searchOrg,
    handleChange,
    foundOrganizations,
  };
};
