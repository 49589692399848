import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth } from 'store';
import { changeAuthFormType } from 'store/auth.slice';

import { AUTH_FORM_TYPES } from 'shared/const';

export const useAuthFormToggle = () => {
  const { authFormType } = useSelector(getAuth);

  const dispatch = useDispatch();

  const isCurrentAuthFormSingIn = useMemo(
    () => authFormType === AUTH_FORM_TYPES.SIGN_IN,
    [authFormType],
  );

  const isCurrentAuthFormSingUp = useMemo(
    () => authFormType === AUTH_FORM_TYPES.SIGN_UP,
    [authFormType],
  );

  const showSignIn = useCallback(
    () => dispatch(changeAuthFormType(AUTH_FORM_TYPES.SIGN_IN)),
    [dispatch],
  );

  const showSignUp = useCallback(
    () => dispatch(changeAuthFormType(AUTH_FORM_TYPES.SIGN_UP)),
    [dispatch],
  );

  const toggleAuthForm = useCallback(() => {
    authFormType === AUTH_FORM_TYPES.SIGN_IN
      ? showSignUp()
      : showSignIn();
  }, [authFormType, showSignUp, showSignIn]);

  return {
    authFormType,
    isCurrentAuthFormSingIn,
    isCurrentAuthFormSingUp,
    showSignIn,
    showSignUp,
    toggleAuthForm,
  };
};
