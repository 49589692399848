import React, { useCallback, useMemo, useState } from 'react';
import { getCreatedOrders } from 'urls';

import { PAGINATION_STEP } from 'shared/const/orders-pagination.const';
import { multiLoginStorage } from 'shared/utils/navigate-to-multi-login.utils';

export interface IUseOrdersPagination {
  nextPaginationStep: number;
  setNextPaginationStep: React.Dispatch<React.SetStateAction<number>>;

  paginationUrl: string;
  createPaginationUrl: (offset: number) => string;

  isLoadMoreDisabled: boolean;
  setIsLoadMoreDisabled: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

export const useOrdersPagination = (): IUseOrdersPagination => {
  const [nextPaginationStep, setNextPaginationStep] = useState(0);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState(false);

  const orgId = multiLoginStorage.getOrgId();

  const paginationUrl = useMemo(() => {
    return (
      orgId &&
      `${getCreatedOrders(
        orgId,
      )}?rowsCount=${PAGINATION_STEP}&rowsOffset=${nextPaginationStep}`
    );
  }, [nextPaginationStep, orgId]);

  const createPaginationUrl = useCallback(
    (offset: number) => {
      return (
        orgId &&
        `${getCreatedOrders(
          orgId,
        )}?rowsCount=${PAGINATION_STEP}&rowsOffset=${offset}`
      );
    },
    [orgId],
  );

  return {
    nextPaginationStep,
    setNextPaginationStep,
    paginationUrl,
    isLoadMoreDisabled,
    setIsLoadMoreDisabled,
    createPaginationUrl,
  };
};
