export const HOSTS = {
  lk: {
    dev: {
      inner: 'lkfn-front.dev.kube.pofd.ru',
      external: 'lk-test.platformatd.ru',
    },
    prod: {
      inner: 'lkfn-front.prod.kube.pofd.ru',
      external: 'lk.platformatd.ru',
    },
  },

  wp: {
    dev: {},
    prod: {
      external: 'platformatd.ru',
    },
  },

  local: {
    dev: {
      inner: 'localhost:3000',
    },
    prod: {},
  },
};

export const DEV_HOSTS = [
  ...Object.values(HOSTS.wp.dev),
  ...Object.values(HOSTS.lk.dev),
  ...Object.values(HOSTS.local.dev),
];

export const PROD_HOSTS = [
  ...Object.values(HOSTS.wp.prod),
  ...Object.values(HOSTS.lk.prod),
  ...Object.values(HOSTS.local.prod),
];

export const isProd = PROD_HOSTS.some(
  (host) => host === window.location.host,
);
