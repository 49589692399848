type TDefaultError = Error;

type TUseHTTPParams = {
  url: string;
  onError?: (error: unknown) => void;
};

export const getHtmlData = async <E = TDefaultError>({
  url,
  onError,
}: TUseHTTPParams) => {
  try {
    if (!url) return;
    const data = await fetch(url);
    return await data.text();
  } catch (error) {
    const err = error as E;
    onError && onError(err);
  }
};
