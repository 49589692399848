import { FC, memo } from 'react';

import ProgressInfoBlock from 'components/progress-info-block/progress-info-block';

import awaitingShipmentIcon from 'images/icons/awaiting-shipment.svg';
import pickupDisabledIcon from 'images/icons/bag-disabled.svg';
import shippedIcon from 'images/icons/delivered-pickup.svg';
import readyToPickupIcon from 'images/icons/ready-to-pickup.svg';

interface IPickupStatusIconProps {
  deliveryStatusName: string;
}

export const PickupStatusIcon: FC<IPickupStatusIconProps> = memo(
  ({ deliveryStatusName }) => {
    switch (deliveryStatusName) {
      case 'Сборка заказа':
        return (
          <ProgressInfoBlock
            icon={awaitingShipmentIcon}
            text={`Самовывоз${deliveryStatusName}`}
            theme="warn"
          />
        );

      case 'Готов к выдаче':
        return (
          <ProgressInfoBlock
            icon={readyToPickupIcon}
            text={`Самовывоз ${deliveryStatusName}`}
            theme="success"
          />
        );

      case 'Отгружен':
        return (
          <ProgressInfoBlock
            icon={shippedIcon}
            text={deliveryStatusName}
            theme="success"
          />
        );

      default:
        return (
          <ProgressInfoBlock
            icon={pickupDisabledIcon}
            text="Самовывоз"
            theme="disabled"
          />
        );
    }
  },
);

PickupStatusIcon.displayName = 'PickupStatusIcon';
