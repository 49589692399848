import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { getOrder } from 'store';

import { Icon } from 'components/Icon';
import AuthMenu from 'components/auth-menu/auth-menu';
import { MultiLoginSubitem } from 'components/multi-login-subitem/multi-login-subitem';

import { tokens } from 'shared/utils/tokens.util';

import styles from './profile-menu.module.css';

interface IMenuOrganizationProps {
  switchToMainMenu: () => void;
  toggleMenu: (e: React.MouseEvent) => void;
}

export const MenuOrganization: FC<IMenuOrganizationProps> = memo(
  ({ switchToMainMenu, toggleMenu }) => {
    const isAuth = tokens.getAccess();
    const { order } = useSelector(getOrder);

    return (
      <>
        <div className={styles['head-organization']}>
          <AuthMenu
            logo={
              isAuth ? (
                <Icon name="newUser" width="16" height="16" />
              ) : (
                <Icon name="authLogo" width="16" height="16" />
              )
            }
            toggleMenu={toggleMenu}
          />
        </div>

        <div className={styles['menu-organization']}>
          {!!order?.products.length && (
            <div className="orders">
              <div>
                <NavLink
                  data-testid="profile-menu-navlink-1"
                  className={`${styles['menu-item']} ${styles.border}`}
                  to="/order"
                >
                  <div className={styles['menu-icon']}>
                    <Icon name="orangeBox" width="16" height="16" />
                  </div>
                  <p>Заказ</p>
                  <div className={styles.counter}>
                    {order.products.reduce((sum, current) => {
                      return sum + current.quantity!;
                    }, 0)}
                  </div>
                </NavLink>
              </div>
            </div>
          )}

          <div
            className={styles['menu-item']}
            onClick={switchToMainMenu}
            role="button"
            aria-hidden="true"
          >
            <div className={styles['menu-icon']}>
              <Icon name="arrowLeft" width="16" height="16" />
            </div>
            <p>Назад</p>
          </div>

          <MultiLoginSubitem
            toggleMenu={toggleMenu}
            isInMenu={true}
          />
        </div>
      </>
    );
  },
);

MenuOrganization.displayName = 'MenuOrganization';
