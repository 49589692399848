import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { initialOrders } from 'shared/const/initial-state.const';
import { TGlobalState } from 'shared/models/global-state.model';

const initialState: TGlobalState['orders'] = [...initialOrders];

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setOrders(_, action: PayloadAction<TGlobalState['orders']>) {
      return [...action.payload];
    },

    addToOrders(
      state,
      action: PayloadAction<TGlobalState['orders']>,
    ) {
      return [...state, ...action.payload];
    },

    clearOrders() {
      return [];
    },
  },
});

export const { setOrders, addToOrders, clearOrders } =
  ordersSlice.actions;
export default ordersSlice.reducer;
