import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { initialModal } from 'shared/const/initial-state.const';
import { TGlobalState } from 'shared/models/global-state.model';

const initialState: TGlobalState['initModal'] = { ...initialModal };

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    showModal(state, action: PayloadAction<boolean>) {
      state.modal = action.payload;
    },
    setShowModalInvoice(state, action: PayloadAction<boolean>) {
      state.showModalInvoice = action.payload;
    },
  },
});

export const { showModal, setShowModalInvoice } = modalSlice.actions;

export default modalSlice.reducer;
