import {
  PayloadAction,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { productsUrl } from 'urls';

import { PRODUCTS_STATUSES } from 'shared/const';
import { initialProducts } from 'shared/const/initial-state.const';
import { TGlobalState } from 'shared/models/global-state.model';
import { IError } from 'shared/models/simple-response.model';
import httpClient from 'shared/utils/http-client/http-client';
import { multiLoginStorage } from 'shared/utils/navigate-to-multi-login.utils';

import { logOutUser } from './auth.slice';
import { showLoader } from './loader.slice';
import { addAlert } from './pageAlerts.slice';

const initialState: TGlobalState['initProducts'] = {
  ...initialProducts,
};

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    getProductsStart(state) {
      state.productsRequest = true;
      state.productsFailed = false;
    },
    getProductsSuccess(
      state,
      action: PayloadAction<TGlobalState['initProducts']['products']>,
    ) {
      state.products = action.payload;
      state.productsRequest = false;
    },
    getProductsFailed(state) {
      state.productsFailed = true;
      state.productsRequest = false;
    },
  },
});

export const getProducts = createAsyncThunk(
  'products/getProducts',
  async (_, { dispatch, rejectWithValue }) => {
    const orgId = multiLoginStorage.getOrgId();
    dispatch(getProductsStart());
    dispatch(showLoader(true));

    try {
      const { data } = await httpClient.get<
        TGlobalState['initProducts']['products']
      >(productsUrl(orgId));

      const products =
        data &&
        data.filter((item) => {
          return (
            item.availableStatus !== PRODUCTS_STATUSES.DO_NOT_DISPLAY
          );
        });

      dispatch(getProductsSuccess(products));
    } catch (error) {
      dispatch(getProductsFailed());
      const err = error as IError;

      if (err.response.status === 500) {
        window.location.href = '/error500';
        return rejectWithValue('Server Error');
      }

      if (err.response.status === 401) {
        dispatch(logOutUser());
        return rejectWithValue('Unauthorized');
      }

      if (!!err.response.status) {
        dispatch(
          addAlert({
            text: 'Ошибка получения товаров',
            variant: 'danger',
          }),
        );
      }

      return rejectWithValue('Error fetching products');
    } finally {
      dispatch(showLoader(false));
    }
  },
);

export const {
  getProductsStart,
  getProductsSuccess,
  getProductsFailed,
} = productsSlice.actions;

export default productsSlice.reducer;
