import classNames from 'classnames/bind';
import React, { FC, memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
  AppDispatch,
  getDeliveryState,
  getMultiLogin,
  getOrder,
} from 'store';
import { logOutUser } from 'store/auth.slice';
import { setDeliveryData } from 'store/delivery.slice';

import { Icon } from 'components/Icon';
import AuthMenu from 'components/auth-menu/auth-menu';

import { useAuth } from 'shared/hooks/use-auth';
import { multiLoginStorage } from 'shared/utils/navigate-to-multi-login.utils';
import { tokens } from 'shared/utils/tokens.util';

import styles from './profile-menu.module.css';

const cn = classNames.bind(styles);

interface IMenuProps {
  switchToOrgMenu: () => void;
  toggleMenu: (e: React.MouseEvent) => void;
}

export const Menu: FC<IMenuProps> = memo(
  ({ switchToOrgMenu, toggleMenu }) => {
    const isAuth = tokens.getAccess();
    const { order } = useSelector(getOrder);
    const { deliveryData } = useSelector(getDeliveryState);
    const auth = useAuth();
    const { type, logins } = useSelector(getMultiLogin);
    const dispatch = useDispatch<AppDispatch>();

    const productsQuantity = order?.products.length;
    const orgIg = multiLoginStorage.getOrgId();

    const logOut = useCallback(() => {
      dispatch(setDeliveryData({ ...deliveryData, start: true }));
      dispatch(logOutUser());
      auth?.logOut();
    }, [dispatch, deliveryData, auth]);

    return (
      <div className={styles['profile-menu']}>
        <div className="main">
          <div className={styles.head}>
            <AuthMenu
              logo={
                isAuth ? (
                  <Icon name="newUser" width="16" height="16" />
                ) : (
                  <Icon name="authLogo" width="16" height="16" />
                )
              }
              toggleMenu={toggleMenu}
            />
          </div>
          <div className="body">
            {productsQuantity > 0 && (
              <div className="orders">
                <div>
                  <NavLink
                    data-testid="profile-menu-navlink-1"
                    className={`${styles['menu-item']} ${styles.border}`}
                    to="/order"
                  >
                    <div className={styles['menu-icon']}>
                      <Icon name="orangeBox" width="16" height="16" />
                    </div>
                    <p>Заказ</p>
                    <div className={styles.counter}>
                      {order.products.reduce((sum, current) => {
                        return sum + current.quantity!;
                      }, 0)}
                    </div>
                  </NavLink>
                </div>
              </div>
            )}
            {/* меню профиля */}
            <div>
              <NavLink
                data-testid="profile-menu-navlink-3"
                className={`${styles['menu-item']} d-xl-none`}
                to="/products"
              >
                <div className={styles['menu-icon']}>
                  <Icon name="newProducts" width="16" height="16" />
                </div>
                <p>Товары</p>
              </NavLink>

              {isAuth && (
                <>
                  <NavLink
                    data-testid="profile-menu-navlink-5"
                    className={`${styles['menu-item']} d-xl-none`}
                    to="/orders"
                  >
                    <div className={styles['menu-icon']}>
                      <Icon name="list" width="16" height="16" />
                    </div>
                    <p>История заказов</p>
                  </NavLink>

                  {orgIg && (
                    <>
                      <NavLink
                        data-testid="profile-menu-navlink-4"
                        className={cn('menu-item', {
                          'border-top': productsQuantity === 0,
                        })}
                        to="/information"
                      >
                        <div className={styles['menu-icon']}>
                          <Icon name="info" width="15" height="15" />
                        </div>
                        <p>Покупателям</p>
                      </NavLink>

                      <NavLink
                        data-testid="profile-menu-navlink-6"
                        className={styles['menu-item']}
                        to="/profile"
                      >
                        <div className={styles['menu-icon']}>
                          <Icon
                            name="newUser"
                            width="16"
                            height="16"
                          />
                        </div>
                        <p>Профиль</p>
                      </NavLink>
                    </>
                  )}

                  {isAuth &&
                    orgIg &&
                    type === 'LK' &&
                    logins.length > 1 && (
                      <div
                        data-testid="profile-menu-navlink-6"
                        className={`${styles['menu-item']} ${styles.border}`}
                        onClick={switchToOrgMenu}
                        role="button"
                        aria-hidden="true"
                      >
                        <div className={styles['menu-icon']}>
                          <Icon
                            name="briefBag"
                            width="20"
                            height="20"
                          />
                        </div>
                        <p>Сменить организацию</p>
                        <div className={styles.arrow}>
                          <Icon
                            name="arrowRight"
                            width="16"
                            height="16"
                          />
                        </div>
                      </div>
                    )}
                </>
              )}
            </div>
            {isAuth && (
              <div className={styles['out-profile']}>
                <div
                  onClick={logOut}
                  role="button"
                  aria-hidden="true"
                >
                  <div
                    className={cn('menu-item', {
                      'border-top': !orgIg,
                    })}
                  >
                    <div className={styles['menu-icon']}>
                      <Icon name="logOut" width="16" height="16" />
                    </div>
                    <p data-testid="profile-menu-click-1">Выход</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  },
);

Menu.displayName = 'Menu';
