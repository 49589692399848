/** Получить мультилогин */
export const multiLoginUrl = '/public/api/v3/auth/multilogin';

/** Обновление по refresh-токену */
export const updateToken = '/public/api/v3/auth/token/refresh';

/** Получить информацию о профиле пользователя */
export const orgUrl = (orgId: string) =>
  `/public/api/v3/organizations/${orgId}/le`;

/** Регистрация нового пользователя (организации) */
export const regNewUser = '/public/api/v3/users';

/** Получить список подписанных соглашений */
export const docsUrl = (orgId: string) =>
  `/public/api/v3/organizations/${orgId}/agreements`;

/** Проверить подписан ли документ по его типу */
export const checkDocUrl = (orgId: string) =>
  `/public/api/v3/organizations/${orgId}/agreements/check`;

/** Получить сведения о продуктах */
export const productsUrl = (orgId: string) =>
  `/public/api/v3/organizations/${orgId}/products?withSketches=false`;

/** Получить документ по типу */
export const getOfferUrl = (orgId: string) =>
  `/public/api/v3/organizations/${orgId}/agreements/body`;

/** Принятие оферты по номеру уже созданной оферты */
export const acceptOfferUrl = (orgId: string) =>
  `/public/api/v3/organizations/${orgId}/agreements/accept`;

/** Создать и принять оферту по её типу */
export const createAndAcceptOfferUrl = (orgId: string) =>
  `/public/api/v3/organizations/${orgId}/agreements/create-and-accept`;

/** Проверка подключения к ЭДО или проверка связи ЭДО по роумингу с Торговый дом Платформа */
export const checkEdoUrl = (orgId: string) =>
  `/public/api/v3/organizations/${orgId}/services/edo/partners`;

/** Получить способ обмена документами */
export const checkDocFlowUrl = (orgId: string) =>
  `/public/api/v3/organizations/${orgId}/doc-exchanges/types`;

/** Подсказки по адресам */
export const dadataUrl =
  '/public/api/v3/dadata/suggest/address?query=';

/** Посчитать стоимость доставки */
export const deliveryUrl = '/public/api/v3/delivery/calc';

/** Создать заказ */
export const createOrderUrl = (orgId: string) =>
  `/public/api/v3/organizations/${orgId}/orders`;

/** Получить типы файлов для продуктов */
export const fileTypesUrl = '/public/api/v3/product/file/types';

/** Получить файл по его ИД */
export const getfileUrl = `/public/api/v3/product`;

/** Получить созданные заказы */
export const getCreatedOrders = (orgId: string) =>
  `/public/api/v3/organizations/${orgId}/orders`;

/** Получить файл по его id */
export const getfileBodyUrl = (fileId: string) =>
  `/public/api/v3/product/file/id/${fileId}`;

/** Получить публичную оферту Платформа ТД */
export const offertaDownloadUrl =
  '/public/api/v3/offer?contentDispositionType=inline';

/** Получить тело дополнительного файла по его ИД */
export const downloadRouteInstructionUrl =
  '/public/api/v3/files/memo/id/2/body';

export const downloadFormProcurationUrl =
  '/public/api/v3/files/memo/id/3/body';

/** Сгенерировать EXCEL файл с подробными сведения о ФН в заказе его ИД */
export const downloadXlxsOrdersUrl = (
  orgId: string,
  orderId: number,
) => `/public/api/v3/organizations/${orgId}/orders/${orderId}/files`;

/** Получить документы по заказу */
export const getOrderDocsUrl = (orgId: string, orderId: number) =>
  `/public/api/v3/organizations/${orgId}/orders/${orderId}/docs`;

/** Создать платёж */
export const createPaymentsUrl = (orgId: string, orderId: number) =>
  `/public/api/v3/organizations/${orgId}/orders/${orderId}/payments`;

export const getPaymentStatus = (
  orgId: string,
  orderId: string,
  paymentKey: string,
) =>
  `/public/api/v3/organizations/${orgId}/orders/${orderId}/payments/${paymentKey}`;

/** Подсказки по организациям */
export const dadataFindByOrganization =
  '/public/api/v3/dadata/suggest/organization';

/** Ссылка для скачивания счета на оплату */
export const getPaymentInvoiceLink = (orderId: number) =>
  `/public/api/v3/orders/${orderId}/docs/types/invoice`;

export const LOGIN_FORM_URL_HASH = '#login';
