import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import styles from './phone-input.module.css';

interface PhoneInputProps {
  label: string;
  margin?: string;
  iconFlag?: string;
  testId?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  onInput?: () => void;
  type?: string;
  theme?: string;
  icon?: string;
  placeholder?: string;
  error?: string;
  defaultValue?: string | number;
  value?: string | number;
  disabled?: boolean;
  revealFlag?: boolean;
  passIconOpened?: string;
  passIconClosed?: string;
  maxLength?: number;
  isLoading?: boolean;
  withClearButton?: boolean;
}

const PhoneInput = memo(
  forwardRef<HTMLInputElement, PhoneInputProps>((props, ref) => {
    const {
      label,
      value,
      margin,
      iconFlag,
      onBlur,
      onFocus,
      onChange,
      onInput,
      type,
      theme,
      icon,
      placeholder,
      testId,
      error,
      defaultValue,
      disabled,
      maxLength,
    } = props;

    const [inputValue, setInputValue] = useState<string | number>();
    const [inputDefaultValue, setInputDefaultValue] = useState<
      string | number
    >();

    const localRef = useRef(null);
    const inputRef = ref || localRef;

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
        setInputDefaultValue(e.target.value);
        !!onChange && onChange(e);
      },
      [onChange],
    );

    useEffect(() => {
      if (value) setInputValue(value);
      if (defaultValue) setInputDefaultValue(defaultValue);
    }, [value, defaultValue]);

    return (
      <div
        className={`${styles.main} ${
          margin ? styles[`${margin}`] : ''
        } ${disabled ? styles.disabled : ''}`}
      >
        <label className={styles.label}>{label}</label>

        {defaultValue && value === undefined && (
          <input
            ref={inputRef}
            data-testid={`custom-input-${testId}`}
            onBlur={onBlur}
            onFocus={onFocus}
            className={`${styles.input} ${
              theme ? styles[`${theme}`] : ''
            }`}
            type={type}
            disabled={disabled || false}
            placeholder={placeholder}
            defaultValue={inputDefaultValue}
            maxLength={maxLength}
          />
        )}

        {!defaultValue && value !== undefined && (
          <input
            ref={inputRef}
            data-testid={`custom-input-${testId}`}
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={handleChange}
            onInput={onInput}
            className={`${styles.input} ${
              theme ? styles[`${theme}`] : ''
            }`}
            type={type}
            disabled={disabled || false}
            placeholder={placeholder}
            value={inputValue}
            maxLength={maxLength}
          />
        )}

        {!defaultValue && value === undefined && (
          <input
            ref={inputRef}
            data-testid={`phone-input-${testId}`}
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={handleChange}
            onInput={onInput}
            className={`${styles.input} ${
              theme ? styles[`${theme}`] : ''
            }`}
            type={type}
            disabled={disabled || false}
            placeholder={placeholder}
            maxLength={maxLength}
          />
        )}

        {iconFlag && (
          <div className={styles.icon}>
            <img src={icon} alt="" />
          </div>
        )}

        {Boolean(error?.length) && (
          <p className={styles.error}>{error}</p>
        )}
      </div>
    );
  }),
);

PhoneInput.displayName = 'PhoneInput';

export default PhoneInput;
