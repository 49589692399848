import React, { FC, ReactElement, memo } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { getMultiLogin } from 'store';

import { tokens } from 'shared/utils/tokens.util';

interface IProtectedRouteProps {
  children: ReactElement;
}

const ProtectedRoute: FC<IProtectedRouteProps> = memo(
  ({ children }) => {
    const { type } = useSelector(getMultiLogin);
    const accessToken = tokens.getAccess();

    if (!accessToken || type !== 'LK') {
      return <Navigate to="/" />;
    }

    return children;
  },
);

export default ProtectedRoute;
