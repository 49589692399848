import { FC, memo } from 'react';

import checkedIco from 'images/icons/checked.svg';

export const IconCheckbox: FC = memo(() => (
  <div
    style={{
      display: 'flex',
      flex: 1,
      backgroundColor: '#EA8242',
      alignItems: 'center',
      justifyContent: 'center',
      alignSelf: 'stretch',
    }}
  >
    <img src={checkedIco} alt="" />
  </div>
));

IconCheckbox.displayName = 'Icon';
