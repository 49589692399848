import classNames from 'classnames/bind';
import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { getMultiLogin, getOrder } from 'store';
import { addAlert } from 'store/pageAlerts.slice';
import { withKeyHasBlocked, withSetHasBlocked } from 'utils';

import { Icon } from 'components/Icon';
import ImageSlider from 'components/image-slider/image-slider';
import { Indicator } from 'components/indicator/indicator';
import ProductAmount from 'components/product-amount/product-amount';

import { PRODUCTS_STATUSES, USER_TYPES } from 'shared/const';
import { useProducts } from 'shared/hooks/use-products.hook';
import {
  FileProduct,
  ProductResponse,
} from 'shared/models/global-state.model';
import { getHtmlData } from 'shared/utils/read-html.util';
import { tokens } from 'shared/utils/tokens.util';

import styles from './products-card.module.css';

const cx = classNames.bind(styles);

const TIMEOUT = 100;

interface IProductsCardProps {
  product: ProductResponse;
  availableStatus: number;
  indicator: boolean;
  counter: boolean;
  photo: FileProduct[];
  imageStyle?: string;
  imageUrl?: string;
}

const ProductsCard: FC<IProductsCardProps> = memo(
  ({
    product,
    availableStatus,
    indicator,
    counter,
    photo,
    imageStyle,
  }) => {
    const [html, setHtml] = useState('');
    const [detailsPositionTop, setDetailsPositionTop] = useState(0);
    const innerHTMLRef = useRef<HTMLDivElement>(null);

    const location = useLocation();
    const navigate = useNavigate();
    const { id } = useParams();
    const { isMainPage } = useProducts();
    const { order } = useSelector(getOrder);
    const { type } = useSelector(getMultiLogin);

    const isAuth = tokens.getAccess();

    const dispatch = useDispatch();

    const blockedCounterFnWithKey = withKeyHasBlocked(order, product);

    const blockedCounterFnSetWithOfd = withSetHasBlocked(
      order,
      product,
    );

    const blockedCounter = useMemo(() => {
      if (isMainPage) return false;
      return blockedCounterFnSetWithOfd || blockedCounterFnWithKey;
    }, [
      blockedCounterFnSetWithOfd,
      blockedCounterFnWithKey,
      isMainPage,
    ]);

    const unBlockedCounter = useMemo(() => {
      if (isMainPage) return true;
      return !blockedCounterFnSetWithOfd && !blockedCounterFnWithKey;
    }, [
      blockedCounterFnSetWithOfd,
      blockedCounterFnWithKey,
      isMainPage,
    ]);

    const deliveryMessage = useMemo(() => {
      return !isAuth || type === USER_TYPES.LK
        ? 'Доставка включена в стоимость'
        : 'Отгрузка в течение 14 дней';
    }, [isAuth, type]);

    const borderStyle = useMemo(() => {
      if (isMainPage) return false;
      return !!id;
    }, [id, isMainPage]);

    const borderStyleUnavailable = useMemo(() => {
      return !id && blockedCounter;
    }, [id, blockedCounter]);

    const handleClick = useCallback(() => {
      navigate(`/product/${product.id}`);
    }, [navigate, product.id]);

    const changeDetailsPosition = useCallback(() => {
      const MAX_HEIGHT = 100;
      const MAX_PADDING = 25;
      const MIN_PADDING = 10;

      const height = innerHTMLRef.current?.getBoundingClientRect()!;

      setDetailsPositionTop(
        height?.height > MAX_HEIGHT
          ? MAX_HEIGHT + MAX_PADDING
          : height?.height + MIN_PADDING,
      );
    }, []);

    useEffect(() => {
      if (!product.specificationFileUrl) return;

      (async () => {
        const htmlCode = await getHtmlData({
          url: product.specificationFileUrl,
          onError: () => {
            dispatch(
              addAlert({
                text: 'Ошибка получения данных',
                variant: 'danger',
              }),
            );
          },
        });

        htmlCode && setHtml(htmlCode);
        setTimeout(changeDetailsPosition, TIMEOUT);
      })();
    }, [
      dispatch,
      changeDetailsPosition,
      product.specificationFileUrl,
    ]);

    useEffect(() => {
      setTimeout(changeDetailsPosition, TIMEOUT);
    }, [changeDetailsPosition, order.products.length]);

    return (
      <>
        {availableStatus !== PRODUCTS_STATUSES.NOT_FOR_ORDER && (
          <div
            className={cx('products-card', {
              'products-border-less': borderStyle,
              'products-border-unavailable': borderStyleUnavailable,
            })}
            onClick={handleClick}
            data-testid={`product-card-${product.name}`}
          >
            <div
              className={cx('wrap', {
                unavailable: blockedCounter,
              })}
            >
              <div className={cx('top')}>
                <ImageSlider
                  photo={photo}
                  classNameImg={imageStyle!}
                  product={product}
                  isBig={
                    location.pathname === `/product/${product.id}`
                  }
                />

                <div className={cx('desc')}>
                  <div className={cx('title')}>{product.name}</div>

                  <div className={cx('text')}>
                    <div className={cx('server-html')}>
                      {!id && product.id === 7505 && (
                        <div className={cx('hidden-block')} />
                      )}

                      {id && (
                        <div className={cx('hidden-block-in-card')} />
                      )}

                      <div
                        className={cx('inner-html')}
                        ref={innerHTMLRef}
                        dangerouslySetInnerHTML={{ __html: html }}
                      />

                      <div className={cx('gradient')} />
                    </div>

                    {!id && (
                      <p
                        className={cx('pseudo-link')}
                        style={{ top: `${detailsPositionTop}px` }}
                      >
                        Подробнее
                      </p>
                    )}
                  </div>
                </div>
              </div>

              {indicator && (
                <div className="justify-content-end d-flex">
                  <Indicator full remainder={availableStatus} />
                </div>
              )}

              <div
                className={cx('bottom', {
                  'justify-content-end': counter,
                })}
              >
                <div className={cx('product-amount')}>
                  <ProductAmount
                    product={product}
                    price={product.price || 0}
                  />
                </div>
              </div>

              {unBlockedCounter && (
                <div className={cx('delivery-wrap')}>
                  <div className={cx('delivery-img')}>
                    <Icon
                      name="deliveryTruck"
                      width="16"
                      height="16"
                    />
                  </div>

                  <p className={cx('delivery-desc')}>
                    {deliveryMessage}
                  </p>
                </div>
              )}

              <p
                className={cx('unavailable-text', {
                  hidden: unBlockedCounter,
                })}
              >
                Данный товар не может быть <br /> добавлен в текущий
                заказ
              </p>
            </div>
          </div>
        )}
      </>
    );
  },
);

ProductsCard.displayName = 'ProductsCard';

export default ProductsCard;
