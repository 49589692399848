import { useCallback, useState } from 'react';

import { IOrgInfo } from 'shared/models/org-info.model';

export const useOrgInfo = () => {
  const [state, setState] = useState<IOrgInfo>({
    data: null,
    error: '',
  });

  const onSearch = useCallback(({ data, error }: IOrgInfo) => {
    setState((prev) => ({ ...prev, data, error }));
  }, []);

  return {
    ...state,
    onSearch,
  };
};
