import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { dadataFindByOrganization } from 'urls';

import { ListItem } from 'components/list/list.model';

import { useValidation } from 'shared/hooks/use-validation';
import { ISuggestion, TSuggestion } from 'shared/models/dadata.model';
import httpClient from 'shared/utils/http-client/http-client';

import { FETCH_DELAY, MIN_INPUT_LENGTH } from './input-search.const';
import {
  InputSearchProps,
  InputSearchState,
} from './input-search.model';

export const useInputSearch = ({ onOrgSelect }: InputSearchProps) => {
  const [state, setState] = useState<InputSearchState>({
    value: '',
    isLoading: false,
    orgs: [],
    selectedOrg: null,
    error: '',
  });

  const { isOnlyNumbersInString, isSameSomeLength } = useValidation();

  const { orgsAsList } = useMemo(() => {
    return {
      orgsAsList: state.orgs.map((org: ISuggestion) => ({
        id: org.value,
        text: org.value,
        inn: org.data.inn,
        kpp: org.data.kpp,
      })),
    };
  }, [state.orgs]);

  const { isListVisible } = useMemo(
    () => ({
      isListVisible: !state.selectedOrg && !!state.orgs.length,
    }),
    [state.selectedOrg, state.orgs.length],
  );

  const isAdditionalDataVisible = useMemo(() => {
    return (
      !!state.selectedOrg?.data &&
      !state.error &&
      !!state.value.length
    );
  }, [state.error, state.selectedOrg?.data, state.value.length]);

  const fetchOrgInfo = useCallback(async (query: string) => {
    setState((prev) => ({
      ...prev,
      isLoading: true,
      orgs: [],
      selectedOrg: null,
      error: '',
    }));

    try {
      const response = await httpClient.get<TSuggestion[]>(
        `${dadataFindByOrganization}?query=${query}`,
      );
      setState((prev) => ({ ...prev, orgs: response.data }));
    } catch (error) {
      setState((prev) => ({
        ...prev,
        error: 'Не удалось получить данные об организации',
      }));
    } finally {
      setState((prev) => ({ ...prev, isLoading: false }));
    }
  }, []);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.value.length) {
        setState({
          value: '',
          isLoading: false,
          orgs: [],
          selectedOrg: null,
          error: '',
        });
      }

      if (e.target.value.length < MIN_INPUT_LENGTH) return;

      if (e.target.value.length < state.value.length) {
        setState((prev) => ({ ...prev, selectedOrg: null }));
      }

      setTimeout(() => {
        fetchOrgInfo(e.target.value);
      }, FETCH_DELAY);
    },
    [fetchOrgInfo, state.value.length],
  );

  const validateInn = useCallback(() => {
    if (!state.value.length) {
      return setState((prev) => ({ ...prev, error: 'Укажите ИНН' }));
    }

    const VALID_INN_LENGTHS = [10, 12];

    const isValidInn =
      isOnlyNumbersInString(state.value) &&
      isSameSomeLength(state.value, VALID_INN_LENGTHS);

    if (!isValidInn) {
      return setState((prev) => ({
        ...prev,
        error: 'Неверный формат ИНН',
      }));
    }

    fetchOrgInfo(state.value);
  }, [
    state.value,
    isOnlyNumbersInString,
    isSameSomeLength,
    fetchOrgInfo,
  ]);

  const handleSelect = useCallback(
    ({ text }: ListItem) => {
      const selectedOrg = state.orgs.filter(
        (org) => org.value === text,
      )[0];

      setState((prev) => ({ ...prev, value: text, selectedOrg }));
    },
    [state.orgs],
  );

  useEffect(() => {
    onOrgSelect({
      data: state.selectedOrg,
      error: state.error,
    });
  }, [onOrgSelect, state.error, state.selectedOrg]);

  return {
    state,
    orgsAsList,
    isListVisible,
    isAdditionalDataVisible,
    fetchOrgInfo,
    handleInputChange,
    validateInn,
    handleSelect,
  };
};
