import {
  ActionCreatorWithPayload,
  PayloadAction,
  createSlice,
} from '@reduxjs/toolkit';
import { getfileUrl } from 'urls';

import { initialDocument } from 'shared/const/initial-state.const';
import {
  FileProduct,
  TGlobalState,
} from 'shared/models/global-state.model';
import { IError } from 'shared/models/simple-response.model';
import httpClient from 'shared/utils/http-client/http-client';
import { getHtmlData } from 'shared/utils/read-html.util';

import { logOutUser } from './auth.slice';
import { AppDispatch } from './index';
import { showLoader } from './loader.slice';
import { addAlert } from './pageAlerts.slice';

const initialState: TGlobalState['initDocument'] = {
  ...initialDocument,
};

type TDispatchType =
  | ActionCreatorWithPayload<
      Record<string, string>,
      'document/getDescSuccess'
    >
  | ActionCreatorWithPayload<
      Record<string, string>,
      'document/getCharSuccess'
    >;

const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    getInfo(state) {
      state.productCardInfoRequest = true;
      state.productCardInfoFailed = false;
    },
    getDocSuccess(state, action) {
      state.document.push(action.payload);
      state.productCardInfoRequest = false;
    },
    getDescSuccess(
      state,
      action: PayloadAction<Record<string, string>>,
    ) {
      state.description.push(action.payload);
      state.productCardInfoRequest = false;
    },
    getCharSuccess(
      state,
      action: PayloadAction<Record<string, string>>,
    ) {
      state.characteristic.push(action.payload);
      state.productCardInfoRequest = false;
    },
    getImagesFailed(state) {
      state.productCardInfoFailed = true;
      state.productCardInfoRequest = false;
    },
  },
});

export const {
  getInfo,
  getDocSuccess,
  getDescSuccess,
  getCharSuccess,
  getImagesFailed,
} = documentSlice.actions;

export default documentSlice.reducer;

async function getProductInformation(
  product: FileProduct,
  typeDispatch: TDispatchType,
  dispatch: AppDispatch,
  id: string,
) {
  const obj: Record<string, string> = {};

  try {
    const htmlCode = await getHtmlData<string>({
      url: product.fileUrl,
      onError: () => {
        dispatch(
          addAlert({
            text: 'Ошибка получения данных',
            variant: 'danger',
          }),
        );
      },
    });

    obj[id] = htmlCode ?? '';
    dispatch(typeDispatch(obj));
  } catch (e) {
    dispatch(
      addAlert({
        text: 'Ошибка получения данных',
        variant: 'danger',
      }),
    );
  }
}

async function getProductInfoFileId(
  item: FileProduct,
  dispatch: AppDispatch,
  id: string,
) {
  const document: Record<string, FileProduct> = {};

  try {
    if (item.fileTypeId === 8) {
      document[id] = item;
      dispatch(getDocSuccess(document));
    }

    if (item.fileTypeId === 2) {
      await getProductInformation(item, getDescSuccess, dispatch, id);
    }

    if (item.fileTypeId === 3) {
      await getProductInformation(item, getCharSuccess, dispatch, id);
    }
  } catch (error) {
    const err = error as IError;

    if (err?.response?.status === 401) {
      dispatch(logOutUser());
    }

    dispatch(
      addAlert({
        text: 'Ошибка получения файлов продукта',
        variant: 'danger',
      }),
    );
  }
}

export const getProductInfo = (id: string, typeID: number) => {
  return async (dispatch: AppDispatch) => {
    dispatch(getInfo());
    dispatch(showLoader(true));

    try {
      const url = `${getfileUrl}/id/${id}/file/type/id/${typeID}`;
      const { data } = await httpClient.get(url);

      for (const item of data) {
        await getProductInfoFileId(item, dispatch, id);
      }
    } catch (error) {
      dispatch(
        addAlert({
          text: 'Ошибка получения файлов продукта по id',
          variant: 'danger',
        }),
      );
    } finally {
      dispatch(showLoader(false));
    }
  };
};
