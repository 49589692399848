import classNames from 'classnames/bind';
import { FC, memo } from 'react';

import searchCircleInfo from 'images/icons/search-circle–info.svg';

import styles from './info-search-banner.module.css';

const cn = classNames.bind(styles);

interface IInfoSearchBannerProps {
  isInMenu: boolean;
  message: string;
}

export const InfoSearchBanner: FC<IInfoSearchBannerProps> = memo(
  ({ message, isInMenu }) => {
    return (
      <div
        className={cn('info-banner', {
          'in-menu-info-banner': isInMenu,
        })}
      >
        <img src={searchCircleInfo} alt="search-circle–info" />
        <div className={styles['info-banner-desc']}>{message}</div>
      </div>
    );
  },
);

InfoSearchBanner.displayName = 'InfoSearchBanner';
