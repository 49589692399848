import { ChangeEvent, FC, memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDeliveryState } from 'store';
import { setDeliveryData } from 'store/delivery.slice';

import { usePartner } from 'shared/hooks/use-partner.hook';
import { tokens } from 'shared/utils/tokens.util';

import styles from './switch-tabs.module.css';

const SwitchTabs: FC = memo(() => {
  const dispatch = useDispatch();
  const { deliveryData } = useSelector(getDeliveryState);
  const isPartner = usePartner();
  const isAuth = tokens.getAccess();

  const switchFlag = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      let newDeliveryData = {
        ...deliveryData,
        deliveryType: e.target.value,
      };

      if (e.target.value === 'Самовывоз') {
        newDeliveryData = {
          ...newDeliveryData,
          deliveryPrice: 0,
          tariff: null,
        };
      }

      dispatch(setDeliveryData(newDeliveryData));
    },
    [deliveryData, dispatch],
  );

  return (
    <div className="wrap">
      <p className={`${styles.title} mb-0`}>Способ получения</p>

      <div className={`${styles['switch-tabs']} d-flex mt-2`}>
        <div className={styles['switch-tab']}>
          <input
            checked={deliveryData.deliveryType === 'Доставка'}
            onChange={switchFlag}
            className={`${styles.input} d-none`}
            name="choice"
            type="radio"
            value="Доставка"
            id="delivery"
          />

          <label
            data-testid="switch-tabs-input-2"
            className={styles.label}
            htmlFor="delivery"
          >
            доставка
          </label>
        </div>

        {isPartner && isAuth && (
          <div className={styles['switch-tab']}>
            <input
              checked={deliveryData.deliveryType === 'Самовывоз'}
              onChange={switchFlag}
              className={`${styles.input} d-none`}
              name="choice"
              type="radio"
              value="Самовывоз"
              id="pickup"
            />

            <label
              data-testid="switch-tabs-input-1"
              className={styles.label}
              htmlFor="pickup"
            >
              самовывоз
            </label>
          </div>
        )}
      </div>
    </div>
  );
});

SwitchTabs.displayName = 'SwitchTabs';

export default SwitchTabs;
