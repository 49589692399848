import classNames from 'classnames/bind';
import React, {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { getCreatedOrders, orgUrl } from 'urls';

import DownloadLink from 'components/download-link/download-link';
import { GeneratingInvoice } from 'components/generating-invoice/generating-invoice';

import { IUpdFileInfo } from 'shared/models/file.model';
import { IError } from 'shared/models/simple-response.model';
import httpClient from 'shared/utils/http-client/http-client';
import { multiLoginStorage } from 'shared/utils/navigate-to-multi-login.utils';

import payCard from 'images/icons/Card.svg';
import checkGreen from 'images/icons/check-green.svg';
import divider from 'images/icons/divider.svg';
import emailIcon from 'images/icons/email.svg';
import exclamation from 'images/icons/exclamation.svg';
import file from 'images/icons/file.svg';
import mc from 'images/mc.svg';
import mir from 'images/mir.svg';
import sberPay from 'images/sber-pay.svg';
import sbp from 'images/sbp.svg';
import visa from 'images/visa.svg';

import styles from './pay-items.module.css';

const cn = classNames.bind(styles);

interface IPayItemsProps {
  orderNumber: number | null;
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  error: string | null;
  handlePayClick: () => Promise<void>;
  invoiceFileInfo?: IUpdFileInfo;
}

export const PayItems: FC<IPayItemsProps> = memo(
  ({
    orderNumber,
    email,
    setEmail,
    error,
    handlePayClick,
    invoiceFileInfo,
  }) => {
    const [emailError, setEmailError] = useState<string | null>(null);
    const orgId = multiLoginStorage.getOrgId();

    const validateEmail = useCallback((email: string) => {
      const re =
        /^(?=.{1,64}$)[A-Za-z0-9А-Яа-я._%+-]+@[A-Za-z0-9А-Яа-я.-]+\.[A-Za-zА-Яа-я]{2,6}$/;
      return re.test(email);
    }, []);

    const handleChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
        setEmailError(
          validateEmail(e.target.value) ? null : 'Некорректный email',
        );
      },
      [setEmail, validateEmail],
    );

    useEffect(() => {
      (async () => {
        try {
          if (orgId) {
            const { data } = await httpClient.get(orgUrl(orgId));
            setEmail(data.email);
          }
        } catch (error) {
          const err = error as IError;
          setEmailError(err.message);
        }
      })();
    }, [setEmail, orgId]);

    return (
      <div className={styles.container}>
        <div className={styles.card}>
          <div className={styles['wrap-card']}>
            По счету
            <img src={file} alt="file" />
          </div>

          {orderNumber ? (
            <>
              <div className={styles['success-status']}>
                <img
                  className={styles['success-icon']}
                  src={checkGreen}
                  alt="checkGreen"
                />

                <p className={styles.desc}>Счет сформирован</p>
              </div>

              <div className={styles['wrap-info']}>
                <img src={file} alt="file" />
                <div>
                  <p className={styles['check-header']}>
                    Счет к заказу
                  </p>
                  <p className={styles.check}>
                    Счет №298–849 от 28.01.2022 г.
                  </p>
                </div>
              </div>
            </>
          ) : (
            <GeneratingInvoice />
          )}

          <DownloadLink
            link={getCreatedOrders(orgId)}
            payload={{ responseType: 'blob' }}
            textLink="Скачать счет"
            linkStyle="short"
            fileName={invoiceFileInfo?.docFile.docName}
            documentType="application/pdf"
            iconSrc={''}
            showedDownloadIcon={null}
            isDisabled={!orderNumber}
          />
        </div>

        <div className={styles.card}>
          <div className={styles['wrap-card']}>
            Банковской картой
            <img src={payCard} alt="payCard" />
          </div>

          <div className={styles['wrap-info-card']}>
            <img src={sbp} alt="sbp" />
            <img src={sberPay} alt="sberPay" />
            <img src={divider} alt="divider" />
            <img src={mir} alt="mir" />
            <img src={visa} alt="visa" />
            <img src={mc} alt="master card" />
          </div>

          <div className={styles['email-container']}>
            <div
              className={cn('email-wrap', {
                'email-wrap-error': emailError,
              })}
            >
              <img src={emailIcon} alt="email" />
              <input
                className={styles.email}
                value={email}
                onChange={handleChange}
                placeholder="Email для отправки чека"
                type="email"
              />
              {emailError && (
                <img
                  className={styles.exclamation}
                  src={exclamation}
                  alt="exclamation"
                />
              )}
            </div>

            {emailError && (
              <p className={styles['email-error']}>{emailError}</p>
            )}

            {error && (
              <p className={styles['email-error']}>{error}</p>
            )}
          </div>

          <button
            className={cn('download-btn', {
              'disabled-btn': !!error || !!emailError,
            })}
            onClick={handlePayClick}
            disabled={!!error || !!emailError}
          >
            Оплатить
          </button>
        </div>
      </div>
    );
  },
);

PayItems.displayName = 'PayItems';
