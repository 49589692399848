import classNames from 'classnames/bind';
import { FC, memo } from 'react';

import styles from './content-loader.module.css';

const cx = classNames.bind(styles);

export const ProductCardLoader: FC = memo(() => {
  return (
    <div
      className={cx(
        'placeholder-wrapper',
        'placeholder-product-list',
      )}
    >
      <div className={cx('placeholder-product-item')}>
        <div className={cx('col-2-row', 'col-row')}>
          <div className={cx('base-placeholder', 'image-medium')} />
          <div>
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
          </div>
        </div>
        <div className={cx('col-2-row', 'col-row')}>
          <div
            className={cx('base-placeholder', 'placeholder-row')}
          />
          <div
            className={cx('base-placeholder', 'placeholder-row')}
          />
        </div>
      </div>
      <div className={cx('placeholder-product-item')}>
        <div className={cx('col-2-row', 'col-row')}>
          <div className={cx('base-placeholder', 'image-medium')} />
          <div>
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
          </div>
        </div>
        <div className={cx('col-2-row', 'col-row')}>
          <div
            className={cx('base-placeholder', 'placeholder-row')}
          />
          <div
            className={cx('base-placeholder', 'placeholder-row')}
          />
        </div>
      </div>
      <div className={cx('placeholder-product-item')}>
        <div className={cx('col-2-row', 'col-row')}>
          <div className={cx('base-placeholder', 'image-medium')} />
          <div>
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
          </div>
        </div>
        <div className={cx('col-2-row', 'col-row')}>
          <div
            className={cx('base-placeholder', 'placeholder-row')}
          />
          <div
            className={cx('base-placeholder', 'placeholder-row')}
          />
        </div>
      </div>
      <div className={cx('placeholder-product-item')}>
        <div className={cx('col-2-row', 'col-row')}>
          <div className={cx('base-placeholder', 'image-medium')} />
          <div>
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
          </div>
        </div>
        <div className={cx('col-2-row', 'col-row')}>
          <div
            className={cx('base-placeholder', 'placeholder-row')}
          />
          <div
            className={cx('base-placeholder', 'placeholder-row')}
          />
        </div>
      </div>
      <div className={cx('placeholder-product-item')}>
        <div className={cx('col-2-row', 'col-row')}>
          <div className={cx('base-placeholder', 'image-medium')} />
          <div>
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
          </div>
        </div>
        <div className={cx('col-2-row', 'col-row')}>
          <div
            className={cx('base-placeholder', 'placeholder-row')}
          />
          <div
            className={cx('base-placeholder', 'placeholder-row')}
          />
        </div>
      </div>
      <div className={cx('placeholder-product-item')}>
        <div className={cx('col-2-row', 'col-row')}>
          <div className={cx('base-placeholder', 'image-medium')} />
          <div>
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
          </div>
        </div>
        <div className={cx('col-2-row', 'col-row')}>
          <div
            className={cx('base-placeholder', 'placeholder-row')}
          />
          <div
            className={cx('base-placeholder', 'placeholder-row')}
          />
        </div>
      </div>
      <div className={cx('placeholder-product-item')}>
        <div className={cx('col-2-row', 'col-row')}>
          <div className={cx('base-placeholder', 'image-medium')} />
          <div>
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
          </div>
        </div>
        <div className={cx('col-2-row', 'col-row')}>
          <div
            className={cx('base-placeholder', 'placeholder-row')}
          />
          <div
            className={cx('base-placeholder', 'placeholder-row')}
          />
        </div>
      </div>
      <div className={cx('placeholder-product-item')}>
        <div className={cx('col-2-row', 'col-row')}>
          <div className={cx('base-placeholder', 'image-medium')} />
          <div>
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
            <div
              className={cx(
                'base-placeholder',
                'paragraph-placeholder',
              )}
            />
          </div>
        </div>
        <div className={cx('col-2-row', 'col-row')}>
          <div
            className={cx('base-placeholder', 'placeholder-row')}
          />
          <div
            className={cx('base-placeholder', 'placeholder-row')}
          />
        </div>
      </div>
    </div>
  );
});

ProductCardLoader.displayName = 'ProductCardLoader';
